import React from "react";
import {
  ErrorMessage,
  Field,
  FieldArray,
  useField,
  useFormikContext,
} from "formik";
import { get } from "lodash";
import {
  MinusSmIcon,
  PlusSmIcon,
  ArrowSmUpIcon,
  ArrowSmDownIcon,
} from "@heroicons/react/solid";
import SelectField from "../SelectField";
import TextField from "../TextField";
import Autosuggest from "react-autosuggest";
import AutoSuggestField from "../AutoSuggestField";
import NumberField from "../NumberField";

function IngredientArrayField({ choices, fieldName }) {
  const { values } = useFormikContext();

  const model = (ix) => {
    return {
      name: {
        name: `${fieldName}.${ix}.name`,
        label: "Name of Ingredient",
        hint: "Required",
      },
      preparation: {
        name: `${fieldName}.${ix}.preparation`,
        label: "Preparation",
        placeholder: "Chopped / Sliced ...",
        hint: "Optional",
      },
      amount: {
        name: `${fieldName}.${ix}.amount`,
        label: "Amount",
        hint: "Required",
      },
      unit: {
        name: `${fieldName}.${ix}.unit`,
        label: "Unit",
        hint: "Required",
        options: [
          { name: "-", value: "" },
          { name: "g", value: "g" },
          { name: "kg", value: "kg" },
          { name: "cup", value: "cup" },
          { name: "ml", value: "ml" },
          { name: "l", value: "l" },
          { name: "tsp", value: "tsp" },
          { name: "tbsp", value: "tbsp" },
          { name: "pinch", value: "pinch" },
          { name: "pc", value: "pc" },
          { name: "pcs", value: "pcs" },
        ],
      },
      category: {
        name: `${fieldName}.${ix}.category`,
        label: "Category/Department",
        hint: "Required",
        options: [
          { name: "", value: "" },
          { name: "Other", value: "other" },
          { name: "Produce", value: "produce" },
          { name: "Legumes", value: "legumes" },
          { name: "Canned Goods", value: "canned goods" },
          { name: "Pasta & Grains", value: "pasta & grains" },
          { name: "Baking & Spices", value: "baking & spices" },
          { name: "Oils & Vinegars", value: "oils & vinegars" },
          { name: "Nuts & Seeds", value: "nuts & seeds" },
          {
            name: "Sauces & Condiments",
            value: "sauces & condiments",
          },
          { name: "Frozen Goods", value: "frozen goods" },
          { name: "Vegan Dairy", value: "vegan dairy" },
          { name: "Vegan Protein", value: "vegan protein" },
        ],
      },
      altAmount: {
        name: `${fieldName}.${ix}.altAmount`,
        label: "Alternative Amount",
        hint: "Optional",
      },
      altUnit: {
        name: `${fieldName}.${ix}.altUnit`,
        label: "Alternative Unit",
        options: [
          { name: "-", value: "" },
          { name: "g", value: "g" },
          { name: "kg", value: "kg" },
          { name: "cup", value: "cup" },
          { name: "ml", value: "ml" },
          { name: "l", value: "l" },
          { name: "tsp", value: "tsp" },
          { name: "tbsp", value: "tbsp" },
          { name: "pinch", value: "pinch" },
          { name: "pc", value: "pc" },
          { name: "pcs", value: "pcs" },
        ],
        hint: "Optional",
      },
      mealPrepTime: {
        name: `${fieldName}.${ix}.mealPrepTime`,
        label: "Meal Prep: Time",
        hint: "Optional",
        options: [
          { name: "", value: "" },
          { name: "15 min", value: "PT15M" },
          { name: "30 min", value: "PT30M" },
          { name: "45 min", value: "PT45M" },
          { name: "1h", value: "PT1H" },
          { name: "2h", value: "PT2H" },
          { name: "3h", value: "PT3H" },
          { name: "4h", value: "PT4H" },
          { name: "5h", value: "PT5H" },
          { name: "6h", value: "PT6H" },
          { name: "7h", value: "PT7H" },
          { name: "8h", value: "PT8H" },
          { name: "9h", value: "PT9H" },
          { name: "10h", value: "PT10H" },
          { name: "11h", value: "PT11H" },
          { name: "12h", value: "PT12H" },
          { name: "1day", value: "P1D" },
          { name: "2days", value: "P2D" },
        ],
      },
      mealPrepInstruction: {
        name: `${fieldName}.${ix}.mealPrepInstruction`,
        label: "Meal Prep: Instruction",
        hint: "Optional",
        placeholder: "Freeze, ...",
      },
    };
  };

  const options = {
    choices: choices,
  };

  return (
    <>
      <FieldArray
        name={fieldName}
        render={(arrayHelpers) => (
          <div className="space-y-4">
            {get(values, fieldName, []) &&
            get(values, fieldName, []).length > 0 ? (
              get(values, fieldName, []).map((ingredient, index) => (
                <>
                  <div
                    key={index}
                    className="space-y-4 bg-white p-4 shadow sm:rounded-lg"
                  >
                    <div className="grid w-full grid-cols-2 gap-4">
                      <div className="col-span-2">
                        <AutoSuggestField
                          {...model(index).name}
                          options={options}
                        />
                      </div>

                      <div className="col-span-2">
                        <TextField {...model(index).preparation} />
                      </div>

                      <div className="col-span-2">
                        {/* TODO: Set after ingredient name - dependent field! */}
                        <SelectField {...model(index).category} />
                      </div>

                      <div className="col-span-2 md:col-span-1">
                        <NumberField {...model(index).amount} />
                      </div>

                      <div className="col-span-2 md:col-span-1">
                        <SelectField {...model(index).unit} />
                      </div>

                      <div className="col-span-2 md:col-span-1">
                        <NumberField {...model(index).altAmount} />
                      </div>

                      <div className="col-span-2 md:col-span-1">
                        <SelectField {...model(index).altUnit} />
                      </div>

                      <div className="col-span-2 md:col-span-1">
                        <SelectField {...model(index).mealPrepTime} />
                      </div>

                      <div className="col-span-2 md:col-span-1">
                        <TextField {...model(index).mealPrepInstruction} />
                      </div>
                    </div>

                    <div className="space-x-2 text-right">
                      <span className="relative z-0 inline-flex rounded-md shadow-sm">
                        <button
                          type="button"
                          disabled={ingredient?.length === 0 || index === 0}
                          onClick={() => arrayHelpers.swap(index - 1, index)}
                          className={
                            (index === 0
                              ? "cursor-default text-gray-200 "
                              : "text-gray-500 hover:bg-gray-50  ") +
                            "relative inline-flex items-center rounded-l-md border border-gray-300 bg-white px-2 py-2 text-sm font-medium focus:z-10 focus:border-cornflower-500 focus:outline-none focus:ring-1 focus:ring-cornflower-500"
                          }
                        >
                          <span className="sr-only">Up</span>
                          <ArrowSmUpIcon
                            className="h-5 w-5"
                            aria-hidden="true"
                          />
                        </button>
                        <button
                          type="button"
                          disabled={
                            ingredient?.length === 0 ||
                            index >= get(values, fieldName, []).length - 1
                          }
                          onClick={() => arrayHelpers.swap(index, index + 1)}
                          className={
                            (index >= get(values, fieldName, []).length - 1
                              ? "cursor-default text-gray-200 "
                              : "text-gray-500 hover:bg-gray-50  ") +
                            "relative -ml-px inline-flex items-center rounded-r-md border border-gray-300 bg-white px-2 py-2 text-sm font-medium  focus:z-10 focus:border-cornflower-500 focus:outline-none focus:ring-1 focus:ring-cornflower-500"
                          }
                        >
                          <span className="sr-only">Down</span>
                          <ArrowSmDownIcon
                            className="h-5 w-5"
                            aria-hidden="true"
                          />
                        </button>
                      </span>

                      <span className="relative z-0 inline-flex rounded-md shadow-sm">
                        <button
                          type="button"
                          onClick={() => arrayHelpers.remove(index)}
                          className="relative inline-flex items-center rounded-l-md border border-gray-300 bg-white px-2 py-2 text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-10 focus:border-cornflower-500 focus:outline-none focus:ring-1 focus:ring-cornflower-500"
                        >
                          <span className="sr-only">Remove</span>
                          <MinusSmIcon className="h-5 w-5" aria-hidden="true" />
                        </button>
                        <button
                          type="button"
                          disabled={ingredient?.length === 0}
                          onClick={() => arrayHelpers.push("")}
                          className="relative -ml-px inline-flex items-center rounded-r-md border border-gray-300 bg-white px-2 py-2 text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-10 focus:border-cornflower-500 focus:outline-none focus:ring-1 focus:ring-cornflower-500"
                        >
                          <span className="sr-only">Add</span>
                          <PlusSmIcon className="h-5 w-5" aria-hidden="true" />
                        </button>
                      </span>
                    </div>
                  </div>
                </>
              ))
            ) : (
              <button
                type="button"
                onClick={() => arrayHelpers.push("")}
                className="inline-flex items-center rounded-md border border-transparent bg-cornflower-100 px-4 py-2 text-sm font-medium text-cornflower-700 hover:bg-cornflower-200 focus:outline-none focus:ring-2 focus:ring-cornflower-500 focus:ring-offset-2"
              >
                Add ingredient
              </button>
            )}
          </div>
        )}
      />
    </>
  );
}

export default IngredientArrayField;
