import React from "react";
import { Link } from "react-router-dom";
import FooterSocial from "../layout/FooterSocials";
import SubscribeForm from "../newsletter/SubscribeForm";
import * as ROUTES from "../../constants/routes";
import { SpeakerphoneIcon } from "@heroicons/react/outline";
import { SpeakerphoneIcon as SpeakerphoneIconSolid } from "@heroicons/react/solid";
import BookCallButton from "../calendar/BookCallButton";

const headerImages = [
  {
    title: "1:1 Coaching Services",
    href: "#",
    imageUrl: "/assets/img/800x1000/header-1.jpeg",
  },
  {
    title: "Group Coaching",
    href: "#",
    imageUrl: "/assets/img/800x1000/header-2.jpeg",
  },
  {
    title: "Nutrition Webinar",
    href: "#",
    imageUrl: "/assets/img/1000x800/header-3.jpeg",
  },
];

const services = [
  {
    title: "1:1 Coaching",
    href: "#",
    description:
      "I believe that food speaks to our body and soul in a special way. We all are different and should be able to master our individual nutritional needs and goals. This is where my personal 1:1 coaching comes in. In my personal coaching we will take a deep dive into your current routine and eating habits and work towards your individual nutrition & health goals! Find out more in a free discovery call.",
    imageUrl: "/assets/img/1000x800/content-4.jpeg",
    imageClass: "",
  },
  {
    title: "Group Coaching",
    href: "#",
    description:
      "You and your friends, colleagues, or family members have the same goal you wanted to tackle for quite some time but need a custom guide that leads the way? My group coaching is an excellent way to finally tackle those goals! The benefits of group coaching are reduced individual cost and you have your own accountability partners right there! Schedule a free discovery call today to find out more!",
    imageUrl: "/assets/img/1000x800/content-6.jpeg",
    imageClass: "object-left-top",
  },
  {
    title: "Nutrition Webinar",
    href: "#",
    description:
      "A custom webinar is a great way to broaden your horizon when it comes to nutrition and healthy lifestyle changes. Corporate or private, I'm happy to chat with you to see how I can supply you with the nutritional education you need.",
    imageUrl: "/assets/img/800x1000/content-5.jpeg",
    imageClass: "object-bottom",
  },
];

function MainPage() {
  return (
    <div className="relative overflow-hidden bg-white">
      <section>
        <div className="bg-gray-50">
          <div className="mx-auto max-w-7xl px-3 py-3 sm:px-6 lg:px-8">
            <div className="flex flex-wrap items-center justify-between">
              <div className="flex flex-1 items-center">
                <span className="flex p-2">
                  <img
                    src="/logo_deliish_small.svg"
                    alt="Deliish Logo small"
                    className="h-6 w-6 text-white"
                    aria-hidden="true"
                  />
                </span>
                <p className="ml-3 truncate font-medium text-cornflower-600">
                  <span className="md:hidden">Book a free discovery call!</span>
                  <span className="hidden md:inline">
                    <span className="mr-2 font-script font-normal">
                      Start today
                    </span>
                    Book a free discovery call now.
                  </span>
                </p>
              </div>
              <div className="mt-2 w-full flex-shrink-0 sm:mt-0 sm:w-auto">
                <BookCallButton
                  text="Schedule Call"
                  url="https://calendly.com/deliish/discovery-call"
                  classNames={
                    "flex items-center justify-center px-4 py-2 text-sm font-medium text-white border border-transparent rounded-md bg-cornflower-600 hover:bg-cornflower-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-cornflower-500 w-full shadow-sm"
                  }
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="mx-auto max-w-7xl px-4 py-12 sm:py-16 sm:px-6 lg:px-8">
          <div className="mx-auto grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-3">
            {headerImages.map((image, ix) => (
              <div
                key={image.title}
                className={
                  "flex flex-col overflow-hidden rounded-lg shadow-lg " +
                  (ix === 2
                    ? "hidden lg:flex"
                    : ix === 1
                    ? "hidden sm:flex"
                    : "")
                }
              >
                <div className="flex-shrink-0">
                  <img
                    className="h-[500px] w-full object-cover object-top"
                    src={image.imageUrl}
                    alt=""
                  />
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>

      <section>
        <div className="mx-auto max-w-5xl px-4 pt-12 pb-24 sm:px-6 sm:pt-16 sm:pb-24 lg:px-8">
          <div className="text-center">
            <h2 className="text-2xl font-semibold uppercase tracking-wider text-amber-500">
              Nutritionist &amp; Health Coach
            </h2>
            <p className="mt-8 text-4xl font-extralight capitalize leading-8 text-gray-900 sm:text-5xl sm:tracking-tight lg:text-6xl">
              <span>
                Helping You Feel Confident &amp; Start Health Habits You'll{" "}
              </span>
              <span className="font-script font-normal">enjoy!</span>
            </p>
          </div>
        </div>
      </section>

      <section>
        <div className="bg-gray-50">
          <div className="relative mx-auto max-w-7xl px-4 py-12 sm:px-6 sm:py-16 lg:px-8 ">
            <div className="mt-8 lg:grid lg:grid-cols-2 lg:gap-8">
              <div className="relative lg:col-start-2 lg:row-start-1">
                <div className="relative mx-auto max-w-prose text-base lg:max-w-none">
                  <figure>
                    <div className="aspect-w-12 aspect-h-7 lg:aspect-none">
                      <img
                        className="rounded-lg object-cover object-top shadow-lg"
                        src="/assets/img/800x1000/content-1.jpeg"
                        alt="Whitney leaning against a railing on a downtown street"
                        width={800}
                        height={1000}
                      />
                    </div>
                  </figure>
                </div>
              </div>
              <div className="mt-8 lg:mt-0">
                <div className="mx-auto max-w-prose text-base lg:max-w-none">
                  <div>
                    <h2 className="text-4xl font-extralight capitalize leading-8 text-gray-900 sm:text-5xl sm:tracking-tight lg:text-6xl">
                      <span>Let's work towards a healthier you, </span>
                      <span className="font-script font-normal">together!</span>
                    </h2>
                  </div>
                  <p className="mt-3 text-xl uppercase text-gray-700 sm:mt-4">
                    Are you ready to thrive?
                  </p>
                </div>
                <div className="prose-cornflower prose mx-auto mt-5 text-gray-500 lg:col-start-1 lg:row-start-1 lg:max-w-none">
                  <p>
                    Listen, I've been chatting with a lot of people and most of
                    them feel overwhelmed by all of the information out there.
                    They want to start eating more plants but are unsure of
                    where to start. They don't know which foods are good for
                    them and help them to reach their goals.
                  </p>
                  <p>
                    As a certified nutritionist I'm trained to guide you
                    step-by-step, meal-by-meal, and help you to become the
                    healthiest version of yourself. We will work through
                    roadblocks that have been holding you back for too long. Let
                    go of what no longer serves you and unlock your true
                    potential!
                  </p>
                  <p>
                    Don't waste one more minute and take advantage of a free
                    discovery call to get started today!
                  </p>
                  <div>
                    <BookCallButton
                      text="Schedule Call"
                      url="https://calendly.com/deliish/discovery-call"
                      classNames={
                        "flex items-center justify-center w-full px-5 py-3 mt-3 text-base font-medium text-white border border-transparent rounded-md bg-cornflower-600 hover:bg-cornflower-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-cornflower-500 sm:w-auto sm:flex-shrink-0 shadow-sm"
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="relative bg-gray-50 px-4 py-12 sm:px-6 lg:py-16 lg:px-8">
          <div className="absolute inset-0">
            <div className="h-1/3 bg-white sm:h-2/3" />
          </div>
          <div className="relative mx-auto max-w-7xl">
            <div className="text-center">
              <h2 className="font-script text-4xl font-normal capitalize tracking-tight text-gray-900 sm:text-5xl lg:text-6xl">
                let's make a positive impact
              </h2>
              <p className="mx-auto mt-3 max-w-2xl text-xl uppercase text-gray-700 sm:mt-4">
                How I can help transform your goals
              </p>
            </div>
            <div className="mx-auto mt-12 grid max-w-lg gap-5 lg:max-w-none lg:grid-cols-3">
              {services.map((post) => (
                <div
                  key={post.title}
                  className="flex flex-col overflow-hidden rounded-lg shadow-lg"
                >
                  <div className="flex-shrink-0">
                    <img
                      className={"h-80 w-full object-cover " + post.imageClass}
                      src={post.imageUrl}
                      alt={post.title}
                    />
                  </div>
                  <div className="flex flex-1 flex-col justify-between bg-white p-6">
                    <div className="flex-1">
                      <a href={post.href} className="mt-2 block">
                        <p className="text-center text-xl font-semibold text-gray-900">
                          {post.title}
                        </p>
                        <p className="mt-3 text-center text-base text-gray-500">
                          {post.description}
                        </p>
                      </a>
                    </div>
                  </div>
                </div>
              ))}
            </div>

            <div className="relative mx-auto max-w-xl pt-12">
              <div className="rounded-md bg-white p-4 ring-1 ring-cornflower-500">
                <div className="flex items-center">
                  <div className="flex-shrink-0">
                    <img
                      src="/logo_deliish_small.svg"
                      alt="Deliish Logo small"
                      className="h-5 w-5"
                      aria-hidden="true"
                    />
                  </div>
                  <div className="ml-3 flex-1 md:flex md:justify-between">
                    <p className="text-sm text-cornflower-600">
                      Book a free call to talk about your
                      <span className="ml-1 font-script text-lg font-normal">
                        journey
                      </span>
                    </p>
                    <p className="mt-3 inline-flex items-center text-sm text-cornflower-600 hover:text-cornflower-500 md:mt-0 md:ml-6">
                      <BookCallButton
                        text={
                          <>
                            <span>Schedule Call</span>
                            <span aria-hidden="true" className="ml-1">
                              &rarr;
                            </span>
                          </>
                        }
                        url="https://calendly.com/deliish/discovery-call"
                        classNames={"font-medium whitespace-nowrap "}
                      />
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="grid grid-cols-1 lg:grid-cols-3">
          <div className="">
            <img
              className="h-64 w-full object-cover lg:h-full lg:max-h-96"
              src="/assets/img/800x1000/content-2.jpeg"
              alt=""
            />
          </div>

          <div className="my-auto px-6 py-10 sm:px-10 lg:col-span-2 xl:p-12">
            <h2 className="text-4xl font-extralight capitalize leading-8 text-gray-900 sm:text-5xl sm:tracking-tight lg:text-6xl">
              <span className="block">Don't miss a thing </span>
              <span className="mt-3 block font-script text-3xl font-normal lg:text-4xl">
                Join the deliish fam
              </span>
            </h2>
            <p className="mt-3 text-base text-gray-500">
              Weekly newsletter with free recipes, nutrition advice &amp;
              science news nuggets.
            </p>

            <div className="pt-6 sm:w-full sm:max-w-3xl lg:mt-0 lg:flex-1">
              <SubscribeForm />
              <p className="mt-3 text-sm text-gray-400">
                We care about the protection of your data. Read our{" "}
                <Link
                  to={ROUTES.PRIVACY_POLICY}
                  className="font-medium text-gray-400 underline"
                >
                  Privacy Policy.
                </Link>
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="overflow-hidden border-t border-gray-200 bg-white">
        <div className="relative mx-auto max-w-7xl px-6 pt-12 pb-12 sm:px-10 sm:py-16">
          <div className="pb-12 text-center">
            <h2 className="font-script text-4xl font-normal capitalize tracking-tight text-gray-900 sm:text-5xl lg:text-6xl">
              About Eva
            </h2>
            <p className="mx-auto mt-3 max-w-2xl text-xl uppercase text-gray-700 sm:mt-4">
              Certified Nutritionist &amp; Health Coach
            </p>
          </div>
          <div className="relative flex justify-center lg:items-center">
            <div className="hidden lg:block lg:flex-shrink-0">
              <img
                className="h-64 w-64 rounded-full xl:h-80 xl:w-80"
                // src="/assets/img/PROFILE_EVA_KITCHEN.jpg"
                src="/assets/img/640x640/profile-eva-about.jpg"
                alt="Profile Eva"
              />
            </div>

            <div className="relative lg:ml-10">
              <svg
                className="absolute top-0 left-0 h-36 w-36 -translate-x-8 -translate-y-12 transform text-cornflower-200 opacity-50"
                stroke="currentColor"
                fill="none"
                viewBox="0 0 144 144"
                aria-hidden="true"
              >
                <path
                  strokeWidth={2}
                  d="M41.485 15C17.753 31.753 1 59.208 1 89.455c0 24.664 14.891 39.09 32.109 39.09 16.287 0 28.386-13.03 28.386-28.387 0-15.356-10.703-26.524-24.663-26.524-2.792 0-6.515.465-7.446.93 2.327-15.821 17.218-34.435 32.11-43.742L41.485 15zm80.04 0c-23.268 16.753-40.02 44.208-40.02 74.455 0 24.664 14.891 39.09 32.109 39.09 15.822 0 28.386-13.03 28.386-28.387 0-15.356-11.168-26.524-25.129-26.524-2.792 0-6.049.465-6.98.93 2.327-15.821 16.753-34.435 31.644-43.742L121.525 15z"
                />
              </svg>

              <div className="prose-cornflower prose mt-5 text-center text-base font-normal text-gray-500 lg:text-left">
                <p>
                  When I decided to change my diet and lifestyle, one of the
                  most exciting things was to explore all the new foods and
                  flavors I could now eat. I started to experiment with new
                  recipes early on and a delicious new world opened up.
                </p>
                <p>
                  To bring my body back into balance after suffering from an
                  autoimmune disease for many years, I changed everything with a
                  holistic approach. This led me to become certified in plant
                  based nutrition and working as a vegan recipe creator. Now I
                  help clients both improve their health and balance their
                  bodies through diet and lifestyle changes in the most
                  delicious way.
                </p>
                <p>
                  Learn more about how I can support you in{" "}
                  <span className="font-script text-lg font-normal">
                    your journey
                  </span>
                  , book a free call!
                </p>
              </div>
              <footer className="mt-8">
                <div className="mx-auto flex justify-center lg:justify-start">
                  <div className="flex-shrink-0 lg:hidden">
                    <img
                      className="h-12 w-12 rounded-full"
                      src="/assets/img/96x96/profile-eva-about.jpg"
                      alt=""
                    />
                  </div>
                  <div className="ml-4 lg:ml-0">
                    <div className="text-base font-medium text-gray-900">
                      Eva Wadl
                    </div>
                    <div className="text-base font-medium text-cornflower-700">
                      Founder, Deliish
                    </div>
                  </div>
                </div>
              </footer>
            </div>
          </div>
        </div>
      </section>

      <section>
        <FooterSocial />
      </section>
    </div>
  );
}

export default MainPage;
