import { isEmpty, isLoaded } from "react-redux-firebase";
import { createSelector } from "reselect";
import {
  includes,
  intersection,
  difference,
  slice,
  orderBy,
  find,
  intersectionBy,
  intersectionWith,
  differenceWith,
} from "lodash";

//#region CONST
const CREATOR_ID_EVA = "igD42UPvVzXDMoB7vnOGOVRsO313";
//#endregion

//#region FIRESTORE
const selectAllRecipes = (state) => state.firestore.ordered.recipes;
const selectAllMealPlans = (state) => state.firestore.ordered.mealPlans;
const selectAllCategories = (state) => state.firestore.ordered.categories;
const selectAllTags = (state) => state.firestore.ordered.tags;
const selectAllIngredients = (state) => state.firestore.ordered.ingredients;
const selectAllCreators = (state) => state.firestore.ordered.creators;
const selectAuth = (state) => state.firebase.auth;
const selectProfile = (state) => state.firebase.profile;
//#endregion

const getFilter = (state) => state.filter;
const getCreateRecipePendingState = (state) =>
  state.pendingStates["CREATE_RECIPE"];
const getCreateMealPlanPendingState = (state) =>
  state.pendingStates["CREATE_MEAL_PLAN"];

export const getProfile = createSelector(selectProfile, (profile) =>
  isLoaded(profile) && !isEmpty(profile) ? profile : null
);

export const getFavorites = createSelector(selectProfile, (profile) =>
  isLoaded(profile) && !isEmpty(profile) ? profile?.favorites || [] : []
);

export const getBookmarkedSaved = createSelector(selectProfile, (profile) =>
  isLoaded(profile) && !isEmpty(profile) ? profile.bookmarks?.Saved || [] : []
);

export const getBookmarkedMonday = createSelector(selectProfile, (profile) =>
  isLoaded(profile) && !isEmpty(profile) ? profile.bookmarks?.Monday || [] : []
);

export const getBookmarkedTuesday = createSelector(selectProfile, (profile) =>
  isLoaded(profile) && !isEmpty(profile) ? profile.bookmarks?.Tuesday || [] : []
);

export const getBookmarkedWednesday = createSelector(selectProfile, (profile) =>
  isLoaded(profile) && !isEmpty(profile)
    ? profile.bookmarks?.Wednesday || []
    : []
);

export const getBookmarkedThursday = createSelector(selectProfile, (profile) =>
  isLoaded(profile) && !isEmpty(profile)
    ? profile.bookmarks?.Thursday || []
    : []
);

export const getBookmarkedFriday = createSelector(selectProfile, (profile) =>
  isLoaded(profile) && !isEmpty(profile) ? profile.bookmarks?.Friday || [] : []
);

export const getBookmarkedSaturday = createSelector(selectProfile, (profile) =>
  isLoaded(profile) && !isEmpty(profile)
    ? profile.bookmarks?.Saturday || []
    : []
);

export const getBookmarkedSunday = createSelector(selectProfile, (profile) =>
  isLoaded(profile) && !isEmpty(profile) ? profile.bookmarks?.Sunday || [] : []
);

export const getShoppingList = createSelector(selectProfile, (profile) =>
  isLoaded(profile) && !isEmpty(profile) ? profile?.shoppingList || [] : []
);

export const getProfileImg = createSelector(selectProfile, (profile) =>
  isLoaded(profile) && !isEmpty(profile) && profile?.photoURL !== ""
    ? profile.photoURL
    : null
);

export const getIsAdmin = createSelector(
  selectProfile,
  (profile) => isLoaded(profile) && !isEmpty(profile) && profile.isAdmin
);

export const getIsSubscriber = createSelector(
  selectProfile,
  (profile) => isLoaded(profile) && !isEmpty(profile) && profile.isSubscriber
);

export const SelectAuthUID = createSelector(
  selectAuth,
  (auth) => auth?.uid ?? null
);

export const SelectUserEmail = createSelector(
  selectAuth,
  (auth) => auth?.email ?? ""
);

export const getCategories = createSelector(selectAllCategories, (categories) =>
  isLoaded(categories) ? categories : []
);

export const getFilteredCategories = createSelector(
  getFilter,
  (filter) => filter.categories || []
);

export const getFilteredShopCategories = createSelector(
  getFilter,
  (filter) => filter.shopCategories || []
);

export const getTags = createSelector(selectAllTags, (tags) =>
  isLoaded(tags) ? tags : []
);

export const getIngredients = createSelector(
  selectAllIngredients,
  (ingredients) => (isLoaded(ingredients) ? ingredients : [])
);

export const getFilteredTags = createSelector(
  getFilter,
  (filter) => filter.tags || []
);

export const getFilteredDietaryClaims = createSelector(
  getFilter,
  (filter) => filter.claims || []
);

export const getFilterFavoritesActive = createSelector(
  getFilter,
  (filter) => filter.favorites || false
);

export const getAllRecipes = createSelector(selectAllRecipes, (recipes) =>
  isLoaded(recipes) ? recipes : []
);

export const getPublishedRecipes = createSelector(selectAllRecipes, (recipes) =>
  isLoaded(recipes) ? recipes.filter((r) => r.meta.visibility.isPublished) : []
);

export const getUnpublishedRecipes = createSelector(
  [selectAllRecipes, getIsAdmin],
  (recipes, isAdmin) =>
    isLoaded(recipes) && isAdmin
      ? recipes.filter((r) => !r.meta.visibility.isPublished)
      : []
);

export const getFilteredRecipes = createSelector(
  [getPublishedRecipes, getFilter, getFavorites],
  (recipes, filter, favorites) =>
    recipes.filter(
      (r) =>
        (filter.favorites ? includes(favorites, r.id) : true) &&
        (filter.categories.length === 0 ||
          intersectionWith(
            r.meta?.categories,
            filter.categories,
            (arrVal, othVal) => {
              return arrVal === othVal.value;
            }
          ).length > 0) &&
        (filter.tags.length === 0 ||
          intersectionWith(r.meta?.tags, filter.tags, (arrVal, othVal) => {
            return arrVal === othVal.value;
          }).length > 0) &&
        (filter.claims.length === 0 ||
          differenceWith(filter.claims, r.meta?.claims, (arrVal, othVal) => {
            return arrVal.value === othVal;
          }).length === 0) // claims are logical AND
    )
);

export const getFavoriteRecipes = createSelector(
  [getPublishedRecipes, getFavorites],
  (recipes, favorites) => recipes.filter((r) => includes(favorites, r.id))
);

export const getRecipesSortedNewFirst = createSelector(
  [getPublishedRecipes],
  (recipes) => orderBy(recipes, (r) => r.createdAt, "desc")
);

export const getLatestRecipes = createSelector(
  [getRecipesSortedNewFirst],
  (recipes) => (recipes.length > 7 ? slice(recipes, 0, 8) : recipes)
);

export const getBookmarkSavedRecipes = createSelector(
  [getPublishedRecipes, getBookmarkedSaved],
  (recipes, filter) => recipes.filter((r) => includes(filter, r.id))
);

export const getBookmarkMondayRecipes = createSelector(
  [getPublishedRecipes, getBookmarkedMonday],
  (recipes, filter) => recipes.filter((r) => includes(filter, r.id))
);

export const getBookmarkTuesdayRecipes = createSelector(
  [getPublishedRecipes, getBookmarkedTuesday],
  (recipes, filter) => recipes.filter((r) => includes(filter, r.id))
);

export const getBookmarkWednesdayRecipes = createSelector(
  [getPublishedRecipes, getBookmarkedWednesday],
  (recipes, filter) => recipes.filter((r) => includes(filter, r.id))
);

export const getBookmarkThursdayRecipes = createSelector(
  [getPublishedRecipes, getBookmarkedThursday],
  (recipes, filter) => recipes.filter((r) => includes(filter, r.id))
);

export const getBookmarkFridayRecipes = createSelector(
  [getPublishedRecipes, getBookmarkedFriday],
  (recipes, filter) => recipes.filter((r) => includes(filter, r.id))
);

export const getBookmarkSaturdayRecipes = createSelector(
  [getPublishedRecipes, getBookmarkedSaturday],
  (recipes, filter) => recipes.filter((r) => includes(filter, r.id))
);

export const getBookmarkSundayRecipes = createSelector(
  [getPublishedRecipes, getBookmarkedSunday],
  (recipes, filter) => recipes.filter((r) => includes(filter, r.id))
);

export const isCreateRecipePending = createSelector(
  [getCreateRecipePendingState],
  (state) => state?.pending ?? false
);

export const isCreateMealPlanPending = createSelector(
  [getCreateMealPlanPendingState],
  (state) => state?.pending ?? false
);

export const getCreatorEva = createSelector(
  [selectAllCreators],
  (creators) => creators && find(creators, (c) => c.id === CREATOR_ID_EVA)
);

export const getPublishedMealPlans = createSelector(
  [selectAllMealPlans],
  (mealPlans) =>
    isLoaded(mealPlans)
      ? mealPlans.filter((r) => r.meta.visibility.isPublished)
      : []
);

export const getUnpublishedMealPlans = createSelector(
  [selectAllMealPlans, getIsAdmin],
  (mealPlans, isAdmin) =>
    isLoaded(mealPlans) && isAdmin
      ? mealPlans.filter((r) => !r.meta.visibility.isPublished)
      : []
);
