import React from "react";
import Step from "./Step";
import { v4 as uuidv4 } from "uuid";

const StepList = ({ steps }) => {
  return (
    <div className="flow-root">
      <ul className="-mb-8">
        {steps.map((step, ix) => (
          <Step {...step} ix={ix} len={steps.length} key={uuidv4()} />
        ))}
      </ul>
    </div>
  );
};

export default StepList;
