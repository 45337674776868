import * as Yup from "yup";

export const validationSchema = () => {
  return Yup.object().shape({
    title: Yup.string().required("Title is required!"),
    description: Yup.string().nullable(),
    image: Yup.mixed().nullable(),
    meta: Yup.object().shape({
      videoUrl: Yup.object().shape({
        url: Yup.string().matches(
          /^(https?:\/\/)?(www\.youtube\.com|youtu\.?be)\/.+$/,
          "Enter a valid YouTube URL!"
        ),
        description: Yup.string().nullable(),
      }),
      notes: Yup.object().shape({
        title: Yup.string().nullable(),
        description: Yup.string().nullable(),
      }),
      visibility: Yup.object().shape({
        isPublished: Yup.bool().required(),
        isFree: Yup.bool().required(),
      }),
      tags: Yup.array().of(
        Yup.string()
          .min(2, "A string of at least 2 characters is required!")
          .required("Empty tags are not allowed! Please remove if not needed.")
      ),
    }),
    days: Yup.array().of(
      Yup.object().shape({
        meals: Yup.array().of(
          Yup.object().shape({
            category: Yup.string()
              .required("Category is required!")
              .min(2, "A string of at least 2 characters is required!"),
            recipe: Yup.string()
              .required("Recipe is required!")
              .min(2, "A string of at least 2 characters is required!"),
            notes: Yup.string().nullable(),
          })
        ),
      })
    ),
  });
};
