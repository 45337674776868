import React, { useEffect, useState } from "react";

const ImageUploadThumbnail = ({ file }) => {
  const [loading, setLoading] = useState(false);
  const [thumb, setThumb] = useState(undefined);

  useEffect(() => {
    if (!file) return;

    setLoading(true);
    let reader = new FileReader();
    reader.onloadend = () => {
      setThumb(reader.result);
      setLoading(false);
    };
    reader.readAsDataURL(file);
  }, [file]);

  if (!file) {
    return null;
  }

  if (loading) {
    return <p>loading...</p>;
  }

  return (
    <img
      src={thumb}
      alt={file.name}
      className="h-auto border border-gray-300 rounded-md shadow-sm w-60"
    />
  );
};

export default ImageUploadThumbnail;
