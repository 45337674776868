export const formInitialValues = (existing = null) => {
  return {
    portraitImage: "",
    landscapeImage: "",
    data: existing ?? {
      title: "",
      description: "",
      meta: {
        videoUrl: {
          url: "",
          description: "",
        },
        notes: {
          title: "",
          description: "",
        },
        time: {
          title: "",
          amount: 20,
          unit: "min",
        },
        visibility: {
          isPublished: false,
          isFree: false,
        },
        categories: [],
        claims: [],
        tags: [],
      },
      ingredients: {
        servings: 2,
        groups: [],
      },
      steps: [],
      nutrition: {
        calories: {
          amount: null,
          unit: null
        },
        carbs: {
          amount: null,
          unit: null
        },
        protein: {
          amount: null,
          unit: null
        },
        fat: {
          amount: null,
          unit: null
        },
        sodium: {
          amount: null,
          unit: null
        },
        sugar: {
          amount: null,
          unit: null
        },
      },
      relatedRecipes: [],
    },
  };
};
