import React from "react";
import ShoppingListGridItem from "./ShoppingListGridItem";

function ShoppingListGridContainer({ title = null, items }) {
  return (
    <>
      <div className="overflow-hidden bg-white shadow-md sm:rounded-md">
        <div className="px-4 py-2 border-b border-gray-200 bg-gray-50 sm:px-6">
          <h3 className="text-sm font-semibold leading-6 text-center text-gray-600 uppercase">
            {title}
          </h3>
        </div>
        <ul className="divide-y divide-gray-200">
          {items.map((item, ix) => (
            <ShoppingListGridItem key={ix} {...item} />
          ))}
        </ul>
      </div>
    </>
  );
}

export default ShoppingListGridContainer;
