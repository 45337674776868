import { combineReducers } from 'redux'
import { firestoreReducer } from 'redux-firestore'
import { firebaseReducer } from 'react-redux-firebase'
import recipeReducer from './recipeReducer'
import userReducer from './userReducer'
import pendingReducer from './pendingReducer'
// import shoppingListReducer from './shoppingListReducer'
import mealPlanReducer from './mealPlanReducer'
import modalReducer from './modalReducer'
import { reducer as toastrReducer } from 'react-redux-toastr'
import filterReducer from './filterReducer'

const rootReducer = combineReducers({
    firebase: firebaseReducer,
    firestore: firestoreReducer,
    toastr: toastrReducer,
    pendingStates: pendingReducer,
    recipe: recipeReducer,
    user: userReducer,
    mealPlan: mealPlanReducer,
    modal: modalReducer,
    filter: filterReducer
})

export default rootReducer