import { ViewGridIcon } from "@heroicons/react/solid";
import { motion } from "framer-motion";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import FooterSocial from "../layout/FooterSocials";
import * as ROUTES from "../../constants/routes";
import moment from "moment";
import { PlusSmIcon } from "@heroicons/react/outline";
import { useSelector } from "react-redux";
import {
  getBookmarkFridayRecipes,
  getBookmarkMondayRecipes,
  getBookmarkSaturdayRecipes,
  getBookmarkSavedRecipes,
  getBookmarkSundayRecipes,
  getBookmarkThursdayRecipes,
  getBookmarkTuesdayRecipes,
  getBookmarkWednesdayRecipes,
} from "../../store/selectors/selectors";
import RecipeGrid from "../recipes/RecipeGrid";
import { useFirestoreConnect } from "react-redux-firebase";
import PageHeader from "../headers/PageHeader";
import { filter, includes } from "lodash";
import RecipeGridScrollable from "../recipes/RecipeGridScrollable";


const getWeekDays = () => {
  let days = [];
  for (let index = 0; index < 7; index++) {
    const day = moment().add(index, "days");
    days.push({
      id: day.format("dddd"),
      name:
        index === 0
          ? day.format("[Today,] dddd")
          : index === 1
          ? day.format("[Tomorrow,] dddd")
          : day.format("dddd"),
    });
  }
  return days;
};

function MealPlan() {
  useFirestoreConnect(() => [
    {
      collection: "recipes",
      orderBy: [["createdAt", "desc"]],
      storeAs: "recipes",
    },
  ]);
  const today = moment().dayOfYear();
  const [weekDays, setWeekDays] = useState([]);
  useEffect(() => {
    setWeekDays(getWeekDays());
  }, [today]);

  const [recipes, setRecipes] = useState({
    Monday: [],
    Tuesday: [],
    Wednesday: [],
    Thursday: [],
    Friday: [],
    Saturday: [],
    Sunday: [],
  });

  const savedRecipes = useSelector(getBookmarkSavedRecipes);

  const mondayRecipes = useSelector(getBookmarkMondayRecipes);
  useEffect(() => {
    setRecipes((s) => {
      return {
        ...s,
        Monday: mondayRecipes,
      };
    });
  }, [mondayRecipes]);

  const tuesdayRecipes = useSelector(getBookmarkTuesdayRecipes);
  useEffect(() => {
    setRecipes((s) => {
      return {
        ...s,
        Tuesday: tuesdayRecipes,
      };
    });
  }, [tuesdayRecipes]);

  const wednesdayRecipes = useSelector(getBookmarkWednesdayRecipes);
  useEffect(() => {
    setRecipes((s) => {
      return {
        ...s,
        Wednesday: wednesdayRecipes,
      };
    });
  }, [wednesdayRecipes]);

  const thursdayRecipes = useSelector(getBookmarkThursdayRecipes);
  useEffect(() => {
    setRecipes((s) => {
      return {
        ...s,
        Thursday: thursdayRecipes,
      };
    });
  }, [thursdayRecipes]);

  const fridayRecipes = useSelector(getBookmarkFridayRecipes);
  useEffect(() => {
    setRecipes((s) => {
      return {
        ...s,
        Friday: fridayRecipes,
      };
    });
  }, [fridayRecipes]);

  const saturdayRecipes = useSelector(getBookmarkSaturdayRecipes);
  useEffect(() => {
    setRecipes((s) => {
      return {
        ...s,
        Saturday: saturdayRecipes,
      };
    });
  }, [saturdayRecipes]);

  const sundayRecipes = useSelector(getBookmarkSundayRecipes);
  useEffect(() => {
    setRecipes((s) => {
      return {
        ...s,
        Sunday: sundayRecipes,
      };
    });
  }, [sundayRecipes]);

  return (
    <>
      <motion.div
        exit={{ opacity: 0 }}
        className="min-h-screen-content relative bg-white"
      >
        <PageHeader
          title={"Meal Plan"}
          subtitle={"Being prepared is everything"}
          description={""}
        />

        <div className="relative mx-auto max-w-7xl overflow-hidden bg-white p-4 sm:px-6 lg:px-8">
          <div className="py-4 sm:py-6 lg:py-8">
            <div className="pb-4">
              <h1 className="font-script text-3xl font-normal capitalize text-gray-900 lg:text-4xl">
                Saved for later
              </h1>
              <p className="mt-2 text-sm text-gray-700">
                Tip: Assign meals to your weekly plan by clicking the bookmark
                icon.
              </p>
            </div>
            <RecipeGridScrollable recipes={savedRecipes} showBookmark={true} />
          </div>

          {/* week view */}
          <div className="py-4 sm:py-6 lg:py-8">
            <div className="xs:flex xs:items-center xs:justify-between">
              <div className="xs:flex-auto">
                <h1 className="font-script text-3xl font-normal capitalize text-gray-900 lg:text-4xl">
                  My week
                </h1>
                <p className="mt-2 text-sm text-gray-700">
                  Your meals this week.
                </p>
              </div>
              <div className="mt-4 self-start xs:mt-0 xs:ml-16 xs:flex-none">
                <div className="mt-3 xs:mt-0 xs:ml-4">
                  <Link
                    to={ROUTES.BROWSE_MEAL_PLANS}
                    className="inline-flex w-full items-center justify-center rounded-md border border-transparent bg-red-50 px-4 py-2 text-base font-medium text-red-400 hover:bg-red-100 hover:text-red-500 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 xs:w-auto xs:justify-start"
                  >
                    <ViewGridIcon
                      className="mr-3 -ml-1 h-5 w-5"
                      aria-hidden="true"
                    />
                    Meal Plans
                  </Link>
                </div>
              </div>
            </div>
            <div className="mt-8 flex flex-col">
              <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                  <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                    <table className="min-w-full divide-y divide-gray-300">
                      <thead className="bg-gray-50">
                        <tr className="divide-x divide-gray-200">
                          <th
                            scope="col"
                            className="py-3.5 px-4 text-left text-sm font-semibold text-gray-900"
                          ></th>
                          <th
                            scope="col"
                            className="py-3.5 px-4 text-left text-sm font-semibold text-gray-900"
                          >
                            Breakfast
                          </th>
                          <th
                            scope="col"
                            className="px-4 py-3.5 text-left text-sm font-semibold text-gray-900"
                          >
                            Lunch
                          </th>
                          <th
                            scope="col"
                            className="px-4 py-3.5 text-left text-sm font-semibold text-gray-900"
                          >
                            Dinner
                          </th>
                          <th
                            scope="col"
                            className="py-3.5 px-4 text-left text-sm font-semibold text-gray-900"
                          >
                            Snack / Treat / Side
                          </th>
                        </tr>
                      </thead>
                      <tbody className="divide-y divide-gray-200 bg-white">
                        {weekDays.map((day) => (
                          <tr
                            key={day.id}
                            // height="300px"
                            className="divide-x divide-gray-200"
                          >
                            <td className="whitespace-normal bg-gray-50 p-4 text-sm font-semibold text-gray-900">
                              {day.name}
                            </td>
                            <td className="whitespace-nowrap p-4 align-top text-sm font-medium text-gray-900">
                              <RecipeGridScrollable
                                recipes={filter(recipes[day.id], (r) =>
                                  includes(r.meta.categories, "breakfast")
                                )}
                                key={"grid" + day.id}
                              />
                            </td>
                            <td className="whitespace-nowrap p-4 align-top text-sm text-gray-500">
                              <RecipeGridScrollable
                                recipes={filter(recipes[day.id], (r) =>
                                  includes(r.meta.categories, "lunch")
                                )}
                                key={"grid" + day.id}
                              />
                            </td>
                            <td className="whitespace-nowrap p-4 align-top text-sm text-gray-500">
                              <RecipeGridScrollable
                                recipes={filter(recipes[day.id], (r) =>
                                  includes(r.meta.categories, "dinner")
                                )}
                                key={"grid" + day.id}
                              />
                            </td>
                            <td className="whitespace-nowrap px-4 py-4 align-top text-sm text-gray-500">
                              <RecipeGridScrollable
                                recipes={filter(
                                  recipes[day.id],
                                  (r) =>
                                    includes(r.meta.categories, "snack") ||
                                    includes(r.meta.categories, "treat") ||
                                    includes(r.meta.categories, "side")
                                )}
                                key={"grid" + day.id}
                              />
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </motion.div>
    </>
  );
}

export default MealPlan;
