import React from "react";
import { motion } from "framer-motion";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";

const SettingsSchema = Yup.object().shape({
  language: Yup.string().required("Required"),
});

function Settings() {
  return (
    <motion.div
      exit={{ opacity: 0 }}
      className="py-4 min-h-screen-content bg-gray-50 sm:p-6 lg:p-8"
    >
      <div className="mt-10 sm:mt-0">
        <div className="md:grid md:grid-cols-3 md:gap-6">
          <div className="md:col-span-1">
            <div className="px-4 sm:px-0">
              <h3 className="text-lg font-medium leading-6 text-gray-900">
                Settings
              </h3>
              <p className="mt-1 text-sm text-gray-600">
                Your global app preferences.
              </p>
            </div>
          </div>
          <div className="mt-5 md:mt-0 md:col-span-2">
            <Formik
              initialValues={{
                language: "English",
              }}
              validationSchema={SettingsSchema}
              onSubmit={(values, { setSubmitting }) => {
                setSubmitting(false);
                // dispatch(updateCreatorProfile(values))
                //   .then(() => {
                //     setSubmitting(false);
                //   })
                //   .catch((err) => {
                //     setSubmitting(false);
                //   });
              }}
            >
              {({ isSubmitting, errors, touched }) => (
                <Form>
                  <div className="overflow-hidden shadow sm:rounded-md">
                    <div className="px-4 py-5 space-y-6 bg-white sm:p-6">
                      <div className="grid grid-cols-6 gap-6">
                        <div className="col-span-6 sm:col-span-3">
                          <label
                            htmlFor="country"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Language
                          </label>
                          <Field
                            disabled
                            as="select"
                            id="language"
                            name="language"
                            autoComplete="language"
                            className="block w-full px-3 py-2 mt-1 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-cornflower-500 focus:border-cornflower-500 sm:text-sm"
                          >
                            <option value="English">English</option>
                            <option value="German">German</option>
                          </Field>
                        </div>
                      </div>
                      {/* <fieldset>
                    <legend className="text-base font-medium text-gray-900">
                      By Email
                    </legend>
                    <div className="mt-4 space-y-4">
                      <div className="flex items-start">
                        <div className="flex items-center h-5">
                          <input
                            id="comments"
                            name="comments"
                            type="checkbox"
                            className="w-4 h-4 border-gray-300 rounded text-cornflower-600 focus:ring-cornflower-500"
                          />
                        </div>
                        <div className="ml-3 text-sm">
                          <label
                            htmlFor="comments"
                            className="font-medium text-gray-700"
                          >
                            Comments
                          </label>
                          <p className="text-gray-500">
                            Get notified when someones posts a comment on a
                            posting.
                          </p>
                        </div>
                      </div>
                      <div className="flex items-start">
                        <div className="flex items-center h-5">
                          <input
                            id="candidates"
                            name="candidates"
                            type="checkbox"
                            className="w-4 h-4 border-gray-300 rounded text-cornflower-600 focus:ring-cornflower-500"
                          />
                        </div>
                        <div className="ml-3 text-sm">
                          <label
                            htmlFor="candidates"
                            className="font-medium text-gray-700"
                          >
                            Candidates
                          </label>
                          <p className="text-gray-500">
                            Get notified when a candidate applies for a job.
                          </p>
                        </div>
                      </div>
                      <div className="flex items-start">
                        <div className="flex items-center h-5">
                          <input
                            id="offers"
                            name="offers"
                            type="checkbox"
                            className="w-4 h-4 border-gray-300 rounded text-cornflower-600 focus:ring-cornflower-500"
                          />
                        </div>
                        <div className="ml-3 text-sm">
                          <label
                            htmlFor="offers"
                            className="font-medium text-gray-700"
                          >
                            Offers
                          </label>
                          <p className="text-gray-500">
                            Get notified when a candidate accepts or rejects an
                            offer.
                          </p>
                        </div>
                      </div>
                    </div>
                  </fieldset>
                  <fieldset>
                    <div>
                      <legend className="text-base font-medium text-gray-900">
                        Push Notifications
                      </legend>
                      <p className="text-sm text-gray-500">
                        These are delivered via SMS to your mobile phone.
                      </p>
                    </div>
                    <div className="mt-4 space-y-4">
                      <div className="flex items-center">
                        <input
                          id="push_everything"
                          name="push_notifications"
                          type="radio"
                          className="w-4 h-4 border-gray-300 text-cornflower-600 focus:ring-cornflower-500"
                        />
                        <label
                          htmlFor="push_everything"
                          className="block ml-3 text-sm font-medium text-gray-700"
                        >
                          Everything
                        </label>
                      </div>
                      <div className="flex items-center">
                        <input
                          id="push_email"
                          name="push_notifications"
                          type="radio"
                          className="w-4 h-4 border-gray-300 text-cornflower-600 focus:ring-cornflower-500"
                        />
                        <label
                          htmlFor="push_email"
                          className="block ml-3 text-sm font-medium text-gray-700"
                        >
                          Same as email
                        </label>
                      </div>
                      <div className="flex items-center">
                        <input
                          id="push_nothing"
                          name="push_notifications"
                          type="radio"
                          className="w-4 h-4 border-gray-300 text-cornflower-600 focus:ring-cornflower-500"
                        />
                        <label
                          htmlFor="push_nothing"
                          className="block ml-3 text-sm font-medium text-gray-700"
                        >
                          No push notifications
                        </label>
                      </div>
                    </div>
                  </fieldset> */}
                    </div>
                    <div className="px-4 py-3 text-right bg-gray-50 sm:px-6">
                      <button
                        disabled
                        type="submit"
                        className="inline-flex justify-center px-4 py-2 text-sm font-medium text-white border border-transparent rounded-md shadow-sm cursor-default bg-cornflower-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cornflower-500" //hover:bg-cornflower-700
                      >
                        Save
                      </button>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </motion.div>
  );
}

export default Settings;
