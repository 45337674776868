import React from "react";
import { ArrowCircleUpIcon, LockClosedIcon } from "@heroicons/react/outline";
import ConditionalLink from "../utils/ConditionalLink";
import { getIsAdmin } from "../../store/selectors/selectors";
import { useDispatch, useSelector } from "react-redux";
import ConditionalDiv from "../utils/ConditionalDiv";
import { showModal } from "../../store/actions/modalActions";

function MealPlanGridItem({ id, thumbnailUrl, title, meta }) {
  const dispatch = useDispatch();
  const isFree = meta?.visibility.isFree ?? false;
  const isAdmin = useSelector(getIsAdmin);
  const isAccessible = isFree || isAdmin;
  return (
    <li className="relative">
      <div className="absolute z-20 top-1 left-1">
        {isFree ? null : (
          <div
            className="p-1 text-gray-600 rounded-full bg-gray-50 bg-opacity-70 focus:outline-none"
            aria-label="Locked (paid recipe)"
          >
            <LockClosedIcon className="w-6 h-6 stroke-current" />
          </div>
        )}
      </div>
      <ConditionalLink to={`/browse/mealPlans/${id}`} condition={isAccessible}>
        <ConditionalDiv
          className="cursor-pointer"
          condition={!isAccessible}
          onClick={() =>
            dispatch(
              showModal(
                {
                  open: true,
                  title: "Sign up for our course to access this content!",
                  message:
                    "In addition no our free content we provide resources exclusive to members. Click below to find out more.",
                },
                "locked"
              )
            )
          }
        >
          <figure className="relative block overflow-hidden rounded-lg focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-cornflower-500 group">
            <div className="relative w-full h-full overflow-hidden rounded-lg bg-gray-50 aspect-w-4 aspect-h-3">
              <div className="transition-all duration-300 group-hover:filter group-hover:blur-sm">
                <img
                  src={thumbnailUrl || "./android-chrome-512x512.jpg"}
                  alt={title}
                  className="object-cover w-full h-full pointer-events-none "
                />
              </div>
              <div className="absolute inset-0 z-10 flex items-center justify-center text-sm font-semibold text-white transition-opacity duration-300 opacity-0 group-hover:opacity-100">
                View meal plan
                <ArrowCircleUpIcon className="w-5 h-5 ml-2 text-current transform rotate-45" />
              </div>
            </div>
            <figcaption>
              <p className="block mt-2 text-sm font-semibold text-gray-900 capitalize pointer-events-none line-clamp-2">
                {title}
              </p>
            </figcaption>
          </figure>
        </ConditionalDiv>
      </ConditionalLink>
    </li>
  );
}

export default MealPlanGridItem;
