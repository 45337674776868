import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { isEmpty } from "react-redux-firebase";
import { motion } from "framer-motion";
import RecipeGrid from "../recipes/RecipeGrid";
import { clearShoppingList } from "../../store/actions/userActions";
import ShoppingListGridItemContainer from "./ShoppingListGridItemContainer";
import ShoppingListEmptyBanner from "./ShoppingListEmptyBanner";
import { groupByArray, onlyUnique } from "../utils/Utils";
import AddCustomItem from "./AddCustomItem";
import {
  getPublishedRecipes,
  getShoppingList,
} from "../../store/selectors/selectors";
import { TrashIcon } from "@heroicons/react/solid";
import { v4 as uuidv4 } from "uuid";
import FooterSocial from "../layout/FooterSocials";
import PageHeader from "../headers/PageHeader";
import RecipeGridScrollable from "../recipes/RecipeGridScrollable";

const ShoppingList = () => {
  const dispatch = useDispatch();
  const shoppingList = useSelector(getShoppingList);
  const recipeList = useSelector(getPublishedRecipes);

  let recipeIds = []; // unique recipe ids
  let ingredients = []; // grouped ingredients by name, sum amount
  shoppingList.forEach((e) => {
    recipeIds.push(e.recipeId);
    let existingIngredient = ingredients.filter(
      (i) => i.name === e.name && i.unit === e.unit && i.inBasket === e.inBasket
    )[0];
    if (existingIngredient) {
      existingIngredient.amount += e.amount;
      existingIngredient.recipeIds.push(e.recipeId);
    } else {
      ingredients.push({
        ...e,
        recipeIds: [e.recipeId],
      });
    }
  });
  if (recipeIds.length > 0) {
    recipeIds = recipeIds.filter(onlyUnique);
  }
  const recipes = recipeList?.filter((r) => recipeIds?.includes(r.id));

  return (
    <>
      <motion.div
        exit={{ opacity: 0 }}
        className="min-h-screen-content relative overflow-hidden bg-white"
      >
        <PageHeader
          title={"Shopping List"}
          subtitle={"Let's make a plan"}
          description={
            "Good to know: Add your own items (tooth paste, shampoo etc.) down below and customise your list."
          }
        />
        <div className="relative mx-auto max-w-7xl p-4 sm:p-6 lg:p-8">
          <div className="pb-5 xs:flex xs:items-center xs:justify-between">
            {isEmpty(shoppingList) ? null : (
              <button
                type="button"
                className="inline-flex w-full items-center justify-center rounded-md border border-transparent bg-red-50 px-4 py-2 text-base font-medium text-red-400 hover:bg-red-100 hover:text-red-500 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 xs:w-auto xs:justify-start"
                onClick={() => dispatch(clearShoppingList())}
              >
                <TrashIcon className="mr-3 -ml-1 h-5 w-5" aria-hidden="true" />
                Clear All
              </button>
            )}
          </div>

          <div className="">
            {isEmpty(shoppingList) ? (
              <ShoppingListEmptyBanner />
            ) : (
              <div className="grid grid-cols-1 gap-y-4 sm:m-1 sm:grid-cols-2 sm:gap-x-6 sm:gap-y-6 lg:grid-cols-3 xl:grid-cols-4">
                {groupByArray(ingredients, "category").map((item, index) => {
                  return (
                    <ShoppingListGridItemContainer
                      key={"container-" + index}
                      title={item.key}
                      items={item.values}
                    />
                  );
                })}
              </div>
            )}
          </div>
        </div>
        <div className="relative bg-gray-50">
          <div className="mx-auto max-w-7xl p-4 sm:p-6 lg:p-8">
            <AddCustomItem />
          </div>
        </div>
        <div className="mx-auto max-w-7xl p-4 sm:p-6 lg:p-8">
          {isEmpty(recipes) ? null : (
            <>
              <div className="pb-4">
                <h1 className="font-script text-3xl font-normal capitalize text-gray-900 lg:text-4xl">
                  On your list
                </h1>
                <p className="mt-2 text-sm text-gray-700">
                  Your shopping list includes ingredients for the following
                  recipes:
                </p>
              </div>
              <RecipeGridScrollable recipes={recipes} />
            </>
          )}
        </div>
      </motion.div>
    </>
  );
};

export default ShoppingList;
