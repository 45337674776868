import * as ActionTypes from "../../constants/ActionTypes";

export const reset = () => (dispatch) => {
  dispatch({
    type: ActionTypes.FILTER_RESET,
  });
};

export const categorySnapshot = (categories) => (dispatch) => {
  dispatch({
    type: ActionTypes.FILTER_CATEGORY_SNAPSHOT,
    categories,
  });
};

export const toggleCategory = (category) => (dispatch) => {
  dispatch({
    type: ActionTypes.FILTER_TOGGLE_CATEGORY,
    category,
  });
};

export const setSingleCategory = (category) => (dispatch) => {
  dispatch({
    type: ActionTypes.FILTER_SET_SINGLE_CATEGORY,
    category,
  });
};

export const toggleTag = (tag) => (dispatch) => {
  dispatch({
    type: ActionTypes.FILTER_TOGGLE_TAG,
    tag,
  });
};

export const setSingleTag = (tag) => (dispatch) => {
  dispatch({
    type: ActionTypes.FILTER_SET_SINGLE_TAG,
    tag,
  });
};

export const claimsSnapshot = (claims) => (dispatch) => {
  dispatch({
    type: ActionTypes.FILTER_CLAIMS_SNAPSHOT,
    claims,
  });
};

export const toggleClaims = (claim) => (dispatch) => {
  dispatch({
    type: ActionTypes.FILTER_TOGGLE_CLAIMS,
    claim,
  });
};

export const toggleShopCategory = (category) => (dispatch) => {
  dispatch({
    type: ActionTypes.FILTER_SHOP_TOGGLE_CATEGORY,
    category,
  });
};

export const toggleFavorites = () => (dispatch) => {
  dispatch({
    type: ActionTypes.FILTER_TOGGLE_FAVORITES,
  });
};
