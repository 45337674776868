import React from "react";
import { useDispatch } from "react-redux";
import {
  removeGroupFromShoppingList,
  setInBasket,
} from "../../store/actions/userActions";
import { CheckCircleIcon as CheckCircleIconSolid } from "@heroicons/react/solid";
import {
  CheckCircleIcon as CheckCircleIconOutline,
  XIcon,
} from "@heroicons/react/outline";
import { classNames } from "../utils/Utils";

function ShoppingListGridItem({ id, name, amount, unit, inBasket, recipeIds }) {
  const dispatch = useDispatch();

  const handleRemove = (e) => {
    e.stopPropagation();
    for (let index = 0; index < recipeIds.length; index++) {
      let rId = recipeIds[index];
      dispatch(removeGroupFromShoppingList(rId, name));
    }
  };

  return (
    <>
      <li
        key={id}
        onClick={() => dispatch(setInBasket(recipeIds, name, !inBasket))}
        className="z-10 cursor-pointer hover:bg-gray-50"
      >
        <div className="flex items-center px-4 py-3 sm:px-2">
          <div className="flex items-center flex-1 min-w-0">
            <div className="flex-shrink-0">
              {inBasket ? (
                <CheckCircleIconSolid className="w-6 h-6 text-red-100 fill-current" />
              ) : (
                <CheckCircleIconOutline className="w-6 h-6 text-red-400 stroke-current hover:text-red-500" />
              )}
            </div>
            <div className="flex-1 min-w-0 px-4">
              <div className={inBasket ? "text-gray-300" : "text-gray-900"}>
                <p className="text-sm font-medium capitalize truncate">
                  {name}
                </p>
              </div>
              <div className="text-sm">
                <p className={inBasket ? "text-gray-300" : "text-gray-500"}>
                  {amount} {unit}
                </p>
              </div>
            </div>
            <div className="z-20 flex-shrink-0">
              <button
                onClick={handleRemove}
                className={classNames(
                  inBasket ? "text-red-100" : "text-red-400",
                  "p-1 fill-current rounded-full hover:text-red-500 focus:outline-none z-20"
                )}
              >
                <XIcon
                  className="w-4 h-4 stroke-current"
                  aria-label="Delete from shopping list"
                />
              </button>
            </div>
          </div>
        </div>
      </li>
    </>
  );
}

export default ShoppingListGridItem;
