import React from "react";
// import { connect, useSelector } from 'react-redux'
import { getFirebase } from "react-redux-firebase";
import * as firebaseui from "firebaseui";
import { motion } from "framer-motion";
import styled from "styled-components";
import { useEffect } from "react";
import * as ROUTES from "../../constants/routes";
import axios from "axios";

const Container = styled(motion.div)`
  min-height: calc(100vh - 4rem); //search bar is 63px + 1px border (4rem)
`;

const SignIn = () => {
  //const auth = useSelector(state => state.firebase.auth);

  useEffect(() => {
    const firebase = getFirebase();
    // Configure FirebaseUI.
    const uiConfig = {
      // Popup signin flow rather than redirect flow.
      signInFlow: "popup",
      // Redirect to /signedIn after sign in is successful. Alternatively you can provide a callbacks.signInSuccess function.
      signInSuccessUrl: ROUTES.LANDING,
      // We will display Google and Facebook as auth providers.
      signInOptions: [
        {
          provider: firebase.auth.EmailAuthProvider.PROVIDER_ID,
          requireDisplayName: false,
          signInMethod:
            firebase.auth.EmailAuthProvider.EMAIL_PASSWORD_SIGN_IN_METHOD,
        },
        {
          provider: firebase.auth.GoogleAuthProvider.PROVIDER_ID,
          // customParameters: {
          //     // Forces account selection even when one account
          //     // is available.
          //     prompt: 'select_account'
          // }
        },
        {
          provider: firebase.auth.FacebookAuthProvider.PROVIDER_ID,
          scopes: ["public_profile", "email"],
        },
      ],
      credentialHelper: firebaseui.auth.CredentialHelper.NONE,
      tosUrl: ROUTES.TERMS_OF_SERVICE,
      privacyPolicyUrl: ROUTES.PRIVACY_POLICY,
      callbacks: {
        signInSuccessWithAuthResult: function (authResult, redirectUrl) {
          var isNewUser = authResult.additionalUserInfo.isNewUser;

          if (isNewUser) {
            var email = authResult.additionalUserInfo.profile.email;
            var firstName = authResult.additionalUserInfo.profile.given_name;
            axios.post(
              "https://api.convertkit.com/v3/forms/5481849/subscribe",
              {
                api_key: "l4qY2T8_fv4ihdtHL7d0Yg",
                email: email,
                first_name: firstName,
                tags: [4041349],
              }
            );
          }
        },
        // signInFailure: function(error) {
        //   // Some unrecoverable error occurred during sign-in.
        //   // Return a promise when error handling is completed and FirebaseUI
        //   // will reset, clearing any UI. This commonly occurs for error code
        //   // 'firebaseui/anonymous-upgrade-merge-conflict' when merge conflict
        //   // occurs. Check below for more details on this.
        //   return handleUIError(error);
        // },
        // uiShown: function() {
        //   // The widget is rendered.
        //   // Hide the loader.
        //   document.getElementById('loader').style.display = 'none';
        // }
      },
    };

    var ui =
      firebaseui.auth.AuthUI.getInstance() ??
      new firebaseui.auth.AuthUI(firebase.auth());

    if (document.getElementById("firebaseui-auth-container") != null) {
      ui.start("#firebaseui-auth-container", uiConfig);
    }

    return () => {
      var ui = firebaseui.auth.AuthUI.getInstance();
      ui?.reset();
    };
  }, []);

  return (
    <Container
      exit={{ opacity: 0 }}
      className="flex flex-col justify-start bg-gray-50 py-12 sm:px-6 lg:px-8"
    >
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        {/* <img
            className="w-auto h-12 mx-auto"
            src="https://tailwindui.com/img/logos/workflow-mark-cornflower-600.svg"
            alt="Workflow"
          /> */}
        <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
          Sign in to your account
        </h2>
        <p className="mt-2 text-center text-sm font-medium text-cornflower-600">
          Or create a new account for free
        </p>
      </div>

      <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
        <div className="bg-white px-4 py-8 shadow sm:rounded-lg sm:px-10">
          <div id="firebaseui-auth-container" className="text-center"></div>
        </div>
      </div>
    </Container>
  );
};

export default SignIn;
