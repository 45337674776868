function getActionName(actionType) {
    if (typeof actionType !== 'string') {
        return null;
    }

    return actionType
        .split("_")
        .slice(0, -1)
        .join("_");
}


const pendingReducer = (state = {}, action) => {

    const { type } = action;
    const actionName = getActionName(type);

    if (!actionName) {
        return {
            ...state,
        }
    }

    if (type.endsWith("_REQUEST")) {
        return {
            ...state,
            [actionName]: {
                pending: true
            }
        };
    }

    if (type.endsWith("_SUCCESS") || type.endsWith("_FAILURE") || type.endsWith("_ERROR")) {
        return {
            ...state,
            [actionName]: {
                pending: false
            }
        };
    }

    return {
        ...state
    };
};

export default pendingReducer;