import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInstagram, faYoutube } from "@fortawesome/free-brands-svg-icons";
import { Link } from "react-router-dom";
import * as ROUTES from "../../constants/routes";

const navigation = {
  main: [
    { name: "Home", href: ROUTES.LANDING },
    // { name: "Browse", href: ROUTES.BROWSE },
    // { name: "Courses", href: ROUTES.COURSES_REBOOT },
    { name: "Terms", href: ROUTES.TERMS_OF_SERVICE },
    { name: "Privacy", href: ROUTES.PRIVACY_POLICY },
  ],
  social: [
    {
      name: "YouTube",
      href: "https://www.youtube.com/channel/UCuocEwp40Pz5VrcxlymeYpQ",
      icon: (props) => <FontAwesomeIcon icon={faYoutube} {...props} />,
    },
    {
      name: "Instagram",
      href: "https://www.instagram.com/deliish.nutrition/",
      icon: (props) => <FontAwesomeIcon icon={faInstagram} {...props} />,
    },
  ],
};

export default function FooterSocial() {
  return (
    <div className="relative mx-auto border-t border-gray-200 p-4 sm:px-6 lg:px-8">
      <nav
        className="-mx-5 -my-2 mb-4 flex flex-wrap justify-center md:mb-0"
        aria-label="Footer"
      >
        {navigation.main.map((item) => (
          <div key={item.name} className="px-5 py-2">
            <Link
              to={item.href}
              className="text-sm text-gray-500 hover:text-gray-900"
            >
              {item.name}
            </Link>
          </div>
        ))}
      </nav>

      <div className="md:flex md:items-center md:justify-between">
        <div className="flex justify-center space-x-6 md:order-2">
          {navigation.social.map((item) => (
            <a
              key={item.name}
              href={item.href}
              target="_blank"
              rel="noreferrer"
              className="text-gray-400 hover:text-gray-500"
            >
              <span className="sr-only">{item.name}</span>
              <item.icon className="w-6-i h-6" aria-hidden="true" />
            </a>
          ))}
        </div>
        <div className="mt-4 md:order-1 md:mt-0">
          <p className="text-center text-sm text-gray-400">
            &copy; 2022 The Hooray Media, Ltd. All rights reserved.
          </p>
        </div>
      </div>
    </div>
  );
}
