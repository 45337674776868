import { chain, value, map, groupBy } from "lodash";
import React, { useEffect, useState } from "react";
import NumberField from "../../formFields/NumberField";
import IngredientGroupArrayField from "../../formFields/recipe/IngredientGroupArrayField";
import StepsArrayField from "../../formFields/recipe/StepsArrayField";
import SelectField from "../../formFields/SelectField";
import TextField from "../../formFields/TextField";

function IngredientsForm(props) {
  const ingredientList = props.ingredients;

  const [choices, setChoices] = useState([]);
  useEffect(() => {
    setChoices(
      chain(ingredientList)
        .groupBy((i) => i.category)
        .map((ingredients, category) => {
          return {
            title: category,
            suggestions: map(ingredients, (i) => ({
              id: i.name,
              text: i.name,
            })),
          };
        })
        .value()
    );
  }, [ingredientList]);

  const {
    formField: { data },
  } = props;

  return (
    <>
      <div className="px-4 py-5 bg-white shadow sm:rounded-lg sm:p-6">
        <div className="md:grid md:grid-cols-3 md:gap-6">
          <div className="md:col-span-1">
            <h3 className="text-lg font-medium leading-6 text-gray-900">
              Ingredients
            </h3>
            <p className="mt-1 text-sm text-gray-500">...</p>
          </div>

          <div className="mt-5 space-y-4 md:mt-0 md:col-span-2">
            <div>
              <NumberField {...data.ingredients.servings} />
            </div>
            <div>
              <IngredientGroupArrayField
                {...data.ingredients.groups}
                choices={choices}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="px-4 py-5 bg-white shadow sm:rounded-lg sm:p-6">
        <div className="md:grid md:grid-cols-3 md:gap-6">
          <div className="md:col-span-1">
            <h3 className="text-lg font-medium leading-6 text-gray-900">
              Steps
            </h3>
            <p className="mt-1 text-sm text-gray-500">
              Describe all steps to prepare this recipe (in order of execution).
            </p>
          </div>

          <div className="mt-5 space-y-4 md:mt-0 md:col-span-2">
            <div>
              <StepsArrayField {...data.steps} />
            </div>
          </div>
        </div>
      </div>

      <div className="px-4 py-5 bg-white shadow sm:rounded-lg sm:p-6">
        <div className="md:grid md:grid-cols-3 md:gap-6">
          <div className="md:col-span-1">
            <h3 className="text-lg font-medium leading-6 text-gray-900">
              Nutrition information per serving
            </h3>
            <p className="mt-1 text-sm text-gray-500">
              This section is optional and can be edited later.
            </p>
          </div>

          <div className="mt-5 space-y-4 md:mt-0 md:col-span-2">
            <div className="grid w-full grid-cols-3 gap-4">
              <div className="flex items-center col-span-1">
                <p className="self-center text-sm text-gray-500">Calories</p>
              </div>

              <div className="col-span-1">
                <TextField {...data.nutrition.calories.amount} />
              </div>

              <div className="col-span-1">
                <SelectField {...data.nutrition.calories.unit} />
              </div>

              <div className="flex items-center col-span-1">
                <p className="self-center text-sm text-gray-500">Carbs</p>
              </div>

              <div className="col-span-1">
                <TextField {...data.nutrition.carbs.amount} />
              </div>

              <div className="col-span-1">
                <SelectField {...data.nutrition.carbs.unit} />
              </div>

              <div className="flex items-center col-span-1">
                <p className="self-center text-sm text-gray-500">Protein</p>
              </div>

              <div className="col-span-1">
                <TextField {...data.nutrition.protein.amount} />
              </div>

              <div className="col-span-1">
                <SelectField {...data.nutrition.protein.unit} />
              </div>

              <div className="flex items-center col-span-1">
                <p className="self-center text-sm text-gray-500">Fat</p>
              </div>

              <div className="col-span-1">
                <TextField {...data.nutrition.fat.amount} />
              </div>

              <div className="col-span-1">
                <SelectField {...data.nutrition.fat.unit} />
              </div>

              <div className="flex items-center col-span-1">
                <p className="self-center text-sm text-gray-500">Sodium</p>
              </div>

              <div className="col-span-1">
                <TextField {...data.nutrition.sodium.amount} />
              </div>

              <div className="col-span-1">
                <SelectField {...data.nutrition.sodium.unit} />
              </div>

              <div className="flex items-center col-span-1">
                <p className="self-center text-sm text-gray-500">Sugar</p>
              </div>

              <div className="col-span-1">
                <TextField {...data.nutrition.sugar.amount} />
              </div>

              <div className="col-span-1">
                <SelectField {...data.nutrition.sugar.unit} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default IngredientsForm;
