import React from "react";
import AddItemForm from "../forms/shoppingList/AddItemForm";

const AddCustomItem = () => {
  return (
    <div className="md:grid md:grid-cols-3 md:gap-6">
      <div className="md:col-span-1">
        <div className="px-4 sm:px-0">
          <h1 className="font-script text-3xl font-normal capitalize text-gray-900 lg:text-4xl">
            Add to list
          </h1>
          <p className="mt-2 text-sm text-gray-700">
            You can choose to add a custom item to your shopping list.
          </p>
        </div>
      </div>
      <div className="mt-5 md:col-span-2 md:mt-0">
        <AddItemForm />
      </div>
    </div>
  );
};

export default AddCustomItem;
