import React from "react";
import YoutubeVideoGridItem from "./YoutubeVideoGridItem.jsx";

function YoutubeVideoGrid({ videos }) {
  return (
    <>
      <ul className="grid grid-cols-2 gap-x-4 gap-y-8 sm:grid-cols-3 sm:gap-x-6 lg:grid-cols-4 2xl:grid-cols-6 xl:gap-x-8">
        {videos.map((video) => (
          <YoutubeVideoGridItem {...video} key={video.videoPublishedAt} />
        ))}
      </ul>
    </>
  );
}

export default YoutubeVideoGrid;
