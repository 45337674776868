import { motion } from "framer-motion";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faYoutube } from "@fortawesome/free-brands-svg-icons";
import React from "react";
import { Link } from "react-router-dom";
import * as ROUTES from "../../constants/routes";
import { AcademicCapIcon, ViewGridIcon, SparklesIcon } from "@heroicons/react/solid";

const navigation = [
  // {
  //   name: "50$ Plant-based bundle",
  //   href: "https://bit.ly/3xEderh",
  //   isExternal: true,
  //   icon: (props) => <SparklesIcon {...props} />,
  // },
  {
    name: "My Youtube channel",
    href: "https://www.youtube.com/channel/UCuocEwp40Pz5VrcxlymeYpQ",
    isExternal: true,
    icon: (props) => <FontAwesomeIcon icon={faYoutube} {...props} />,
  },
  {
    name: "Deliish app",
    href: ROUTES.BROWSE,
    isExternal: false,
    icon: (props) => <ViewGridIcon {...props} />,
  },
  {
    name: "Join plant reboot",
    href: ROUTES.COURSES_REBOOT,
    isExternal: false,
    icon: (props) => <AcademicCapIcon {...props} />,
  },
];

function Hello() {
  return (
    <motion.div exit={{ opacity: 0 }} className="relative bg-white">
      <div className="relative px-4 py-8 mx-auto bg-white max-w-7xl sm:px-6 lg:px-8">
        <div className="max-w-3xl mx-auto">
          <div className="space-y-6">
            <img
              className="w-40 h-40 mx-auto rounded-full xl:w-56 xl:h-56"
              src="/assets/img/PROFILE_EVA_KITCHEN.jpg"
              alt=""
            />
            <div className="mx-auto text-center">
              <div className="space-y-1 text-base font-medium leading-6">
                <h3>Eva | Plant-Based Coach</h3>
                <p className="text-cornflower-600">@evawadl</p>
              </div>
              {/* <ul className="flex justify-center space-x-5">
            <li>
              <a
                href={person.twitterUrl}
                className="text-gray-400 hover:text-gray-500"
              >
                <span className="sr-only">Twitter</span>
                <svg
                  className="w-5 h-5"
                  aria-hidden="true"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                >
                  <path d="M6.29 18.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0020 3.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.073 4.073 0 01.8 7.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 010 16.407a11.616 11.616 0 006.29 1.84" />
                </svg>
              </a>
            </li>
            <li>
              <a
                href={person.linkedinUrl}
                className="text-gray-400 hover:text-gray-500"
              >
                <span className="sr-only">LinkedIn</span>
                <svg
                  className="w-5 h-5"
                  aria-hidden="true"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                >
                  <path
                    fillRule="evenodd"
                    d="M16.338 16.338H13.67V12.16c0-.995-.017-2.277-1.387-2.277-1.39 0-1.601 1.086-1.601 2.207v4.248H8.014v-8.59h2.559v1.174h.037c.356-.675 1.227-1.387 2.526-1.387 2.703 0 3.203 1.778 3.203 4.092v4.711zM5.005 6.575a1.548 1.548 0 11-.003-3.096 1.548 1.548 0 01.003 3.096zm-1.337 9.763H6.34v-8.59H3.667v8.59zM17.668 1H2.328C1.595 1 1 1.581 1 2.298v15.403C1 18.418 1.595 19 2.328 19h15.34c.734 0 1.332-.582 1.332-1.299V2.298C19 1.581 18.402 1 17.668 1z"
                    clipRule="evenodd"
                  />
                </svg>
              </a>
            </li>
          </ul> */}
            </div>

            <div className="space-y-4 xs:grid xs:grid-cols-1 xs:mx-auto">
              {navigation.map((item) =>
                item.isExternal ? (
                  <a
                    key={item.name}
                    href={item.href}
                    className="inline-flex items-center justify-center w-full px-6 py-3 text-base font-medium text-white border border-transparent rounded-md shadow-sm xs:mx-auto bg-cornflower-600 xs:max-w-sm hover:bg-cornflower-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cornflower-500"
                  >
                    <span className="sr-only">{item.name}</span>
                    {item.icon != null ? (
                      <item.icon
                        className="h-6 mr-3 -ml-1 w-6-i"
                        aria-hidden="true"
                      />
                    ) : null}
                    <span className="font-medium uppercase">{item.name}</span>
                  </a>
                ) : (
                  <Link
                    key={item.name}
                    to={item.href}
                    className="inline-flex items-center justify-center w-full px-6 py-3 text-base font-medium text-white border border-transparent rounded-md shadow-sm bg-cornflower-600 xs:mx-auto xs:max-w-sm hover:bg-cornflower-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cornflower-500"
                  >
                    <span className="sr-only">{item.name}</span>
                    {item.icon != null ? (
                      <item.icon
                        className="h-6 mr-3 -ml-1 w-6-i"
                        aria-hidden="true"
                      />
                    ) : null}
                    <span className="font-medium uppercase">{item.name}</span>
                  </Link>
                )
              )}
            </div>
          </div>
        </div>
      </div>
    </motion.div>
  );
}

export default Hello;
