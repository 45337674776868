import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { showModal } from "../../store/actions/modalActions";
import {
  BookmarkIcon as BookmarkIconOutline,
  XIcon,
} from "@heroicons/react/outline";
import { BookmarkIcon as BookmarkIconSolid } from "@heroicons/react/solid";
import {
  getBookmarkedFriday,
  getBookmarkedMonday,
  getBookmarkedSaturday,
  getBookmarkedSaved,
  getBookmarkedSunday,
  getBookmarkedThursday,
  getBookmarkedTuesday,
  getBookmarkedWednesday,
  getFavorites,
  getProfile,
} from "../../store/selectors/selectors";
import { Dialog, Transition } from "@headlessui/react";
import { Fragment } from "react";
import { Form, Formik } from "formik";
import CheckboxField from "../formFields/CheckboxField";
import CheckboxGroupField from "../formFields/CheckboxGroupField";
import { updateBookmarks } from "../../store/actions/userActions";
import { includes } from "lodash";

const formModel = {
  saveForLater: {
    name: "saveForLater",
    label: "Save for later",
  },
  days: {
    name: "days",
    options: [
      { name: "Monday", value: "Monday" },
      { name: "Tuesday", value: "Tuesday" },
      { name: "Wednesday", value: "Wednesday" },
      { name: "Thursday", value: "Thursday" },
      { name: "Friday", value: "Friday" },
      { name: "Saturday", value: "Saturday" },
      { name: "Sunday", value: "Sunday" },
    ],
  },
};

const BookmarkButton = ({ recipeId, thumbnailUrl, title, categories }) => {
  const dispatch = useDispatch();
  const profile = useSelector(getProfile);

  const [open, setOpen] = useState(false);
  const [formInitialValues, setFormInitialValues] = useState({
    saveForLater: false,
    days: [],
  });
  const bookmarkedSavedList = useSelector(getBookmarkedSaved);
  const isBookmarkSaved = includes(bookmarkedSavedList, recipeId);

  const bookmarkedMondayList = useSelector(getBookmarkedMonday);
  const isBookmarkMonday = includes(bookmarkedMondayList, recipeId);
  const bookmarkedTuesdayList = useSelector(getBookmarkedTuesday);
  const isBookmarkTuesday = includes(bookmarkedTuesdayList, recipeId);
  const bookmarkedWednesdayList = useSelector(getBookmarkedWednesday);
  const isBookmarkWednesday = includes(bookmarkedWednesdayList, recipeId);
  const bookmarkedThursdayList = useSelector(getBookmarkedThursday);
  const isBookmarkThursday = includes(bookmarkedThursdayList, recipeId);
  const bookmarkedFridayList = useSelector(getBookmarkedFriday);
  const isBookmarkFriday = includes(bookmarkedFridayList, recipeId);
  const bookmarkedSaturdayList = useSelector(getBookmarkedSaturday);
  const isBookmarkSaturday = includes(bookmarkedSaturdayList, recipeId);
  const bookmarkedSundayList = useSelector(getBookmarkedSunday);
  const isBookmarkSunday = includes(bookmarkedSundayList, recipeId);

  useEffect(() => {
    const daysBookmarked = [];
    if (isBookmarkMonday) {
      daysBookmarked.push("Monday");
    }
    if (isBookmarkTuesday) {
      daysBookmarked.push("Tuesday");
    }
    if (isBookmarkWednesday) {
      daysBookmarked.push("Wednesday");
    }
    if (isBookmarkThursday) {
      daysBookmarked.push("Thursday");
    }
    if (isBookmarkFriday) {
      daysBookmarked.push("Friday");
    }
    if (isBookmarkSaturday) {
      daysBookmarked.push("Saturday");
    }
    if (isBookmarkSunday) {
      daysBookmarked.push("Sunday");
    }
    // for (let index = 0; index < 7; index++) {
    //   const element = formModel.days.options[index].value;
    //   if (includes(profile?.bookmarks?.[element], recipeId)) {
    //     daysBookmarked.push(element);
    //   }
    // }

    setFormInitialValues({
      saveForLater: isBookmarkSaved ? true : false,
      days: daysBookmarked,
    });
  }, [
    isBookmarkSaved,
    isBookmarkMonday,
    isBookmarkTuesday,
    isBookmarkWednesday,
    isBookmarkThursday,
    isBookmarkFriday,
    isBookmarkSaturday,
    isBookmarkSunday,
  ]);

  if (profile === null) {
    return (
      <button
        className="p-1 text-gray-400 rounded-full bg-gray-50 bg-opacity-70 hover:text-gray-500 focus:outline-none"
        onClick={() =>
          dispatch(
            showModal(
              {
                open: true,
                title: "Sign up for free to save your bookmarks!",
                message:
                  "We cannot save your bookmarked recipes if you are not signed in to your account. If you have not already, you can create a new account for free in just a few seconds.",
              },
              "sign-in"
            )
          )
        }
        aria-label="Add Bookmark"
      >
        <BookmarkIconOutline className="w-6 h-6 stroke-current" />
      </button>
    );
  }

  if (recipeId === null || recipeId === undefined) {
    return null;
  }

  return (
    <>
      {formInitialValues.days.length || isBookmarkSaved ? (
        <button
          className="p-1 text-gray-400 rounded-full bg-gray-50 bg-opacity-70 hover:text-gray-500 focus:outline-none"
          onClick={() => setOpen(true)}
          aria-label="Remove Bookmark"
        >
          <BookmarkIconSolid className="w-6 h-6 fill-current" />
        </button>
      ) : (
        <button
          className="p-1 text-gray-400 rounded-full bg-gray-50 bg-opacity-70 hover:text-gray-500 focus:outline-none"
          onClick={() => setOpen(true)}
          aria-label="Add Bookmark"
        >
          <BookmarkIconOutline className="w-6 h-6 stroke-current" />
        </button>
      )}
      <Transition.Root show={open} as={Fragment}>
        <Dialog
          as="div"
          static
          className="fixed inset-0 z-50 overflow-hidden"
          open={open}
          onClose={setOpen}
        >
          <div className="absolute inset-0 overflow-hidden">
            <Transition.Child
              as={Fragment}
              enter="ease-in-out duration-500"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in-out duration-500"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="absolute inset-0 transition-opacity bg-gray-500 bg-opacity-75" />
            </Transition.Child>

            <div className="fixed inset-y-0 right-0 flex max-w-full pl-16">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <div className="w-screen max-w-sm">
                  <Formik
                    initialValues={formInitialValues}
                    // validationSchema={}
                    onSubmit={(values, actions) => {
                      dispatch(updateBookmarks(recipeId, values));
                      actions.setTouched({});
                      actions.setSubmitting(false);
                      setOpen(false);
                    }}
                  >
                    {({ isSubmitting }) => (
                      <Form
                        id="BookmarkForm"
                        className="flex flex-col h-full bg-white divide-y divide-gray-200 shadow-xl "
                      >
                        <div className="flex-1 h-0 overflow-y-auto">
                          <div className="px-4 py-6 bg-cornflower-700 sm:px-6">
                            <div className="flex items-center justify-between">
                              <Dialog.Title className="text-lg font-medium text-white">
                                Meal Planner
                              </Dialog.Title>
                              <div className="flex items-center ml-3 h-7">
                                <button
                                  type="button"
                                  className="rounded-md text-cornflower-200 bg-cornflower-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-white"
                                  onClick={() => setOpen(false)}
                                >
                                  <span className="sr-only">Close panel</span>
                                  <XIcon
                                    className="w-6 h-6"
                                    aria-hidden="true"
                                  />
                                </button>
                              </div>
                            </div>
                            <div className="mt-1">
                              <p className="text-sm text-cornflower-300">
                                Bookmark recipe for specific days.
                              </p>
                            </div>
                          </div>
                          <div className="flex flex-col justify-between flex-1">
                            <figure className="p-4">
                              <div className="relative w-full h-full overflow-hidden rounded-lg bg-gray-50 aspect-w-1 aspect-h-1">
                                <img
                                  src={thumbnailUrl}
                                  alt="Recipe thumbnail"
                                  className="object-cover w-full h-full pointer-events-none "
                                />
                              </div>
                              <figcaption>
                                <p className="block mt-2 text-base font-semibold text-gray-900 capitalize pointer-events-none line-clamp-2">
                                  {title}
                                </p>
                                <ul className="inline-block mr-2 space-x-2 space-y-1">
                                  {categories.map((c) => {
                                    return (
                                      <li
                                        key={c}
                                        className="inline-flex items-center px-2.5 py-0.5 rounded-md text-xs font-medium bg-gray-800 text-gray-50 capitalize"
                                      >
                                        {c}
                                      </li>
                                    );
                                  })}
                                </ul>
                              </figcaption>
                            </figure>
                            <div className="px-4 pt-2 pb-4 space-y-4 divide-y divide-gray-200 divide-dashed sm:px-6">
                              <div>
                                <CheckboxField {...formModel.saveForLater} />
                              </div>
                              <div className="pt-4">
                                <CheckboxGroupField {...formModel.days} />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="flex justify-end flex-shrink-0 px-4 py-4">
                          <button
                            type="button"
                            className="hidden px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm md:block hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cornflower-500"
                            onClick={() => setOpen(false)}
                          >
                            Cancel
                          </button>
                          <button
                            type="submit"
                            disabled={isSubmitting}
                            className="inline-flex justify-center w-full px-4 py-2 ml-0 text-sm font-medium text-white border border-transparent rounded-md shadow-sm sm:ml-4 bg-cornflower-600 hover:bg-cornflower-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cornflower-500 sm:w-auto"
                          >
                            Save
                          </button>
                        </div>
                      </Form>
                    )}
                  </Formik>
                </div>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
};

export default BookmarkButton;
