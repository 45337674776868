import React from "react";
import { Link } from "react-router-dom";
import * as ROUTES from "../../constants/routes";
import styled from "styled-components";
import { motion } from "framer-motion";
import { ArrowSmLeftIcon } from "@heroicons/react/outline";

const BackgroundElement = styled.div`
  background-image: url("./assets/svg/illustrations/error-404.svg");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  background-origin: border-box;
  height: -webkit-fill-available;
  height: 100%;
`;

const Error = () => {
  return (
    <>
      <motion.div
        exit={{ opacity: 0 }}
        className="relative bg-white h-screen-content"
      >
        <BackgroundElement className="h-full">
          <div className="flex items-center justify-center h-full ml-0 md:justify-start md:ml-12">
            <div>
              <div className="mb-8">
                <img
                  className="mb-3 max-w-23rem"
                  src="./assets/svg/illustrations/error-number-404.svg"
                  alt="SVG Illustration"
                />
                <p className="text-base font-normal">
                  Oops! Looks like you followed a bad link.
                </p>
              </div>
              <div>
                <Link
                  to={ROUTES.LANDING}
                  className="inline-flex items-center justify-center w-full px-4 py-2 mt-4 text-base font-medium text-white border border-transparent rounded-md shadow-sm md:mt-0 xs:justify-start xs:w-auto bg-cornflower-600 hover:bg-cornflower-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cornflower-500 sm:w-auto sm:text-sm"
                >
                  <ArrowSmLeftIcon
                    className="w-5 h-5 mr-3 -ml-1"
                    aria-hidden="true"
                  />
                  Back Home
                </Link>
              </div>
            </div>
          </div>
        </BackgroundElement>
      </motion.div>
    </>
  );
};

export default Error;
