import { showModal } from "./modalActions";
import * as ActionTypes from "../../constants/ActionTypes";
import { filter, includes, xor } from "lodash";

// TODO: align and improve notification system + integrate "framer toasts"
export const addToFavorites = (recipeId) => {
  return async (dispatch, getState, getFirebase) => {
    const uid = getState().firebase.auth.uid;

    if (!uid) {
      dispatch({ type: "ADD_FAVORITE_ERROR", err: "User not authenticated!" });
      return;
    }

    const firebase = getFirebase();
    const firestore = getFirebase().firestore();
    const analytics = getFirebase().analytics();

    await firestore
      .collection("users")
      .doc(uid)
      .update({
        favorites: firebase.firestore.FieldValue.arrayUnion(recipeId),
      })
      .then(() => {
        dispatch({ type: "ADD_FAVORITE_SUCCESS", recipeId: recipeId });
      })
      .catch((err) => {
        dispatch({ type: "ADD_FAVORITE_ERROR", err });
      });

    analytics.logEvent("select_item", { item_id: recipeId });
  };
};

export const removeFromFavorites = (recipeId) => {
  return async (dispatch, getState, getFirebase) => {
    const uid = getState().firebase.auth.uid;

    if (!uid) {
      return dispatch({
        type: "REMOVE_FAVORITE_ERROR",
        err: "User not authenticated!",
      });
    }

    const firebase = getFirebase();
    const firestore = getFirebase().firestore();

    await firestore
      .collection("users")
      .doc(uid)
      .update({
        favorites: firebase.firestore.FieldValue.arrayRemove(recipeId),
      })
      .then(() => {
        dispatch({ type: "REMOVE_FAVORITE_SUCCESS", recipeId: recipeId });
      })
      .catch((err) => {
        dispatch({ type: "REMOVE_FAVORITE_ERROR", err });
      });
  };
};

export const addToShoppingList = (recipeId, ingredient) => {
  return async (dispatch, getState, getFirebase) => {
    const uid = getState().firebase.auth.uid;
    if (!uid) {
      dispatch({
        type: ActionTypes.SHOPPING_LIST_ERROR,
        err: "User not authenticated!",
      });
      return dispatch(
        showModal(
          {
            open: true,
            title: "Sign up for free to manage your shopping list!",
            message:
              "We cannot save your shopping list if you are not signed in to your account. If you have not already, you can create a new account for free in just a few seconds.",
          },
          "sign-in"
        )
      );
    }

    const profile = getState().firebase.profile;
    if (
      (profile.shoppingList || []).some(
        (i) => i.recipeId === recipeId && i.id === ingredient.id
      )
    ) {
      return;
    }

    const firebase = getFirebase();
    await firebase
      .firestore()
      .collection("users")
      .doc(uid)
      .update({
        shoppingList: firebase.firestore.FieldValue.arrayUnion({
          ...ingredient,
          recipeId: recipeId,
          inBasket: false,
        }),
      })
      .catch((err) => {
        dispatch({ type: ActionTypes.SHOPPING_LIST_ERROR, err });
      });
  };
};

export const removeFromShoppingList = (recipeId, ingredientId) => {
  return async (dispatch, getState, getFirebase) => {
    const uid = getState().firebase.auth.uid;
    if (!uid) {
      dispatch({
        type: ActionTypes.SHOPPING_LIST_ERROR,
        err: "User not authenticated!",
      });
      return dispatch(
        showModal(
          {
            open: true,
            title: "Sign up for free to manage your shopping list!",
            message:
              "We cannot save your shopping list if you are not signed in to your account. If you have not already, you can create a new account for free in just a few seconds.",
          },
          "sign-in"
        )
      );
    }

    const profile = getState().firebase.profile;
    const updatedShoppingList = filter(profile.shoppingList || [], (e) => {
      if (e.recipeId !== recipeId) {
        return true;
      }
      return e.id !== ingredientId;
    });

    const firebase = getFirebase();
    await firebase
      .firestore()
      .collection("users")
      .doc(uid)
      .update({
        shoppingList: updatedShoppingList,
      })
      .catch((err) => {
        dispatch({ type: ActionTypes.SHOPPING_LIST_ERROR, err });
      });
  };
};

export const removeGroupFromShoppingList = (recipeId, ingredientName) => {
  return async (dispatch, getState, getFirebase) => {
    const uid = getState().firebase.auth.uid;
    if (!uid) {
      dispatch({
        type: ActionTypes.SHOPPING_LIST_ERROR,
        err: "User not authenticated!",
      });
      return dispatch(
        showModal(
          {
            open: true,
            title: "Sign up for free to manage your shopping list!",
            message:
              "We cannot save your shopping list if you are not signed in to your account. If you have not already, you can create a new account for free in just a few seconds.",
          },
          "sign-in"
        )
      );
    }

    console.log("Recipe: ", recipeId, " | Ingredient: ", ingredientName);

    const profile = getState().firebase.profile;
    const updatedShoppingList = filter(profile.shoppingList || [], (e) => {
      if (e.recipeId !== recipeId) {
        return true;
      }
      return e.name !== ingredientName;
    });

    console.log(updatedShoppingList)

    const firebase = getFirebase();
    await firebase
      .firestore()
      .collection("users")
      .doc(uid)
      .update({
        shoppingList: updatedShoppingList,
      })
      .catch((err) => {
        dispatch({ type: ActionTypes.SHOPPING_LIST_ERROR, err });
      });
  };
};

export const adjustShoppingList = (recipeId, factor) => {
  return async (dispatch, getState, getFirebase) => {
    const uid = getState().firebase.auth.uid;
    if (!uid) return;

    const profile = getState().firebase.profile;
    const updatedShoppingList = (profile.shoppingList || []).map((e) =>
      e.recipeId === recipeId
        ? {
            ...e,
            amount:
              Math.round((e.amount * factor + Number.EPSILON) * 100) / 100,
          }
        : e
    );

    const firebase = getFirebase();
    await firebase
      .firestore()
      .collection("users")
      .doc(uid)
      .update({
        shoppingList: updatedShoppingList,
      })
      .catch((err) => {
        dispatch({ type: ActionTypes.SHOPPING_LIST_ERROR, err });
      });
  };
};

export const deleteShoppingList = (recipeId) => {
  return async (dispatch, getState, getFirebase) => {
    const uid = getState().firebase.auth.uid;
    if (!uid) return;

    const profile = getState().firebase.profile;
    const updatedShoppingList = (profile.shoppingList || []).filter(
      (e) => e.recipeId !== recipeId
    );

    const firebase = getFirebase();
    await firebase
      .firestore()
      .collection("users")
      .doc(uid)
      .update({
        shoppingList: updatedShoppingList,
      })
      .catch((err) => {
        dispatch({ type: ActionTypes.SHOPPING_LIST_ERROR, err });
      });
  };
};

export const clearShoppingList = () => {
  return async (dispatch, getState, getFirebase) => {
    const uid = getState().firebase.auth.uid;
    if (!uid) {
      dispatch({
        type: ActionTypes.SHOPPING_LIST_ERROR,
        err: "User not authenticated!",
      });
      return dispatch(
        showModal(
          {
            open: true,
            title: "Sign up for free to manage your shopping list!",
            message:
              "We cannot save your shopping list if you are not signed in to your account. If you have not already, you can create a new account for free in just a few seconds.",
          },
          "sign-in"
        )
      );
    }

    await getFirebase()
      .firestore()
      .collection("users")
      .doc(uid)
      .update({
        shoppingList: [],
      })
      .catch((err) => {
        dispatch({ type: ActionTypes.SHOPPING_LIST_ERROR, err });
      });
  };
};

export const setInBasket = (recipeIds, ingredientName, isInBasket) => {
  return async (dispatch, getState, getFirebase) => {
    const uid = getState().firebase.auth.uid;
    if (!uid) return;
    // console.log("Recipes: ", recipeIds, " | Ingredient: ", ingredientName);
    const profile = getState().firebase.profile;
    const updatedShoppingList = (profile.shoppingList || []).map((e) =>
      recipeIds.includes(e.recipeId) && e.name === ingredientName
        ? { ...e, inBasket: isInBasket }
        : e
    );

    const firebase = getFirebase();
    await firebase
      .firestore()
      .collection("users")
      .doc(uid)
      .update({
        shoppingList: updatedShoppingList,
      })
      .catch((err) => {
        dispatch({ type: ActionTypes.SHOPPING_LIST_ERROR, err });
      });
  };
};

export const updateBookmarks = (recipeId, data) => {
  return async (dispatch, getState, getFirebase) => {
    const uid = getState().firebase.auth.uid;

    if (!uid) {
      dispatch({
        type: "UPDATE_BOOKMARKS_ERROR",
        err: "User not authenticated!",
      });
      return;
    }

    const firebase = getFirebase();
    const firestore = getFirebase().firestore();
    // const analytics = getFirebase().analytics();

    // const profile = getState().firebase.profile;
    // const bookmarks = profile.bookmarks || initialBookmarks;

    // var batch = firestore.batch();
    // const docRef = firestore.collection("users").doc(uid);

    // batch.update(docRef, )

    // batch.commit().then(() => {
    //   // ...
    // });
    await firestore
      .collection("users")
      .doc(uid)
      .update({
        "bookmarks.Saved": data.saveForLater
          ? firebase.firestore.FieldValue.arrayUnion(recipeId)
          : firebase.firestore.FieldValue.arrayRemove(recipeId),
        "bookmarks.Monday": includes(data.days, "Monday")
          ? firebase.firestore.FieldValue.arrayUnion(recipeId)
          : firebase.firestore.FieldValue.arrayRemove(recipeId),
        "bookmarks.Tuesday": includes(data.days, "Tuesday")
          ? firebase.firestore.FieldValue.arrayUnion(recipeId)
          : firebase.firestore.FieldValue.arrayRemove(recipeId),
        "bookmarks.Wednesday": includes(data.days, "Wednesday")
          ? firebase.firestore.FieldValue.arrayUnion(recipeId)
          : firebase.firestore.FieldValue.arrayRemove(recipeId),
        "bookmarks.Thursday": includes(data.days, "Thursday")
          ? firebase.firestore.FieldValue.arrayUnion(recipeId)
          : firebase.firestore.FieldValue.arrayRemove(recipeId),
        "bookmarks.Friday": includes(data.days, "Friday")
          ? firebase.firestore.FieldValue.arrayUnion(recipeId)
          : firebase.firestore.FieldValue.arrayRemove(recipeId),
        "bookmarks.Saturday": includes(data.days, "Saturday")
          ? firebase.firestore.FieldValue.arrayUnion(recipeId)
          : firebase.firestore.FieldValue.arrayRemove(recipeId),
        "bookmarks.Sunday": includes(data.days, "Sunday")
          ? firebase.firestore.FieldValue.arrayUnion(recipeId)
          : firebase.firestore.FieldValue.arrayRemove(recipeId),
      })
      .then(() => {
        dispatch({ type: "UPDATE_BOOKMARKS_SUCCESS", recipeId: recipeId });
      })
      .catch((err) => {
        dispatch({ type: "UPDATE_BOOKMARKS_ERROR", err });
      });

    // analytics.logEvent("select_item", { item_id: recipeId });
  };
};
