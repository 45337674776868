/* eslint-disable import/no-anonymous-default-export */
import * as Yup from "yup";

export default [
  Yup.object().shape({
    data: Yup.object().shape({
      title: Yup.string().required("Title is required!"),
      description: Yup.string().nullable(),
    }),
    portraitImage: Yup.mixed().nullable(),
    landscapeImage: Yup.mixed().nullable(),
  }),
  Yup.object().shape({
    data: Yup.object().shape({
      meta: Yup.object().shape({
        videoUrl: Yup.object().shape({
          url: Yup.string().matches(
            /^(https?:\/\/)?(www\.youtube\.com|youtu\.?be)\/.+$/,
            "Enter a valid YouTube URL!"
          ),
          description: Yup.string().nullable(),
        }),
        notes: Yup.object().shape({
          title: Yup.string().nullable(),
          description: Yup.string().nullable(),
        }),
        time: Yup.object().shape({
          title: Yup.string().nullable(),
          amount: Yup.number()
            .required("Amount is required!")
            .positive("Please enter a positive number!")
            .integer("Please enter an integer!")
            .typeError("Must be a number!"),
          unit: Yup.string().required("Unit is required!"),
        }),
        visibility: Yup.object().shape({
          isPublished: Yup.bool().required(),
          isFree: Yup.bool().required(),
        }),
        categories: Yup.array().of(
          Yup.string()
            .min(2, "A string of at least 2 characters is required!")
            .required(
              "Empty categories are not allowed! Please remove if not needed."
            )
        ),
        claims: Yup.array().of(
          Yup.string()
            .min(2, "A string of at least 2 characters is required!")
            .required(
              "Empty claims are not allowed! Please remove if not needed."
            )
        ),
        tags: Yup.array().of(
          Yup.string()
            .min(2, "A string of at least 2 characters is required!")
            .required(
              "Empty tags are not allowed! Please remove if not needed."
            )
        ),
      }),
    }),
  }),
  Yup.object().shape({
    data: Yup.object().shape({
      ingredients: Yup.object().shape({
        servings: Yup.number()
          .integer()
          .positive()
          .min(1)
          .max(8)
          .required("Servings are required!"),
        groups: Yup.array().of(
          Yup.object().shape({
            title: Yup.string().nullable(),
            ingredients: Yup.array().of(
              Yup.object().shape({
                name: Yup.string().required("Name is required!"),
                preparation: Yup.string().nullable(),
                amount: Yup.number().positive().required("Amount is required!"),
                unit: Yup.string().required("Unit is required!"),
                category: Yup.string().required("Category is required!"),
                altAmount: Yup.number().positive().nullable(),
                altUnit: Yup.string().nullable(),
                mealPrepTime: Yup.string().nullable(),
                mealPrepInstruction: Yup.string().nullable(),
              })
            ),
          })
        ),
      }),
      steps: Yup.array().of(
        Yup.object().shape({
          details: Yup.string()
            .required("Description is required!")
            .min(2, "A string of at least 2 characters is required!"),
        })
      ),
      nutrition: Yup.object().shape({
        calories: Yup.object().shape({
          amount: Yup.number().positive().nullable(),
          unit: Yup.string().nullable(),
        }),
        carbs: Yup.object().shape({
          amount: Yup.number().positive().nullable(),
          unit: Yup.string().nullable(),
        }),
        protein: Yup.object().shape({
          amount: Yup.number().positive().nullable(),
          unit: Yup.string().nullable(),
        }),
        fat: Yup.object().shape({
          amount: Yup.number().positive().nullable(),
          unit: Yup.string().nullable(),
        }),
        sodium: Yup.object().shape({
          amount: Yup.number().positive().nullable(),
          unit: Yup.string().nullable(),
        }),
        sugar: Yup.object().shape({
          amount: Yup.number().positive().nullable(),
          unit: Yup.string().nullable(),
        }),
      }),
    }),
  }),
  Yup.object().shape({
    data: Yup.object().shape({
      relatedRecipes: Yup.array().of(
        Yup.string()
          .required()
          .min(2, "A string of at least 2 characters is required!")
      ),
    }),
  }),
  Yup.mixed(),
];
