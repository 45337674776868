const getImageName = (imageDataUrl) => {
  const contents = imageDataUrl.split(";");

  if (contents.length < 3) {
    return "main.jpg";
  }

  if (contents[1].startsWith("name")) {
    return contents[1].split("name=")[1];
  }
};

const getDataUrl = (imageDataUrl) => {
  const contents = imageDataUrl.split(";");

  if (contents.length < 3) {
    return imageDataUrl;
  }

  return `${contents[0]};${contents[2]}`;
};

function readFile(file) {
  return new Promise((resolve, reject) => {
    var fr = new FileReader();
    fr.onload = () => {
      resolve(fr.result);
    };
    fr.onerror = reject;
    fr.readAsDataURL(file);
  });
}

export const initCreateRecipe = () => {
  return async (dispatch, getState, getFirebase) => {
    dispatch({ type: "CREATE_RECIPE_REQUEST" });
  };
};

export const createRecipe = (data) => {
  return async (dispatch, getState, getFirebase) => {
    // 1. Upload recipe data

    // author info
    const authorId = getState().firebase.auth.uid;
    const firestore = getFirebase().firestore();

    const docId = await firestore
      .collection("recipes")
      .add({
        ...data.data,
        authorId: authorId,
        createdAt: Date.now(),
      })
      .then((doc) => {
        return doc.id;
      })
      .catch((err) => {
        dispatch({ type: "CREATE_RECIPE_ERROR", error: err.message });
        return null;
      });

    if (docId === null) {
      return;
    }

    // await firestore.collection('recipes').doc(docId).update({
    //     createdAt: getFirebase().firestore.FieldValue.serverTimestamp()
    // });

    const storage = getFirebase().storage();
    const storageRef = storage.ref();

    // 2. Upload portrait image
    if (data.portraitImage !== "") {
      const fileRef = storageRef.child(`recipes/${docId}/Portrait.jpg`); //${getImageName(data.portraitImage)}

      readFile(data.portraitImage)
        .then(async (result) => {
          await fileRef.putString(result, "data_url");
        })
        .catch((err) => {
          dispatch({ type: "CREATE_RECIPE_ERROR", error: err.message });
          return;
        });
    }

    // 3. Upload landscape image
    if (data.landscapeImage !== "") {
      const fileRef = storageRef.child(`recipes/${docId}/Landscape.jpg`);

      readFile(data.landscapeImage)
        .then(async (result) => {
          await fileRef.putString(result, "data_url");
        })
        .catch((err) => {
          dispatch({ type: "CREATE_RECIPE_ERROR", error: err.message });
          return;
        });
    }

    return dispatch({ type: "CREATE_RECIPE_SUCCESS", recipeId: docId });
  };
};

export const updateRecipe = (id, data) => {
  return async (dispatch, getState, getFirebase) => {
    // author info
    const authorId = getState().firebase.auth.uid;

    // save recipe data in Firestore
    const firestore = getFirebase().firestore();

    await firestore
      .collection("recipes")
      .doc(id)
      .update({
        ...data.data,
        authorId: authorId,
        updatedAt: Date.now(),
      })
      .catch((err) => {
        dispatch({ type: "CREATE_RECIPE_ERROR", error: err.message });
        return null;
      });

    const storage = getFirebase().storage();
    const storageRef = storage.ref();

    // 2. Upload portrait image
    if (data.portraitImage !== "") {
      const fileRef = storageRef.child(`recipes/${id}/Portrait.jpg`); //${getImageName(data.portraitImage)}

      readFile(data.portraitImage)
        .then(async (result) => {
          await fileRef.putString(result, "data_url");
        })
        .catch((err) => {
          dispatch({ type: "CREATE_RECIPE_ERROR", error: err.message });
          return;
        });
    }

    // 3. Upload landscape image
    if (data.landscapeImage !== "") {
      const fileRef = storageRef.child(`recipes/${id}/Landscape.jpg`);

      readFile(data.landscapeImage)
        .then(async (result) => {
          await fileRef.putString(result, "data_url");
        })
        .catch((err) => {
          dispatch({ type: "CREATE_RECIPE_ERROR", error: err.message });
          return;
        });
    }

    return dispatch({ type: "CREATE_RECIPE_SUCCESS", recipeId: id });
  };
};

export const linkRecipeImage = () => {
  return async (dispatch, getState, getFirebase) => {
    const recipeId = getState().recipe.recipeId;
    const imageUploaded = getState().recipe.imageUploaded;

    if (recipeId !== null && imageUploaded === true) {
      const storage = getFirebase().storage();
      const storageRef = storage.ref();
      const fileRef = storageRef.child(`recipes/${recipeId}/main.jpg`);

      fileRef
        .getDownloadURL()
        .then((url) => {
          const firestore = getFirebase().firestore();
          firestore.collection("recipes").doc(recipeId).update({
            imageUrl: url,
          });
          dispatch({ type: "LINK_IMAGE_SUCCESS" });
        })
        .catch((err) => {
          // console.error(err);
          dispatch({ type: "LINK_IMAGE_ERROR", err });
        });
    }
  };
};

export const finishCreateRecipe = () => {
  return async (dispatch, getState, getFirebase) => {
    dispatch({ type: "CREATE_RECIPE_FINISH" });
  };
};

export const updateRecipeIngredients = (ingredients) => {
  return async (dispatch, getState, getFirebase) => {
    // author info
    const authorId = getState().firebase.auth.uid;

    // save recipe data in Firestore
    const firestore = getFirebase().firestore();
    ingredients.forEach(async (i) => {
      await firestore
        .collection("ingredients")
        .add({
          name: i.name,
          category: i.category ?? "other",
          authorId: authorId,
          createdAt: Date.now(),
        })
        .then((doc) => {
          // console.log("SUCCESS: Add new ingredient: ", i.name, doc.id);
          return doc.id;
        })
        .catch((err) => {
          // console.log("ERROR: Add new ingredient: ", err.message);
          return null;
        });
    });
  };
};

export const updateRecipeTags = (tags) => {
  return async (dispatch, getState, getFirebase) => {
    // author info
    const authorId = getState().firebase.auth.uid;

    // save recipe data in Firestore
    const firestore = getFirebase().firestore();
    tags.forEach(async (name) => {
      await firestore
        .collection("tags")
        .add({
          name: name,
          authorId: authorId,
          createdAt: Date.now(),
        })
        .then((doc) => {
          // console.log("SUCCESS: Add new tag: ", name, doc.id);
          return doc.id;
        })
        .catch((err) => {
          // console.log("ERROR: Add new tag: ", err.message);
          return null;
        });
    });
  };
};
