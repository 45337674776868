import { ArrowCircleUpIcon, LockClosedIcon } from "@heroicons/react/outline";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { showModal } from "../../store/actions/modalActions";
import { getIsAdmin } from "../../store/selectors/selectors";
import BookmarkButton from "../buttons/BookmarkButton";
import ConditionalDiv from "../utils/ConditionalDiv";
import ConditionalLink from "../utils/ConditionalLink";

function RecipeGridItemSimple({ id, thumbnailUrl, title, meta, showBookmark }) {
  const dispatch = useDispatch();
  const isFree = meta?.visibility.isFree ?? false;
  const isAdmin = useSelector(getIsAdmin);
  const isAccessible = isFree || isAdmin;
  return (
    <li key={id} className="relative inline-flex w-32 flex-col text-left">
      {showBookmark ? (
        <div className="absolute top-1 left-1 z-20">
          {isFree ? (
            <BookmarkButton
              recipeId={id}
              thumbnailUrl={thumbnailUrl || "./android-chrome-512x512.jpg"}
              title={title}
              categories={meta.categories || []}
            />
          ) : (
            <div
              className="rounded-full bg-gray-50 bg-opacity-70 p-1 text-gray-600 focus:outline-none"
              aria-label="Locked (paid recipe)"
            >
              <LockClosedIcon className="h-6 w-6 stroke-current" />
            </div>
          )}
        </div>
      ) : null}

      <ConditionalLink to={`/browse/recipes/${id}`} condition={isAccessible}>
        <ConditionalDiv
          className="cursor-pointer"
          condition={!isAccessible}
          onClick={() =>
            dispatch(
              showModal(
                {
                  open: true,
                  title: "Sign up for our course to access this content!",
                  message:
                    "In addition no our free content we provide resources exclusive to members. Click below to find out more.",
                },
                "locked"
              )
            )
          }
        >
          <figure className="group relative block overflow-hidden rounded-lg focus-within:ring-2 focus-within:ring-cornflower-500 focus-within:ring-offset-2">
            <div className="aspect-w-3 aspect-h-4 relative h-full w-full overflow-hidden rounded-lg bg-gray-50">
              <div className="transition-all duration-300 group-hover:blur-sm group-hover:filter">
                <img
                  src={thumbnailUrl || "./android-chrome-512x512.jpg"}
                  alt="Recipe thumbnail"
                  className="pointer-events-none h-auto w-auto object-cover "
                />
              </div>
              <div className="absolute inset-0 z-10 flex items-center justify-center text-sm font-semibold text-white opacity-0 transition-opacity duration-300 group-hover:opacity-100">
                View recipe
                <ArrowCircleUpIcon className="ml-2 h-5 w-5 rotate-45 transform text-current" />
              </div>
            </div>
            <figcaption>
              <p className="pointer-events-none mt-2 block whitespace-normal text-sm font-normal capitalize text-gray-900 line-clamp-2">
                {title}
              </p>
            </figcaption>
          </figure>
        </ConditionalDiv>
      </ConditionalLink>

      {/* <div className="group relative">
        <div className="aspect-w-1 aspect-h-1 w-full overflow-hidden rounded-md bg-gray-200">
          <img
            src={product.imageSrc}
            alt={product.imageAlt}
            className="h-full w-full object-cover object-center group-hover:opacity-75"
          />
        </div>
        <div className="mt-6">
          <p className="text-sm text-gray-500">{product.color}</p>
          <h3 className="mt-1 font-semibold text-gray-900">
            <a href={product.href}>
              <span className="absolute inset-0" />
              {product.name}
            </a>
          </h3>
          <p className="mt-1 text-gray-900">{product.price}</p>
        </div>
      </div> */}
    </li>
  );
}

export default RecipeGridItemSimple;
