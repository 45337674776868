import { PlusSmIcon } from "@heroicons/react/outline";
import { Form, Formik } from "formik";
import React from "react";
import { useDispatch } from "react-redux";
import { addToShoppingList } from "../../../store/actions/userActions";
import SelectField from "../../formFields/SelectField";
import TextField from "../../formFields/TextField";
import { formInitialValues } from "./models/formInitialValues";
import { formModel } from "./models/formModel";
import { validationSchema } from "./models/validationSchema";

const AddItemForm = () => {
  const { formId, formField } = formModel();
  const dispatch = useDispatch();

  return (
    <>
      <Formik
        initialValues={formInitialValues()}
        validationSchema={validationSchema()}
        onSubmit={(values, actions) => {
          dispatch(
            addToShoppingList(null, {
              name: values.name,
              category: values.category,
              id: values.name,
            })
          );
          actions.setTouched({});
          actions.setSubmitting(false);
        }}
      >
        {({ isSubmitting }) => (
          <Form id={formId}>
            <div className="overflow-hidden shadow sm:rounded-md">
              <div className="bg-white px-4 py-5 sm:p-6">
                <div className="grid grid-cols-6 gap-6">
                  <div className="col-span-6 sm:col-span-3">
                    <TextField {...formField.name} />
                  </div>

                  <div className="col-span-6 sm:col-span-3">
                    <SelectField {...formField.category} />
                  </div>
                </div>
              </div>
              <div className="bg-gray-50 px-4 py-3 text-right sm:px-6">
                <button
                  type="submit"
                  className="inline-flex w-full items-center justify-center space-x-2 rounded-md border border-transparent bg-cornflower-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-cornflower-700 focus:outline-none focus:ring-2 focus:ring-cornflower-500 focus:ring-offset-2 xs:w-auto xs:justify-start sm:w-auto sm:text-sm "
                  disabled={isSubmitting}
                >
                  <PlusSmIcon className="h-5 w-5" aria-hidden="true" />
                  <span>Add</span>
                </button>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default AddItemForm;
