import React from "react";
import { motion } from "framer-motion";
import styled from "styled-components";

const loaderVariants = {
  animationOne: {
    x: [-20, 20],
    y: [0, -30],
    transition: {
      x: {
        yoyo: Infinity,
        duration: 0.5,
      },
      y: {
        yoyo: Infinity,
        duration: 0.25,
        ease: "easeOut",
      },
    },
  },
};

const DivElement = styled(motion.div)`
  width: 15px;
  height: 15px;
  margin-top: 30px;
`;

const Loader = () => {
  return (
    <DivElement
      className="rounded-full bg-melon-500"
      variants={loaderVariants}
      animate="animationOne"
    />
  );
};

export default Loader;
