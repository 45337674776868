import { isEmpty } from "lodash";
import React from "react";
import { Emoji, EmojiProvider } from "react-apple-emojis";
import { useSelector } from "react-redux";
import { getPublishedMealPlans } from "../../store/selectors/selectors";
import MealPlanGrid from "../mealPlan/MealPlanGrid";
import emojiData from "react-apple-emojis/lib/data.json";

function BrowseMealPlans() {
  const mealPlans = useSelector(getPublishedMealPlans);

  return (
    <div className="relative max-w-7xl p-4 sm:px-6 lg:px-8">
      {isEmpty(mealPlans) ? (
        <div className="text-center">
          <div className="flex justify-center">
            <EmojiProvider data={emojiData}>
              <Emoji name="woman-shrugging-light-skin-tone" />
            </EmojiProvider>
          </div>
          <h3 className="mt-2 text-sm font-medium text-gray-900">No results</h3>
          <p className="mt-1 text-sm text-gray-500">
            {/* Please try adjusting your filters. */}
          </p>
        </div>
      ) : (
        <MealPlanGrid mealPlans={mealPlans} />
      )}
    </div>
  );
}

export default BrowseMealPlans;
