import { Fragment, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Dialog, Menu, Transition } from "@headlessui/react";
import {
  HomeIcon,
  MenuAlt2Icon,
  PlusIcon,
  ViewGridIcon,
  ScaleIcon,
  ShoppingBagIcon,
  XIcon,
  ClipboardListIcon,
  CalendarIcon,
} from "@heroicons/react/outline";
import { SearchIcon } from "@heroicons/react/solid";
import { Link, NavLink } from "react-router-dom";
import * as ROUTES from "../../../constants/routes";
import {
  getProfileImg,
  getIsAdmin,
  SelectAuthUID,
} from "../../../store/selectors/selectors";
import { signOut } from "../../../store/actions/authActions";
import { classNames } from "../../utils/Utils";
import FooterSocial from "../FooterSocials";

const sidebarNavigation = [
  {
    name: "Home",
    href: ROUTES.LANDING,
    icon: HomeIcon,
    isExternal: false,
    current: false,
  },
  {
    name: "Nutrition",
    href: ROUTES.NUTRITION,
    icon: ScaleIcon,
    isExternal: false,
    current: false,
  },
  {
    name: "Recipes & Meal Plans",
    href: ROUTES.BROWSE,
    icon: ViewGridIcon,
    isExternal: false,
    current: false,
  },
  {
    name: "Shop",
    href: ROUTES.SHOP,
    icon: ShoppingBagIcon,
    isExternal: false,
    current: false,
  },

  // {
  //   name: "Courses",
  //   href: ROUTES.COURSES_REBOOT,
  //   icon: AcademicCapIcon,
  //   isExternal: false,
  //   current: false,
  // },

  // {
  //   name: "Favorites",
  //   href: ROUTES.FAVORITES,
  //   icon: HeartIcon,
  //   isExternal: false,
  //   current: false,
  // },
  {
    name: "Shopping List",
    href: ROUTES.SHOPPING_LIST,
    icon: ClipboardListIcon,
    isExternal: false,
    current: false,
  },
  {
    name: "Meal Plan",
    href: ROUTES.MEAL_PLANNER,
    icon: CalendarIcon,
    isExternal: false,
    current: false,
  },
];
const userNavigation = [
  { name: "Your Profile", href: ROUTES.PROFILE },
  { name: "Settings", href: ROUTES.SETTINGS },
];

const adminNavigation = [
  { name: "New Recipe", href: ROUTES.CREATE_RECIPE + "/0" },
  { name: "New Meal Plan", href: ROUTES.CREATE_MEAL_PLAN + "/0" },
  { name: "Drafts", href: ROUTES.DRAFTS },
];

export default function SidebarNavigation({ children }) {
  const dispatch = useDispatch();
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const profileImg = useSelector(getProfileImg);
  const isAdmin = useSelector(getIsAdmin);
  const uid = useSelector(SelectAuthUID);

  return (
    <div className="flex h-screen overflow-hidden bg-white">
      {/* Narrow sidebar */}
      <div className="hidden w-28 overflow-y-auto bg-cornflower-500 md:block">
        <div className="flex w-full flex-col items-center py-6">
          <div className="flex flex-shrink-0 items-center">
            <Link to={ROUTES.LANDING}>
              <img
                className="h-16 w-auto"
                src="/logo_deliish_with_text.svg"
                alt="Deliish"
              />
            </Link>
          </div>
          <div className="mt-6 w-full flex-1 space-y-1 px-2">
            {sidebarNavigation.map((item) =>
              item.isExternal ? (
                <a
                  key={item.name}
                  href={item.href}
                  target="_blank"
                  rel="noreferrer"
                  className={classNames(
                    item.current
                      ? "bg-cornflower-100 text-cornflower-900"
                      : "text-white hover:bg-cornflower-100 hover:text-cornflower-900",
                    "group flex w-full flex-col items-center rounded-md p-3 text-xs font-medium"
                  )}
                  aria-current={item.current ? "page" : undefined}
                >
                  <item.icon
                    className="h-6 w-6 stroke-current text-current group-hover:text-cornflower-900"
                    aria-hidden="true"
                  />
                  <span className="mt-2 text-center">{item.name}</span>
                </a>
              ) : (
                <NavLink
                  key={item.name}
                  to={item.href}
                  exact={item.href === ROUTES.LANDING}
                  className={classNames(
                    item.current
                      ? "bg-cornflower-100 text-cornflower-900"
                      : "text-white hover:bg-cornflower-100 hover:text-cornflower-900",
                    "group flex w-full flex-col items-center rounded-md p-3 text-xs font-medium"
                  )}
                  activeClassName={"bg-cornflower-100 text-cornflower-900"}
                  aria-current={"page"}
                >
                  <item.icon
                    className="h-6 w-6 stroke-current text-current group-hover:text-cornflower-900"
                    aria-hidden="true"
                  />
                  <span className="mt-2 text-center">{item.name}</span>
                </NavLink>
              )
            )}
          </div>
        </div>
      </div>

      {/* Mobile menu */}
      <Transition.Root show={mobileMenuOpen} as={Fragment}>
        <Dialog
          as="div"
          static
          className="md:hidden"
          open={mobileMenuOpen}
          onClose={setMobileMenuOpen}
        >
          <div className="fixed inset-0 z-40 flex">
            <Transition.Child
              as={Fragment}
              enter="transition-opacity ease-linear duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity ease-linear duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-cornflower-700 bg-opacity-75" />
            </Transition.Child>
            <Transition.Child
              as={Fragment}
              enter="transition ease-in-out duration-300 transform"
              enterFrom="-translate-x-full"
              enterTo="translate-x-0"
              leave="transition ease-in-out duration-300 transform"
              leaveFrom="translate-x-0"
              leaveTo="-translate-x-full"
            >
              <div className="relative flex w-full max-w-xs flex-1 flex-col bg-cornflower-500 pt-5 pb-4">
                <Transition.Child
                  as={Fragment}
                  enter="ease-in-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in-out duration-300"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="absolute right-0 top-1 -mr-14 p-1">
                    <button
                      type="button"
                      className="flex h-12 w-12 items-center justify-center rounded-full focus:outline-none focus:ring-2 focus:ring-white"
                      onClick={() => setMobileMenuOpen(false)}
                    >
                      <XIcon
                        className="h-6 w-6 text-cornflower-100"
                        aria-hidden="true"
                      />
                      <span className="sr-only">Close sidebar</span>
                    </button>
                  </div>
                </Transition.Child>
                <div className="flex flex-shrink-0 items-center px-4">
                  <img
                    className="h-14 w-auto"
                    src="/logo_deliish_with_text.svg"
                    alt="Deliish"
                  />
                </div>
                <div className="mt-5 h-0 flex-1 overflow-y-auto px-2">
                  <nav className="flex h-full flex-col">
                    <div className="space-y-1">
                      {sidebarNavigation.map((item) =>
                        item.isExternal ? (
                          <a
                            key={item.name}
                            href={item.href}
                            className={classNames(
                              item.current
                                ? "bg-cornflower-100 text-cornflower-900"
                                : "text-white hover:bg-cornflower-100 hover:text-cornflower-900",
                              "group flex items-center rounded-md py-2 px-3 text-sm font-medium"
                            )}
                            aria-current={item.current ? "page" : undefined}
                          >
                            <item.icon
                              className="mr-3 h-6 w-6 stroke-current text-current group-hover:text-cornflower-900"
                              aria-hidden="true"
                            />
                            <span>{item.name}</span>
                          </a>
                        ) : (
                          <NavLink
                            key={item.name}
                            to={item.href}
                            exact={item.href === ROUTES.LANDING}
                            className={classNames(
                              item.current
                                ? "bg-cornflower-100 text-cornflower-900"
                                : "text-white hover:bg-cornflower-100 hover:text-cornflower-900",
                              "group flex items-center rounded-md py-2 px-3 text-sm font-medium"
                            )}
                            onClick={() => setMobileMenuOpen(false)}
                            activeClassName={
                              "bg-cornflower-100 text-cornflower-900"
                            }
                            aria-current={"page"}
                          >
                            <item.icon
                              className="mr-3 h-6 w-6 stroke-current text-current group-hover:text-cornflower-900"
                              aria-hidden="true"
                            />
                            <span>{item.name}</span>
                          </NavLink>
                        )
                      )}
                    </div>
                  </nav>
                </div>
              </div>
            </Transition.Child>
            <div className="w-14 flex-shrink-0" aria-hidden="true">
              {/* Dummy element to force sidebar to shrink to fit close icon */}
            </div>
          </div>
        </Dialog>
      </Transition.Root>

      {/* Content area */}
      <div className="flex flex-1 flex-col overflow-hidden">
        {/* Main content */}
        <div className="flex flex-1 items-stretch overflow-hidden">
          <main className="flex-1 overflow-y-auto">
            {/* Primary column */}
            <section
              aria-labelledby="primary"
              className="flex min-h-full min-w-0 flex-1 flex-col overflow-x-hidden lg:order-last"
            >
              <h1 id="primary" className="sr-only">
                Primary content area
              </h1>
              {children}
            </section>
            {/* <footer>
              <FooterSocial />
            </footer> */}
          </main>

          {/* Secondary column (hidden on smaller screens) */}
          {/* <aside className="hidden overflow-y-auto bg-white border-l border-gray-500 w-96 lg:block"> */}
          {/* Your content */}
          {/* </aside> */}
        </div>

        <header className="order-first w-full">
          <div className="relative z-10 flex h-16 flex-shrink-0 border-b border-gray-500 bg-white shadow-sm">
            <button
              type="button"
              className="border-r border-gray-500 px-4 text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-cornflower-500 md:hidden"
              onClick={() => setMobileMenuOpen(true)}
            >
              <span className="sr-only">Open sidebar</span>
              <MenuAlt2Icon className="h-6 w-6" aria-hidden="true" />
            </button>
            <div className="flex flex-1 justify-between px-4 sm:px-6">
              <div className="flex flex-1">
                
                <form className="flex w-full md:ml-0" action="#" method="GET">
                  <label htmlFor="search_field" className="sr-only">
                    Search all files
                  </label>
                  <div className="relative w-full text-gray-400 focus-within:text-gray-600">
                    <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center">
                      <SearchIcon
                        className="h-5 w-5 flex-shrink-0"
                        aria-hidden="true"
                      />
                    </div>
                    <input
                      name="search_field"
                      id="search_field"
                      className="h-full w-full border-transparent py-2 pl-8 pr-3 text-base text-gray-900 placeholder-gray-500 focus:border-transparent focus:placeholder-gray-400 focus:outline-none focus:ring-0"
                      placeholder="Search"
                      type="search"
                      disabled
                    />
                  </div>
                </form>
              </div>
              <div className="ml-2 flex items-center space-x-4 sm:ml-6 sm:space-x-6">
                {/* Profile dropdown */}
                {uid != null ? (
                  <Menu
                    as="div"
                    className="relative inline-block flex-shrink-0"
                  >
                    <Menu.Button className="flex rounded-full bg-white text-sm focus:outline-none focus:ring-2 focus:ring-cornflower-500 focus:ring-offset-2">
                      <span className="sr-only">Open user menu</span>
                      {profileImg !== null ? (
                        <img
                          className="h-8 w-8 rounded-full"
                          src={profileImg}
                          alt="Avatar"
                        />
                      ) : (
                        <span className="inline-block h-8 w-8 overflow-hidden rounded-full bg-gray-100">
                          <svg
                            className="h-full w-full text-gray-300"
                            fill="currentColor"
                            viewBox="0 0 24 24"
                          >
                            <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
                          </svg>
                        </span>
                      )}
                    </Menu.Button>
                    <Transition
                      enter="transition duration-100 ease-out"
                      enterFrom="transform scale-95"
                      enterTo="transform scale-100"
                      leave="transition duration-75 ease-out"
                      leaveFrom="transform scale-100 opacity-100"
                      leaveTo="transform scale-95 opacity-0"
                      className="absolute z-40"
                    >
                      <Menu.Items
                        as="ul"
                        className="absolute right-0 z-40 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
                      >
                        {userNavigation.map((item) => (
                          <Menu.Item as="li" key={item.name} className="z-40">
                            {({ active }) => (
                              <Link
                                to={item.href}
                                className={classNames(
                                  active ? "bg-gray-100" : "",
                                  "block px-4 py-2 text-sm text-gray-700"
                                )}
                              >
                                {item.name}
                              </Link>
                            )}
                          </Menu.Item>
                        ))}
                        <Menu.Item as="li" key="sign-out" className="z-40">
                          <button
                            className="z-40 block w-full px-4 py-2 text-left text-sm text-gray-700 hover:bg-gray-100"
                            onClick={() => dispatch(signOut())}
                          >
                            Sign out
                          </button>
                        </Menu.Item>
                      </Menu.Items>
                    </Transition>
                  </Menu>
                ) : (
                  <Link
                    to={ROUTES.SIGN_IN}
                    className="flex rounded-full bg-white text-sm focus:outline-none focus:ring-2 focus:ring-cornflower-500 focus:ring-offset-2"
                  >
                    <span className="sr-only">Sign in</span>
                    <span className="inline-block h-8 w-8 overflow-hidden rounded-full bg-gray-100">
                      <svg
                        className="h-full w-full text-gray-300"
                        fill="currentColor"
                        viewBox="0 0 24 24"
                      >
                        <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
                      </svg>
                    </span>
                  </Link>
                )}

                {isAdmin ? (
                  <Menu as="div" className="relative z-40 flex-shrink-0">
                    {({ open }) => (
                      <>
                        <div>
                          <Menu.Button className="flex items-center justify-center rounded-full bg-cornflower-600 p-1 text-white hover:bg-cornflower-700 focus:outline-none focus:ring-2 focus:ring-cornflower-500 focus:ring-offset-2">
                            <PlusIcon className="h-6 w-6" aria-hidden="true" />
                            <span className="sr-only">Open admin menu</span>
                          </Menu.Button>
                        </div>
                        <Transition
                          show={open}
                          as={Fragment}
                          enter="transition ease-out duration-100"
                          enterFrom="transform opacity-0 scale-95"
                          enterTo="transform opacity-100 scale-100"
                          leave="transition ease-in duration-75"
                          leaveFrom="transform opacity-100 scale-100"
                          leaveTo="transform opacity-0 scale-95"
                        >
                          <Menu.Items
                            static
                            className="absolute right-0 z-40 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
                          >
                            {adminNavigation.map((item) => (
                              <Menu.Item key={item.name}>
                                {({ active }) => (
                                  <Link
                                    to={item.href}
                                    className={classNames(
                                      active ? "bg-gray-100" : "",
                                      "z-40 block px-4 py-2 text-sm text-gray-700"
                                    )}
                                  >
                                    {item.name}
                                  </Link>
                                )}
                              </Menu.Item>
                            ))}
                          </Menu.Items>
                        </Transition>
                      </>
                    )}
                  </Menu>
                ) : null}
              </div>
            </div>
          </div>
          {/* TODO: breadcrumbs here */}
          {/* <nav className="flex" aria-label="Breadcrumb">
      <ol className="flex items-center space-x-4">
        <li>
          <div>
            <a href="#" className="text-gray-400 hover:text-gray-500">
              <HomeIconSolid className="flex-shrink-0 w-5 h-5" aria-hidden="true" />
              <span className="sr-only">Home</span>
            </a>
          </div>
        </li>
        {pages.map((page) => (
          <li key={page.name}>
            <div className="flex items-center">
              <svg
                className="flex-shrink-0 w-5 h-5 text-gray-300"
                xmlns="http://www.w3.org/2000/svg"
                fill="currentColor"
                viewBox="0 0 20 20"
                aria-hidden="true"
              >
                <path d="M5.555 17.776l8-16 .894.448-8 16-.894-.448z" />
              </svg>
              <a
                href={page.href}
                className="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700"
                aria-current={page.current ? 'page' : undefined}
              >
                {page.name}
              </a>
            </div>
          </li>
        ))}
      </ol>
    </nav> */}
        </header>
      </div>
    </div>
  );
}
