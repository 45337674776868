import React from "react";
import { connect } from "react-redux";
import { AnimatePresence } from "framer-motion";
import { default as modalTypes } from "./";
// import styled from "styled-components";

const MODAL_TYPES = {
  "sign-in": modalTypes.signInModal,
  "loading": modalTypes.loadingModal,
  "locked": modalTypes.lockedModal,
  "confirmLoadMealPlan": modalTypes.confirmLoadMealPlanModal,
};

// const BackdropElement = styled(motion.div)`
//   position: fixed;
//   top: 0;
//   left: 0;
//   width: 100%;
//   height: 100%;
//   height: -webkit-fill-available;
//   background: rgba(0, 0, 0, 0.54);
//   z-index: 2001;
// `;

// const ModalElement = styled(motion.div)`
//   max-width: 400px;
//   margin: 0 auto;
//   padding: 50px 30px 30px 30px;
//   background: white;
//   border-radius: 1.25rem;
//   text-align: center;
// `;

const RootModal = ({ onHide, modalProps, modalType }) => {
  // const backdrop = {
  //   hidden: {
  //     opacity: 0,
  //   },
  //   visible: {
  //     opacity: 1,
  //   },
  // };

  // const modal = {
  //   hidden: {
  //     y: "-100vh",
  //     opacity: 0,
  //   },
  //   visible: {
  //     y: "200px",
  //     opacity: 1,
  //     transition: { delay: 0.5 },
  //   },
  // };

  const SpecifiedModal = modalType && MODAL_TYPES[modalType];

  return (
    <AnimatePresence exitBeforeEnter>
      {SpecifiedModal && <SpecifiedModal closeModal={onHide} {...modalProps} />}
      {/* {isOpen && (
        <BackdropElement
          key="reboot-modal-backdrop"
          variants={backdrop}
          initial="hidden"
          animate="visible"
          exit="hidden"
        >
          <ModalElement key="reboot-modal" variants={modal}>
            {modalType === "loading" ? null : (
              <button
                onClick={onHide}
                className="absolute top-0 right-0 p-2 text-gray-500 hover:text-gray-900"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  width="32"
                  height="32"
                  className="mb-0 text-gray-500 stroke-current hover:text-gray-900"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
            )}

            {SpecifiedModal && (
              <SpecifiedModal closeModal={onHide} {...modalProps} />
            )}
          </ModalElement>
        </BackdropElement>
      )} */}
    </AnimatePresence>
  );
};

const mapStateToProps = (state) => ({
  ...state.modal,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(RootModal);
