import * as ActionTypes from "../../constants/ActionTypes";
import { xorWith, isEqual } from "lodash";

const initialState = {
  categories: [],
  ingredients: [],
  claims: [],
  tags: [],
  shopCategories: [],
  favorites: false,
};

const filterReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.FILTER_RESET:
      return initialState;

    case ActionTypes.FILTER_CATEGORY_SNAPSHOT:
      return {
        ...state,
        categories: [...action.categories],
      };
    case ActionTypes.FILTER_TOGGLE_CATEGORY:
      return {
        ...state,
        categories: xorWith(state.categories, [action.category], isEqual),
      };

    case ActionTypes.FILTER_SET_SINGLE_CATEGORY:
      return {
        ...state,
        categories: [action.category],
      };

    case ActionTypes.FILTER_TOGGLE_TAG:
      return {
        ...state,
        tags: xorWith(state.tags, [action.tag], isEqual),
      };

    case ActionTypes.FILTER_SET_SINGLE_TAG:
      return {
        ...state,
        tags: [action.tag],
      };

    case ActionTypes.FILTER_CLAIMS_SNAPSHOT:
      return {
        ...state,
        claims: [...action.claims],
      };

    case ActionTypes.FILTER_TOGGLE_CLAIMS:
      return {
        ...state,
        claims: xorWith(state.claims, [action.claim], isEqual),
      };

    case ActionTypes.FILTER_SHOP_TOGGLE_CATEGORY:
      return {
        ...state,
        shopCategories: xorWith(
          state.shopCategories,
          [action.category],
          isEqual
        ),
      };

    case ActionTypes.FILTER_TOGGLE_FAVORITES:
      return {
        ...state,
        favorites: !state.favorites,
      };

    default:
      return state;
  }
};

export default filterReducer;
