import React from "react";
import { ErrorMessage, Field, useField } from "formik";

function TextAreaField({
  placeholder = "",
  hint = "",
  label,
  requiredErrorMsg,
  ...props
}) {
  const [field, meta] = useField(props);
  return (
    <>
      <div className="flex justify-between">
        <label
          htmlFor={field.name}
          className="block text-sm font-medium text-gray-700"
        >
          {label}
        </label>
        <span className="text-sm text-gray-500" id={field.name + "-hint"}>
          {hint}
        </span>
      </div>
      <Field
        as="textarea"
        type="text"
        name={field.name}
        id={field.name}
        placeholder={placeholder}
        className={
          (meta.error ? "border-red-500 " : "border-gray-300 ") +
          "block w-full mt-1 rounded-md shadow-sm focus:ring-cornflower-500 focus:border-cornflower-500 sm:text-sm"
        }
      />
      <ErrorMessage
        name={field.name}
        component="div"
        className="mt-1 text-xs text-red-600"
      />
    </>
  );
}

export default TextAreaField;
