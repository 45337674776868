// MODAL
export const HIDE_MODAL = "HIDE_MODAL";
export const SHOW_MODAL = "SHOW_MODAL";

// FILTER
export const FILTER_RESET = "FILTER_RESET";
export const FILTER_CATEGORY_SNAPSHOT = "FILTER_CATEGORY_SNAPSHOT";
export const FILTER_TOGGLE_CATEGORY = "FILTER_TOGGLE_CATEGORY";
export const FILTER_SET_SINGLE_CATEGORY = "FILTER_SET_SINGLE_CATEGORY";
export const FILTER_TOGGLE_TAG = "FILTER_TOGGLE_TAG";
export const FILTER_SET_SINGLE_TAG = "FILTER_SET_SINGLE_TAG";
export const FILTER_CLAIMS_SNAPSHOT = "FILTER_CLAIMS_SNAPSHOT";
export const FILTER_SHOP_TOGGLE_CATEGORY = "FILTER_SHOP_TOGGLE_CATEGORY";
export const FILTER_TOGGLE_CLAIMS = "FILTER_TOGGLE_CLAIMS";
export const FILTER_TOGGLE_FAVORITES = "FILTER_TOGGLE_FAVORITES";

// SHOPPING LIST
export const SHOPPING_LIST_ADD = "SHOPPING_LIST_ADD";
export const SHOPPING_LIST_REMOVE = "SHOPPING_LIST_REMOVE";
export const SHOPPING_LIST_ERROR = "SHOPPING_LIST_ERROR";
