import React from "react";
import {
  ErrorMessage,
  Field,
  FieldArray,
  useField,
  useFormikContext,
} from "formik";
import { get } from "lodash";
import {
  MinusSmIcon,
  PlusSmIcon,
  ArrowSmUpIcon,
  ArrowSmDownIcon,
} from "@heroicons/react/solid";
import IngredientArrayField from "./IngredientArrayField";
import TextField from "../TextField";

function IngredientGroupArrayField({ choices, ...props }) {
  const [field, meta] = useField(props);
  const { values } = useFormikContext();

  const model = (ix) => {
    return {
      title: {
        name: `${field.name}.${ix}.title`,
        label: "Group Title",
      },
    };
  };

  return (
    <>
      <FieldArray
        key={field.name}
        name={field.name}
        render={(arrayHelpers) => (
          <div className="space-y-4">
            {get(values, field.name, []) &&
            get(values, field.name, []).length > 0 ? (
              get(values, field.name, []).map((step, index) => (
                <>
                  <div className="flex space-x-4 bg-gray-50 p-4 shadow sm:rounded-lg">
                    <div className="w-full space-y-4">
                      <div
                        className="block md:flex md:space-x-4"
                        key={field.name + index}
                      >
                        <div className="flex-1">
                          <TextField {...model(index).title} />
                        </div>

                        <div className="flex-initial space-x-2 self-end text-right">
                          <span className="relative z-0 mt-1 inline-flex rounded-md shadow-sm">
                            <button
                              type="button"
                              disabled={step?.length === 0 || index === 0}
                              onClick={() =>
                                arrayHelpers.swap(index - 1, index)
                              }
                              className={
                                (index === 0
                                  ? "cursor-default text-gray-200 "
                                  : "text-gray-500 hover:bg-gray-50  ") +
                                "relative inline-flex items-center rounded-l-md border border-gray-300 bg-white px-2 py-2 text-sm font-medium focus:z-10 focus:border-cornflower-500 focus:outline-none focus:ring-1 focus:ring-cornflower-500"
                              }
                            >
                              <span className="sr-only">Up</span>
                              <ArrowSmUpIcon
                                className="h-5 w-5"
                                aria-hidden="true"
                              />
                            </button>
                            <button
                              type="button"
                              disabled={
                                step?.length === 0 ||
                                index >= get(values, field.name, []).length - 1
                              }
                              onClick={() =>
                                arrayHelpers.swap(index, index + 1)
                              }
                              className={
                                (index >= get(values, field.name, []).length - 1
                                  ? "cursor-default text-gray-200 "
                                  : "text-gray-500 hover:bg-gray-50  ") +
                                "relative -ml-px inline-flex items-center rounded-r-md border border-gray-300 bg-white px-2 py-2 text-sm font-medium  focus:z-10 focus:border-cornflower-500 focus:outline-none focus:ring-1 focus:ring-cornflower-500"
                              }
                            >
                              <span className="sr-only">Down</span>
                              <ArrowSmDownIcon
                                className="h-5 w-5"
                                aria-hidden="true"
                              />
                            </button>
                          </span>

                          <span className="relative z-0 mt-1 inline-flex rounded-md shadow-sm">
                            <button
                              type="button"
                              onClick={() => arrayHelpers.remove(index)}
                              className="relative inline-flex items-center rounded-l-md border border-gray-300 bg-white px-2 py-2 text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-10 focus:border-cornflower-500 focus:outline-none focus:ring-1 focus:ring-cornflower-500"
                            >
                              <span className="sr-only">Remove</span>
                              <MinusSmIcon
                                className="h-5 w-5"
                                aria-hidden="true"
                              />
                            </button>
                            <button
                              type="button"
                              disabled={step?.length === 0}
                              onClick={() => arrayHelpers.push("")}
                              className="relative -ml-px inline-flex items-center rounded-r-md border border-gray-300 bg-white px-2 py-2 text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-10 focus:border-cornflower-500 focus:outline-none focus:ring-1 focus:ring-cornflower-500"
                            >
                              <span className="sr-only">Add</span>
                              <PlusSmIcon
                                className="h-5 w-5"
                                aria-hidden="true"
                              />
                            </button>
                          </span>
                        </div>
                      </div>

                      <div className="">
                        <IngredientArrayField
                          fieldName={`${field.name}.${index}.ingredients`}
                          choices={choices}
                          key={`${field.name}.${index}.ingredients`}
                        />
                      </div>
                    </div>
                  </div>
                </>
              ))
            ) : (
              <button
                type="button"
                onClick={() => arrayHelpers.push("")}
                className="inline-flex items-center rounded-md border border-transparent bg-cornflower-100 px-4 py-2 text-sm font-medium text-cornflower-700 hover:bg-cornflower-200 focus:outline-none focus:ring-2 focus:ring-cornflower-500 focus:ring-offset-2"
              >
                Add group of ingredients
              </button>
            )}
          </div>
        )}
      />
    </>
  );
}

export default IngredientGroupArrayField;
