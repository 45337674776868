import MealPlanDayItem from "./MealPlanDayItem";

export default function MealPlanDayList({ meals, dayIx }) {
  return (
    <div className="overflow-hidden bg-white shadow sm:rounded-md">
      <ul className="divide-y divide-gray-200">
        {meals.map((meal, ix) => (
          <MealPlanDayItem {...meal} key={"day-item" + dayIx + "-" + ix} />
        ))}
      </ul>
    </div>
  );
}
