import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import { useFirestoreConnect, isLoaded, isEmpty } from "react-redux-firebase";
import { useDispatch, useSelector } from "react-redux";
import {
  getIsAdmin,
  getProfile,
  getProfileImg,
  SelectAuthUID,
} from "../../store/selectors/selectors";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { updateCreatorProfile } from "../../store/actions/authActions";

const CreatorSchema = Yup.object().shape({
  fullName: Yup.string()
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .required("Required"),
  instagramUsername: Yup.string().matches(
    "^([A-Za-z0-9_](?:(?:[A-Za-z0-9_]|(?:.(?!.))){0,28}(?:[A-Za-z0-9_]))?)$",
    "Incorrect format!"
  ),
  tiktokUsername: Yup.string().min(2, "Too Short!"),
  youtubeChannelName: Yup.string().min(2, "Too Short!"),
});

const FILE_SIZE = 1000 * 1024;
const SUPPORTED_FORMATS = ["image/jpg", "image/jpeg", "image/gif", "image/png"];
const ProfileSchema = Yup.object().shape({
  displayName: Yup.string()
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .required("Required"),
  emailAddress: Yup.string().email().required("Required"),
  profileImg: Yup.mixed()
    .required("A file is required")
    .test(
      "fileSize",
      "File too large",
      (value) => value && value.size <= FILE_SIZE
    )
    .test(
      "fileFormat",
      "Unsupported Format",
      (value) => value && SUPPORTED_FORMATS.includes(value.type)
    ),
});

// Photo upload:
// https://codesandbox.io/s/formik-file-input-with-validation-pn3vb?file=/src/App.js:1147-1488
// https://codesandbox.io/s/lkkjpr5r7?from-embed=&file=/index.js:1074-1458

function Profile() {
  useFirestoreConnect(() => [
    {
      collection: "creators",
      storeAs: "creators",
    },
  ]);

  const dispatch = useDispatch();

  const profile = useSelector(getProfile);
  const isAdmin = useSelector(getIsAdmin);
  const uid = useSelector(SelectAuthUID);
  const creator = useSelector(
    ({ firestore: { data } }) => data.creators && data.creators[uid]
  );
  const profileImg = useSelector(getProfileImg);

  return (
    <motion.div
      exit={{ opacity: 0 }}
      className="py-4 min-h-screen-content bg-gray-50 sm:p-6 lg:p-8"
    >
      <div>
        <div className="md:grid md:grid-cols-3 md:gap-6">
          <div className="md:col-span-1">
            <div className="px-4 sm:px-0">
              <h3 className="text-lg font-medium leading-6 text-gray-900">
                Profile
              </h3>
              <p className="mt-1 text-sm text-gray-600">
                This information will be displayed publicly so be careful what
                you share.
              </p>
            </div>
          </div>
          <div className="mt-5 md:mt-0 md:col-span-2">
            <Formik
              initialValues={{
                displayName: profile.displayName,
                emailAddress: profile.email,
                profileImg: null,
              }}
              validationSchema={ProfileSchema}
              onSubmit={(values, { setSubmitting }) => {
                setSubmitting(false);
                // dispatch(updateCreatorProfile(values))
                //   .then(() => {
                //     setSubmitting(false);
                //   })
                //   .catch((err) => {
                //     setSubmitting(false);
                //   });
              }}
            >
              {({ isSubmitting, errors, touched }) => (
                <Form>
                  <div className="shadow sm:rounded-md sm:overflow-hidden">
                    <div className="px-4 py-5 space-y-6 bg-white sm:p-6">
                      {/* <div className="grid grid-cols-3 gap-6">
                    <div className="col-span-3 sm:col-span-2">
                      <label
                        htmlFor="company_website"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Website
                      </label>
                      <div className="flex mt-1 rounded-md shadow-sm">
                        <span className="inline-flex items-center px-3 text-sm text-gray-500 border border-r-0 border-gray-300 rounded-l-md bg-gray-50">
                          http://
                        </span>
                        <input
                          type="text"
                          name="company_website"
                          id="company_website"
                          className="flex-1 block w-full border-gray-300 rounded-none focus:ring-cornflower-500 focus:border-cornflower-500 rounded-r-md sm:text-sm"
                          placeholder="www.example.com"
                        />
                      </div>
                    </div>
                  </div> */}

                      {/* <div>
                    <label
                      htmlFor="about"
                      className="block text-sm font-medium text-gray-700"
                    >
                      About
                    </label>
                    <div className="mt-1">
                      <textarea
                        id="about"
                        name="about"
                        rows={3}
                        className="block w-full mt-1 border-gray-300 rounded-md shadow-sm focus:ring-cornflower-500 focus:border-cornflower-500 sm:text-sm"
                        placeholder="you@example.com"
                        defaultValue={""}
                      />
                    </div>
                    <p className="mt-2 text-sm text-gray-500">
                      Brief description for your profile. URLs are hyperlinked.
                    </p>
                  </div> */}

                      <div className="grid grid-cols-3 gap-6">
                        <div className="col-span-3 sm:col-span-2">
                          <label
                            htmlFor="displayName"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Display Name
                          </label>
                          <Field
                            disabled
                            type="text"
                            name="displayName"
                            id="displayName"
                            autoComplete="name"
                            className="block w-full mt-1 border-gray-300 rounded-md shadow-sm focus:ring-cornflower-500 focus:border-cornflower-500 sm:text-sm"
                          />
                          <ErrorMessage name="displayName" component="div" />
                        </div>
                      </div>

                      <div className="grid grid-cols-3 gap-6">
                        <div className="col-span-3 sm:col-span-2">
                          <label
                            htmlFor="emailAddress"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Email address
                          </label>
                          <Field
                            disabled
                            type="email"
                            name="emailAddress"
                            id="emailAddress"
                            autoComplete="email"
                            className="block w-full mt-1 border-gray-300 rounded-md shadow-sm focus:ring-cornflower-500 focus:border-cornflower-500 sm:text-sm"
                          />
                          <ErrorMessage name="emailAddress" component="div" />
                        </div>
                      </div>

                      <div>
                        <label className="block text-sm font-medium text-gray-700">
                          Photo
                        </label>
                        <div className="flex items-center mt-1">
                          {profileImg === null ? (
                            <span className="inline-block w-12 h-12 overflow-hidden bg-gray-100 rounded-full">
                              <svg
                                className="w-full h-full text-gray-300"
                                fill="currentColor"
                                viewBox="0 0 24 24"
                              >
                                <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
                              </svg>
                            </span>
                          ) : (
                            <img
                              className="w-12 h-12 rounded-full"
                              src={profileImg}
                              alt="Profile Avatar"
                            />
                          )}

                          <button
                            type="button"
                            disabled
                            className="px-3 py-2 ml-5 text-sm font-medium leading-4 text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm cursor-default focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cornflower-500" //hover:bg-gray-50
                          >
                            Change
                          </button>
                        </div>
                      </div>

                      {/* <div>
                        <label className="block text-sm font-medium text-gray-700">
                          Cover photo
                        </label>
                        <div className="flex justify-center px-6 pt-5 pb-6 mt-1 border-2 border-gray-300 border-dashed rounded-md">
                          <div className="space-y-1 text-center">
                            <svg
                              className="w-12 h-12 mx-auto text-gray-400"
                              stroke="currentColor"
                              fill="none"
                              viewBox="0 0 48 48"
                              aria-hidden="true"
                            >
                              <path
                                d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                                strokeWidth={2}
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </svg>
                            <div className="flex text-sm text-gray-600">
                              <label
                                htmlFor="profileImg"
                                className="relative font-medium bg-white rounded-md cursor-pointer text-cornflower-600 hover:text-cornflower-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-cornflower-500"
                              >
                                <span>Upload a file</span>
                                <Field
                                  id="profileImg"
                                  name="profileImg"
                                  type="file"
                                  className="sr-only"
                                />
                              </label>
                              <p className="pl-1">or drag and drop</p>
                            </div>
                            <p className="text-xs text-gray-500">
                              PNG, JPG, GIF up to 10MB
                            </p>
                          </div>
                        </div>
                      </div> */}
                    </div>
                    <div className="px-4 py-3 text-right bg-gray-50 sm:px-6">
                      <button
                        type="submit"
                        disabled
                        className="inline-flex justify-center px-4 py-2 text-sm font-medium text-white border border-transparent rounded-md shadow-sm cursor-default bg-cornflower-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cornflower-500" //hover:bg-cornflower-700 
                      >
                        Save
                      </button>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>

      {isAdmin && isLoaded(creator) ? (
        <>
          <div className="hidden sm:block" aria-hidden="true">
            <div className="py-5">
              <div className="border-t border-gray-200" />
            </div>
          </div>
          <div className="mt-10 sm:mt-0">
            <div className="md:grid md:grid-cols-3 md:gap-6">
              <div className="md:col-span-1">
                <div className="px-4 sm:px-0">
                  <h3 className="text-lg font-medium leading-6 text-gray-900">
                    Creator profile
                  </h3>
                  <p className="mt-1 text-sm text-gray-600">
                    This information will be displayed publicly so be careful
                    what you share.
                  </p>
                </div>
              </div>
              <div className="mt-5 md:mt-0 md:col-span-2">
                <Formik
                  initialValues={{
                    fullName:
                      creator?.fullName !== null ? creator.fullName : "",
                    instagramUsername:
                      creator?.instagramUsername !== null
                        ? creator.instagramUsername
                        : "",
                    tiktokUsername:
                      creator?.tiktokUsername !== null
                        ? creator.tiktokUsername
                        : "",
                    youtubeChannelName:
                      creator?.youtubeChannelName !== null
                        ? creator.youtubeChannelName
                        : "",
                  }}
                  validationSchema={CreatorSchema}
                  onSubmit={(values, { setSubmitting }) => {
                    setSubmitting(true);
                    dispatch(updateCreatorProfile(values))
                      .then(() => {
                        setSubmitting(false);
                      })
                      .catch((err) => {
                        setSubmitting(false);
                      });
                  }}
                >
                  {({ isSubmitting, errors, touched }) => (
                    <Form>
                      <div className="overflow-hidden shadow sm:rounded-md">
                        <div className="px-4 py-5 space-y-6 bg-white sm:p-6">
                          <div className="grid grid-cols-3 gap-6">
                            <div className="col-span-3 sm:col-span-2">
                              <label
                                htmlFor="fullName"
                                className="block text-sm font-medium text-gray-700"
                              >
                                Full Name
                              </label>
                              <Field
                                type="text"
                                name="fullName"
                                id="fullName"
                                autoComplete="name"
                                className="block w-full mt-1 border-gray-300 rounded-md shadow-sm focus:ring-cornflower-500 focus:border-cornflower-500 sm:text-sm"
                              />
                              <ErrorMessage name="fullName" component="div" />
                            </div>
                          </div>

                          {/* <div>
                            <label className="block text-sm font-medium text-gray-700">
                              Photo
                            </label>
                            <div className="flex items-center mt-1">
                              <span className="inline-block w-12 h-12 overflow-hidden bg-gray-100 rounded-full">
                                <svg
                                  className="w-full h-full text-gray-300"
                                  fill="currentColor"
                                  viewBox="0 0 24 24"
                                >
                                  <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
                                </svg>
                              </span>
                              <button
                                type="button"
                                className="px-3 py-2 ml-5 text-sm font-medium leading-4 text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cornflower-500"
                              >
                                Change
                              </button>
                            </div>
                          </div> */}

                          <div className="grid grid-cols-3 gap-6">
                            <div className="col-span-3 sm:col-span-2">
                              <label
                                htmlFor="instagramUsername"
                                className="block text-sm font-medium text-gray-700"
                              >
                                Instagram username
                              </label>
                              <div className="flex mt-1 rounded-md shadow-sm">
                                <span className="inline-flex items-center px-3 text-sm text-gray-500 border border-r-0 border-gray-300 rounded-l-md bg-gray-50">
                                  @
                                </span>
                                <Field
                                  type="text"
                                  name="instagramUsername"
                                  id="instagramUsername"
                                  className="flex-1 block w-full border-gray-300 rounded-none focus:ring-cornflower-500 focus:border-cornflower-500 rounded-r-md sm:text-sm"
                                />
                              </div>
                              <ErrorMessage
                                name="instagramUsername"
                                component="div"
                                className="block mt-1 text-sm font-medium text-red-700"
                              />
                            </div>
                          </div>

                          <div className="grid grid-cols-3 gap-6">
                            <div className="col-span-3 sm:col-span-2">
                              <label
                                htmlFor="tiktokUsername"
                                className="block text-sm font-medium text-gray-700"
                              >
                                TikTok username
                              </label>
                              <div className="flex mt-1 rounded-md shadow-sm">
                                <span className="inline-flex items-center px-3 text-sm text-gray-500 border border-r-0 border-gray-300 rounded-l-md bg-gray-50">
                                  @
                                </span>
                                <Field
                                  type="text"
                                  name="tiktokUsername"
                                  id="tiktokUsername"
                                  className="flex-1 block w-full border-gray-300 rounded-none focus:ring-cornflower-500 focus:border-cornflower-500 rounded-r-md sm:text-sm"
                                />
                              </div>
                              <ErrorMessage
                                name="tiktokUsername"
                                component="div"
                                className="block mt-1 text-sm font-medium text-red-700"
                              />
                            </div>
                          </div>

                          <div className="grid grid-cols-3 gap-6">
                            <div className="col-span-3 sm:col-span-2">
                              <label
                                htmlFor="youtubeChannelName"
                                className="block text-sm font-medium text-gray-700"
                              >
                                YouTube channel Id
                              </label>
                              <Field
                                type="text"
                                name="youtubeChannelName"
                                id="youtubeChannelName"
                                className="block w-full mt-1 border-gray-300 rounded-md shadow-sm focus:ring-cornflower-500 focus:border-cornflower-500 sm:text-sm"
                              />
                              <ErrorMessage
                                name="youtubeChannelName"
                                component="div"
                                className="block mt-1 text-sm font-medium text-red-700"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="px-4 py-3 text-right bg-gray-50 sm:px-6">
                          <button
                            type="submit"
                            disabled={isSubmitting}
                            className="inline-flex justify-center px-4 py-2 text-sm font-medium text-white border border-transparent rounded-md shadow-sm bg-cornflower-600 hover:bg-cornflower-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cornflower-500"
                          >
                            Save
                          </button>
                        </div>
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </>
      ) : null}

      {/* <div className="mt-10 sm:mt-0">
        <div className="md:grid md:grid-cols-3 md:gap-6">
          <div className="md:col-span-1">
            <div className="px-4 sm:px-0">
              <h3 className="text-lg font-medium leading-6 text-gray-900">
                Personal Information
              </h3>
              <p className="mt-1 text-sm text-gray-600">
                Use a permanent address where you can receive mail.
              </p>
            </div>
          </div>
          <div className="mt-5 md:mt-0 md:col-span-2">
            <form action="#" method="POST">
              <div className="overflow-hidden shadow sm:rounded-md">
                <div className="px-4 py-5 bg-white sm:p-6">
                  <div className="grid grid-cols-6 gap-6">
                    <div className="col-span-6 sm:col-span-3">
                      <label
                        htmlFor="first_name"
                        className="block text-sm font-medium text-gray-700"
                      >
                        First name
                      </label>
                      <input
                        type="text"
                        name="first_name"
                        id="first_name"
                        autoComplete="given-name"
                        className="block w-full mt-1 border-gray-300 rounded-md shadow-sm focus:ring-cornflower-500 focus:border-cornflower-500 sm:text-sm"
                      />
                    </div>

                    <div className="col-span-6 sm:col-span-3">
                      <label
                        htmlFor="last_name"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Last name
                      </label>
                      <input
                        type="text"
                        name="last_name"
                        id="last_name"
                        autoComplete="family-name"
                        className="block w-full mt-1 border-gray-300 rounded-md shadow-sm focus:ring-cornflower-500 focus:border-cornflower-500 sm:text-sm"
                      />
                    </div>

                    <div className="col-span-6 sm:col-span-4">
                      <label
                        htmlFor="emailAddress"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Email address
                      </label>
                      <input
                        type="text"
                        name="emailAddress"
                        id="emailAddress"
                        autoComplete="email"
                        className="block w-full mt-1 border-gray-300 rounded-md shadow-sm focus:ring-cornflower-500 focus:border-cornflower-500 sm:text-sm"
                      />
                    </div>

                    <div className="col-span-6 sm:col-span-3">
                      <label
                        htmlFor="country"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Country / Region
                      </label>
                      <select
                        id="country"
                        name="country"
                        autoComplete="country"
                        className="block w-full px-3 py-2 mt-1 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-cornflower-500 focus:border-cornflower-500 sm:text-sm"
                      >
                        <option>United States</option>
                        <option>Canada</option>
                        <option>Mexico</option>
                      </select>
                    </div>

                    <div className="col-span-6">
                      <label
                        htmlFor="street_address"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Street address
                      </label>
                      <input
                        type="text"
                        name="street_address"
                        id="street_address"
                        autoComplete="street-address"
                        className="block w-full mt-1 border-gray-300 rounded-md shadow-sm focus:ring-cornflower-500 focus:border-cornflower-500 sm:text-sm"
                      />
                    </div>

                    <div className="col-span-6 sm:col-span-6 lg:col-span-2">
                      <label
                        htmlFor="city"
                        className="block text-sm font-medium text-gray-700"
                      >
                        City
                      </label>
                      <input
                        type="text"
                        name="city"
                        id="city"
                        className="block w-full mt-1 border-gray-300 rounded-md shadow-sm focus:ring-cornflower-500 focus:border-cornflower-500 sm:text-sm"
                      />
                    </div>

                    <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                      <label
                        htmlFor="state"
                        className="block text-sm font-medium text-gray-700"
                      >
                        State / Province
                      </label>
                      <input
                        type="text"
                        name="state"
                        id="state"
                        className="block w-full mt-1 border-gray-300 rounded-md shadow-sm focus:ring-cornflower-500 focus:border-cornflower-500 sm:text-sm"
                      />
                    </div>

                    <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                      <label
                        htmlFor="postal_code"
                        className="block text-sm font-medium text-gray-700"
                      >
                        ZIP / Postal
                      </label>
                      <input
                        type="text"
                        name="postal_code"
                        id="postal_code"
                        autoComplete="postal-code"
                        className="block w-full mt-1 border-gray-300 rounded-md shadow-sm focus:ring-cornflower-500 focus:border-cornflower-500 sm:text-sm"
                      />
                    </div>
                  </div>
                </div>
                <div className="px-4 py-3 text-right bg-gray-50 sm:px-6">
                  <button
                    type="submit"
                    className="inline-flex justify-center px-4 py-2 text-sm font-medium text-white border border-transparent rounded-md shadow-sm bg-cornflower-600 hover:bg-cornflower-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cornflower-500"
                  >
                    Save
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div> */}

      {/* <div className="hidden sm:block" aria-hidden="true">
        <div className="py-5">
          <div className="border-t border-gray-200" />
        </div>
      </div>

      <div className="mt-10 sm:mt-0">
        <div className="md:grid md:grid-cols-3 md:gap-6">
          <div className="md:col-span-1">
            <div className="px-4 sm:px-0">
              <h3 className="text-lg font-medium leading-6 text-gray-900">
                Notifications
              </h3>
              <p className="mt-1 text-sm text-gray-600">
                Decide which communications you'd like to receive and how.
              </p>
            </div>
          </div>
          <div className="mt-5 md:mt-0 md:col-span-2">
            <form action="#" method="POST">
              <div className="overflow-hidden shadow sm:rounded-md">
                <div className="px-4 py-5 space-y-6 bg-white sm:p-6">
                  <fieldset>
                    <legend className="text-base font-medium text-gray-900">
                      By Email
                    </legend>
                    <div className="mt-4 space-y-4">
                      <div className="flex items-start">
                        <div className="flex items-center h-5">
                          <input
                            id="comments"
                            name="comments"
                            type="checkbox"
                            className="w-4 h-4 border-gray-300 rounded text-cornflower-600 focus:ring-cornflower-500"
                          />
                        </div>
                        <div className="ml-3 text-sm">
                          <label
                            htmlFor="comments"
                            className="font-medium text-gray-700"
                          >
                            Comments
                          </label>
                          <p className="text-gray-500">
                            Get notified when someones posts a comment on a
                            posting.
                          </p>
                        </div>
                      </div>
                      <div className="flex items-start">
                        <div className="flex items-center h-5">
                          <input
                            id="candidates"
                            name="candidates"
                            type="checkbox"
                            className="w-4 h-4 border-gray-300 rounded text-cornflower-600 focus:ring-cornflower-500"
                          />
                        </div>
                        <div className="ml-3 text-sm">
                          <label
                            htmlFor="candidates"
                            className="font-medium text-gray-700"
                          >
                            Candidates
                          </label>
                          <p className="text-gray-500">
                            Get notified when a candidate applies for a job.
                          </p>
                        </div>
                      </div>
                      <div className="flex items-start">
                        <div className="flex items-center h-5">
                          <input
                            id="offers"
                            name="offers"
                            type="checkbox"
                            className="w-4 h-4 border-gray-300 rounded text-cornflower-600 focus:ring-cornflower-500"
                          />
                        </div>
                        <div className="ml-3 text-sm">
                          <label
                            htmlFor="offers"
                            className="font-medium text-gray-700"
                          >
                            Offers
                          </label>
                          <p className="text-gray-500">
                            Get notified when a candidate accepts or rejects an
                            offer.
                          </p>
                        </div>
                      </div>
                    </div>
                  </fieldset>
                  <fieldset>
                    <div>
                      <legend className="text-base font-medium text-gray-900">
                        Push Notifications
                      </legend>
                      <p className="text-sm text-gray-500">
                        These are delivered via SMS to your mobile phone.
                      </p>
                    </div>
                    <div className="mt-4 space-y-4">
                      <div className="flex items-center">
                        <input
                          id="push_everything"
                          name="push_notifications"
                          type="radio"
                          className="w-4 h-4 border-gray-300 text-cornflower-600 focus:ring-cornflower-500"
                        />
                        <label
                          htmlFor="push_everything"
                          className="block ml-3 text-sm font-medium text-gray-700"
                        >
                          Everything
                        </label>
                      </div>
                      <div className="flex items-center">
                        <input
                          id="push_email"
                          name="push_notifications"
                          type="radio"
                          className="w-4 h-4 border-gray-300 text-cornflower-600 focus:ring-cornflower-500"
                        />
                        <label
                          htmlFor="push_email"
                          className="block ml-3 text-sm font-medium text-gray-700"
                        >
                          Same as email
                        </label>
                      </div>
                      <div className="flex items-center">
                        <input
                          id="push_nothing"
                          name="push_notifications"
                          type="radio"
                          className="w-4 h-4 border-gray-300 text-cornflower-600 focus:ring-cornflower-500"
                        />
                        <label
                          htmlFor="push_nothing"
                          className="block ml-3 text-sm font-medium text-gray-700"
                        >
                          No push notifications
                        </label>
                      </div>
                    </div>
                  </fieldset>
                </div>
                <div className="px-4 py-3 text-right bg-gray-50 sm:px-6">
                  <button
                    type="submit"
                    className="inline-flex justify-center px-4 py-2 text-sm font-medium text-white border border-transparent rounded-md shadow-sm bg-cornflower-600 hover:bg-cornflower-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cornflower-500"
                  >
                    Save
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div> */}
    </motion.div>
  );
}

export default Profile;
