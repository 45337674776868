export const signOut = () => {
    return (dispatch, getState, getFirebase) => {
        const firebase = getFirebase();
        firebase.auth().signOut()
        firebase.logout()
            .then(() => {
                //console.log("Signed out");
            })
    }
}

export const updateUserProfile = (data) => {
    return async (dispatch, getState, getFirebase) => {
        const firestore = getFirebase().firestore();
        const uid = getState().firebase.auth.uid;
        await firestore.collection('users')
            .doc(uid)
            .set(data, { merge: true })
            .then((doc) => {
                dispatch({ type: 'UPDATE_USER_PROFILE_SUCCESS' });
                return doc.id;
            }).catch(err => {
                dispatch({ type: 'UPDATE_USER_PROFILE_ERRORS', err });
                return null;
            });
    }
}

export const updateCreatorProfile = (data) => {
    return async (dispatch, getState, getFirebase) => {
        const firestore = getFirebase().firestore();
        const uid = getState().firebase.auth.uid;
        await firestore.collection('creators')
            .doc(uid)
            .set(data, { merge: true })
            .then((doc) => {
                // TODO: success notification
                dispatch({ type: 'UPDATE_CREATOR_PROFILE_SUCCESS' });
                return doc.id;
            }).catch(err => {
                dispatch({ type: 'UPDATE_CREATOR_PROFILE_ERRORS', err });
                return null;
            });
    }
}