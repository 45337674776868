export const theme = {
    primaryDark: '#0D0C1D',
    primaryHover: '#343078',

    primaryLight: '#0B5864',
    secondaryLight: '#f87171',
    tertiaryLight: '#fee2e2',

    mobile: '540px',
}

// --blue: #377dff; --indigo: #2d1582; --purple: #6f42c1; --pink: #e83e8c; --red: #de4437; --orange: #fd7e14; --yellow: #f5ca99; --green: #28a745; --teal: #00c9a7; --cyan: #00dffc; --white: #fff; --gray: #8c98a4; --gray-dark: #71869d; --primary: #0B5864; --secondary: rgba(248, 113, 113, 1); --success: #00c9a7; --info: #00dffc; --warning: #f5ca99; --danger: #de4437; --light: #f7faff; --dark: #1e2022; --breakpoint-xs: 0; --breakpoint-sm: 576px; --breakpoint-md: 768px; --breakpoint-lg: 992px; --breakpoint-xl: 1200px; --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"; --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace; }