import React from "react";
import {
  ErrorMessage,
  Field,
  FieldArray,
  useField,
  useFormikContext,
} from "formik";
import { get } from "lodash";
import {
  MinusSmIcon,
  PlusSmIcon,
  ArrowSmUpIcon,
  ArrowSmDownIcon,
  ExclamationCircleIcon,
} from "@heroicons/react/solid";

function ArrayField({ requiredErrorMsg, ...props }) {
  const [field, meta, helpers] = useField(props);
  const { values } = useFormikContext();

  return (
    <>
      <FieldArray
        key={field.name}
        name={field.name}
        render={(arrayHelpers) => (
          <div>
            {get(values, field.name, []) &&
            get(values, field.name, []).length > 0 ? (
              get(values, field.name, []).map((tag, index) => (
                <>
                  <div key={index} className="flex space-x-4">
                    <div className="relative w-full mt-1 rounded-md shadow-sm">
                      <Field
                        type="text"
                        name={`${field.name}.${index}`}
                        id={`${field.name}.${index}`}
                        key={`${field.name}.${index}`}
                        placeholder="Name your tag"
                        className={
                          (meta.error
                            ? "border-red-300 text-red-900 placeholder-red-300 focus:outline-none focus:ring-red-500 focus:border-red-500 pr-10  "
                            : "border-gray-300 focus:ring-cornflower-500 focus:border-cornflower-500") +
                          "block w-full  sm:text-sm rounded-md"
                        }
                      />
                      {meta.error && (
                        <div className="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none">
                          <ExclamationCircleIcon
                            className="w-5 h-5 text-red-500"
                            aria-hidden="true"
                          />
                        </div>
                      )}
                    </div>

                    <span className="relative z-0 inline-flex mt-1 rounded-md shadow-sm">
                      <button
                        type="button"
                        disabled={tag?.length === 0 || index === 0}
                        onClick={() => arrayHelpers.swap(index - 1, index)}
                        className={
                          (index === 0
                            ? "text-gray-200 cursor-default "
                            : "text-gray-500 hover:bg-gray-50  ") +
                          "relative inline-flex items-center px-2 py-2 text-sm font-medium bg-white border border-gray-300 rounded-l-md focus:z-10 focus:outline-none focus:ring-1 focus:ring-cornflower-500 focus:border-cornflower-500"
                        }
                      >
                        <span className="sr-only">Up</span>
                        <ArrowSmUpIcon className="w-5 h-5" aria-hidden="true" />
                      </button>
                      <button
                        type="button"
                        disabled={
                          tag?.length === 0 ||
                          index >= get(values, field.name, []).length - 1
                        }
                        onClick={() => arrayHelpers.swap(index, index + 1)}
                        className={
                          (index >= get(values, field.name, []).length - 1
                            ? "text-gray-200 cursor-default "
                            : "text-gray-500 hover:bg-gray-50  ") +
                          "relative inline-flex items-center px-2 py-2 -ml-px text-sm font-medium bg-white border border-gray-300 rounded-r-md  focus:z-10 focus:outline-none focus:ring-1 focus:ring-cornflower-500 focus:border-cornflower-500"
                        }
                      >
                        <span className="sr-only">Down</span>
                        <ArrowSmDownIcon
                          className="w-5 h-5"
                          aria-hidden="true"
                        />
                      </button>
                    </span>

                    <span className="relative z-0 inline-flex mt-1 rounded-md shadow-sm">
                      <button
                        type="button"
                        onClick={() => arrayHelpers.remove(index)}
                        className="relative inline-flex items-center px-2 py-2 text-sm font-medium text-gray-500 bg-white border border-gray-300 rounded-l-md hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-cornflower-500 focus:border-cornflower-500"
                      >
                        <span className="sr-only">Remove</span>
                        <MinusSmIcon className="w-5 h-5" aria-hidden="true" />
                      </button>
                      <button
                        type="button"
                        disabled={tag?.length === 0}
                        onClick={() => arrayHelpers.insert(index, "")}
                        className="relative inline-flex items-center px-2 py-2 -ml-px text-sm font-medium text-gray-500 bg-white border border-gray-300 rounded-r-md hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-cornflower-500 focus:border-cornflower-500"
                      >
                        <span className="sr-only">Add</span>
                        <PlusSmIcon className="w-5 h-5" aria-hidden="true" />
                      </button>
                    </span>
                  </div>
                  <ErrorMessage
                    name={`${field.name}.${index}`}
                    component="div"
                    className="mt-1 text-xs text-red-600"
                  />
                </>
              ))
            ) : (
              <button
                type="button"
                onClick={() => arrayHelpers.push("")}
                className="inline-flex items-center px-4 py-2 text-sm font-medium border border-transparent rounded-md text-cornflower-700 bg-cornflower-100 hover:bg-cornflower-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cornflower-500"
              >
                Add a tag
              </button>
            )}
          </div>
        )}
      />
    </>
  );
}

export default ArrayField;
