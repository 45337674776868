import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInstagram, faYoutube } from "@fortawesome/free-brands-svg-icons";

function RecipeCreatorNotes({
  profilePictureUrlSmall,
  profilePictureUrl,
  fullName,
  instagramUsername,
  youtubeChannelName,
  description,
}) {
  return (
    <section className="rounded-lg shadow-sm bg-gray-50">
      <div className="mx-auto md:px-6 lg:px-8">
        <div className="p-4 sm:p-6">
          <blockquote className="md:flex-grow md:flex md:flex-col">
            {description?.length ? (
              <div className="relative mb-8 text-base leading-7 text-justify text-gray-600 md:flex-grow">
                <svg
                  className="absolute top-0 left-0 w-8 h-8 transform -translate-x-3 -translate-y-2 text-cornflower-500 text-opacity-30"
                  fill="currentColor"
                  viewBox="0 0 32 32"
                  aria-hidden="true"
                >
                  <path d="M9.352 4C4.456 7.456 1 13.12 1 19.36c0 5.088 3.072 8.064 6.624 8.064 3.36 0 5.856-2.688 5.856-5.856 0-3.168-2.208-5.472-5.088-5.472-.576 0-1.344.096-1.536.192.48-3.264 3.552-7.104 6.624-9.024L9.352 4zm16.512 0c-4.8 3.456-8.256 9.12-8.256 15.36 0 5.088 3.072 8.064 6.624 8.064 3.264 0 5.856-2.688 5.856-5.856 0-3.168-2.304-5.472-5.184-5.472-.576 0-1.248.096-1.44.192.48-3.264 3.456-7.104 6.528-9.024L25.864 4z" />
                </svg>
                <p className="relative">{description}</p>
              </div>
            ) : null}
            <footer className="">
              <div className="flex items-center">
                <div className="inline-flex flex-shrink-0 border-2 border-white rounded-full">
                  <img
                    className="text-xs rounded-full h-14 w-14"
                    src={profilePictureUrlSmall ?? profilePictureUrl}
                    alt={fullName}
                  />
                </div>
                <div className="ml-4">
                  <div className="text-base font-medium text-cornflower-500">
                    {fullName}
                  </div>
                  <div className="flex mt-1 space-x-2 text-gray-900">
                    <a
                      key={youtubeChannelName}
                      href={`https://www.youtube.com/channel/${youtubeChannelName}`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <FontAwesomeIcon
                        icon={faYoutube}
                        className="h-6 mb-0 w-6-i"
                        aria-hidden="true"
                      />
                    </a>
                    <a
                      key={instagramUsername}
                      href={`https://www.instagram.com/${instagramUsername}`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <FontAwesomeIcon
                        icon={faInstagram}
                        className="h-6 mb-0 w-6-i"
                        aria-hidden="true"
                      />
                    </a>
                  </div>
                </div>
              </div>
            </footer>
          </blockquote>
        </div>
      </div>
    </section>
  );
}

export default RecipeCreatorNotes;

// {isLoaded(creator) && !isEmpty(creator) ? (
//     <div className="py-2">
//       <figure className="justify-center p-5 flex-column align-items-center bg-gray-50 rounded-3xl">
//         <img
//           className="w-32 h-32 mx-auto rounded-full "
//           src={
//             creator?.profilePictureUrlSmall ??
//             creator?.profilePictureUrl
//           }
//           alt="Creator profile"
//           width="75"
//           height="75"
//         />
//         <div className="text-center">
//           {recipe?.meta.notes.description &&
//           recipe?.meta.notes.description.length ? (
//             <div>
//               <div className="position-relative">
//                 <Quote className="p-0 py-3 pl-5 m-0 leading-normal border-0 text-dark">
//                   {recipe?.meta.notes.description}
//                 </Quote>
//                 <figure className="top-0 left-0 position-absolute">
//                   <svg
//                     xmlns="http://www.w3.org/2000/svg"
//                     xmlnsXlink="http://www.w3.org/1999/xlink"
//                     x="0px"
//                     y="0px"
//                     width="80px"
//                     height="80px"
//                     viewBox="0 0 8 8"
//                     style={{
//                       enableBackground: "new 0 0 8 8",
//                     }}
//                     xmlSpace="preserve"
//                   >
//                     <path
//                       fill="#fee2e2"
//                       opacity=".3"
//                       d="M3,1.3C2,1.7,1.2,2.7,1.2,3.6c0,0.2,0,0.4,0.1,0.5c0.2-0.2,0.5-0.3,0.9-0.3c0.8,0,1.5,0.6,1.5,1.5c0,0.9-0.7,1.5-1.5,1.5
// C1.4,6.9,1,6.6,0.7,6.1C0.4,5.6,0.3,4.9,0.3,4.5c0-1.6,0.8-2.9,2.5-3.7L3,1.3z M7.1,1.3c-1,0.4-1.8,1.4-1.8,2.3
// c0,0.2,0,0.4,0.1,0.5c0.2-0.2,0.5-0.3,0.9-0.3c0.8,0,1.5,0.6,1.5,1.5c0,0.9-0.7,1.5-1.5,1.5c-0.7,0-1.1-0.3-1.4-0.8
// C4.4,5.6,4.4,4.9,4.4,4.5c0-1.6,0.8-2.9,2.5-3.7L7.1,1.3z"
//                     />
//                   </svg>
//                 </figure>
//               </div>
//             </div>
//           ) : null}
//           <figcaption className="font-medium">
//             <div
//               className={`text-red-400 ${
//                 recipe?.meta.notes.description.length
//                   ? " text-right"
//                   : " text-center"
//               }`}
//             >
//               <span className="">{creator?.fullName}</span>
//             </div>
//             <Social
//               className={`align-self-center pt-2 ${
//                 recipe?.meta.notes.description.length
//                   ? " ml-auto"
//                   : " mx-auto"
//               }`}
//             >
//               {creator?.youtubeChannelName ? (
//                 <a
//                   className="text-dark"
//                   href={`https://www.youtube.com/channel/${creator?.youtubeChannelName}`}
//                   target="_blank"
//                   rel="noreferrer"
//                 >
//                   <i className="fab fa-youtube fa-lg"></i>
//                 </a>
//               ) : null}
//               {creator?.instagramUsername ? (
//                 <a
//                   className="ml-3 text-dark"
//                   href={`https://www.instagram.com/${creator?.instagramUsername}`}
//                   target="_blank"
//                   rel="noreferrer"
//                 >
//                   <i className="fab fa-instagram fa-lg"></i>
//                 </a>
//               ) : null}
//             </Social>
//           </figcaption>
//         </div>
//       </figure>
//     </div>
//   ) : null}
