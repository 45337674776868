import { motion } from "framer-motion";
import { isEmpty } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { isLoaded, useFirestoreConnect } from "react-redux-firebase";
import { Link, Redirect, useParams } from "react-router-dom";
import {
  getIsAdmin,
  getIsSubscriber,
  getProfile,
  getPublishedMealPlans,
} from "../../store/selectors/selectors";
import Loader from "../loaders/Loader";
import * as ROUTES from "../../constants/routes";
import RecipeCreatorNotes from "../recipes/RecipeCreatorNotes";
import CreatorYoutubeBanner from "../creator/CreatorYoutubeBanner";
import { PencilIcon, UploadIcon } from "@heroicons/react/outline";
import MealPlanDayList from "./MealPlanDayList";
import { showModal } from "../../store/actions/modalActions";

export default function MealPlanDetail() {
  let { mealPlanId } = useParams();
  useFirestoreConnect(() => [
    {
      collection: "creators",
      storeAs: "creators",
    },
    {
      collection: "recipes",
      orderBy: [["createdAt", "desc"]],
      storeAs: "recipes",
    },
    {
      collection: "mealPlans",
      orderBy: [["createdAt", "desc"]],
      storeAs: "mealPlans",
    },
  ]);

  const dispatch = useDispatch();

  const mealPlans = useSelector(getPublishedMealPlans);
  const mealPlan = useSelector(
    ({ firestore: { data } }) => data.mealPlans && data.mealPlans[mealPlanId]
  );
  const creator = useSelector(
    ({ firestore: { data } }) =>
      data.creators && data.creators[mealPlan?.authorId]
  );
  const profile = useSelector(getProfile);
  const isAdmin = useSelector(getIsAdmin);
  const isSubscriber = useSelector(getIsSubscriber);

  const handleStartMealPlan = () => {
    return profile === null
      ? dispatch(
          showModal(
            {
              open: true,
              title: "Sign up for free to load meal plans!",
              message:
                "We cannot save your meal plans if you are not signed in to your account. If you have not already, you can create a new account for free in just a few seconds.",
            },
            "sign-in"
          )
        )
      : dispatch(
          showModal(
            {
              open: true,
              title: "Start meal plan",
              message:
                "Are you sure you want to load this meal plan? All of your currently planned meals will be removed.",
              mealPlanId,
            },
            "confirmLoadMealPlan"
          )
        );
  };

  if (!isLoaded(mealPlan) && isEmpty(mealPlans)) {
    return (
      <motion.div
        exit="undefined"
        className="flex items-center justify-center w-full h-screen"
      >
        <Loader />
      </motion.div>
    );
  }

  if (isLoaded(mealPlan) && isEmpty(mealPlan)) {
    return (
      <motion.Fragment exit="undefined">
        <Redirect to={ROUTES.ERROR_NOT_FOUND} />
      </motion.Fragment>
    );
  }

  if (
    isLoaded(mealPlan) &&
    !(mealPlan?.meta?.visibility?.isFree ?? false) &&
    !isAdmin &&
    !isSubscriber
  ) {
    return <span>Locked</span>;
  }

  return (
    <div className="overflow-hidden bg-white">
      <div className="relative mx-auto max-w-7xl ">
        {/* <div className="absolute top-0 bottom-0 hidden w-screen lg:block bg-gray-50 left-3/4" /> */}
        <div className="px-4 pt-4 mx-auto text-base sm:px-6 lg:px-8 max-w-prose lg:grid lg:grid-cols-2 lg:gap-8 lg:max-w-none">
          <div>
            <h2 className="text-base font-semibold tracking-wide uppercase text-cornflower-600">
              1-week Meal Plan
            </h2>
            <h3 className="mt-2 text-3xl font-extrabold leading-8 tracking-tight text-gray-900 capitalize sm:text-4xl">
              {mealPlan?.title}
            </h3>
          </div>
          <div>
            <div className="mt-3 lg:mt-0">
              <button
                onClick={handleStartMealPlan}
                className="inline-flex items-center justify-center w-full px-4 py-2 text-base font-medium text-white border border-transparent rounded-md shadow-md xs:justify-start xs:w-auto bg-cornflower-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cornflower-500 hover:bg-cornflower-700"
              >
                <UploadIcon className="w-5 h-5 mr-3 -ml-1" aria-hidden="true" />
                Start Meal Plan
              </button>
            </div>
          </div>
        </div>

        <div className="px-4 mt-4 sm:px-6 lg:px-8 lg:grid lg:grid-cols-2 lg:gap-8">
          <div className="relative lg:row-start-1 lg:col-start-2">
            <svg
              className="absolute top-0 right-0 hidden -mt-20 -mr-20 lg:block"
              width={404}
              height={384}
              fill="none"
              viewBox="0 0 404 384"
              aria-hidden="true"
            >
              <defs>
                <pattern
                  id="de316486-4a29-4312-bdfc-fbce2132a2c1"
                  x={0}
                  y={0}
                  width={20}
                  height={20}
                  patternUnits="userSpaceOnUse"
                >
                  <rect
                    x={0}
                    y={0}
                    width={4}
                    height={4}
                    className="text-gray-200"
                    fill="currentColor"
                  />
                </pattern>
              </defs>
              <rect
                width={404}
                height={384}
                fill="url(#de316486-4a29-4312-bdfc-fbce2132a2c1)"
              />
            </svg>
            {mealPlan && mealPlan.mainLandscapeUrl && (
              <div className="relative mx-auto text-base max-w-prose lg:max-w-none">
                <figure>
                  <div className="aspect-w-4 aspect-h-3">
                    <img
                      className="object-cover object-center rounded-lg shadow-lg"
                      src={mealPlan.mainLandscapeUrl}
                      alt={mealPlan.title}
                      width={2280}
                      height={1710}
                    />
                  </div>
                </figure>
              </div>
            )}
            {isAdmin ? (
              <div className="flex justify-end mt-3 space-x-2">
                <Link
                  className="inline-flex items-center px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cornflower-500 z-50"
                  to={`${ROUTES.CREATE_MEAL_PLAN}/${mealPlanId}`}
                >
                  <PencilIcon
                    className="w-5 h-5 mr-2 -ml-1"
                    aria-hidden="true"
                  />
                  Edit
                </Link>
              </div>
            ) : null}
          </div>
          <div className="mt-4 lg:mt-0">
            <ul className="inline-block mb-3 space-x-2 space-y-2">
              {mealPlan?.meta?.tags?.map((t, i) => {
                return (
                  <li
                    key={t + i}
                    className="inline-flex items-center px-2.5 py-0.5 rounded-md text-sm font-medium bg-gray-100 text-gray-800 capitalize" //cursor-pointer
                    //   onClick={() => handleTagClick(t)}
                  >
                    {t}
                  </li>
                );
              })}
            </ul>
            <div className="mx-auto text-base max-w-prose lg:max-w-none">
              <p className="text-lg text-gray-500">{mealPlan.description}</p>
            </div>
            {mealPlan?.meta?.videoUrl?.url &&
              isLoaded(creator) &&
              !isEmpty(creator) &&
              creator.youtubeChannelInfo && (
                <div className="pt-3">
                  <CreatorYoutubeBanner
                    creator={creator}
                    videoUrl={mealPlan.meta.videoUrl.url}
                  />
                </div>
              )}
            {/* <div className="mx-auto mt-5 prose text-gray-500 prose-cornflower lg:max-w-none lg:row-start-1 lg:col-start-1">
              <p>
                Sollicitudin tristique eros erat odio sed vitae, consequat
                turpis elementum. Lorem nibh vel, eget pretium arcu vitae. Eros
                eu viverra donec ut volutpat donec laoreet quam urna.
              </p>
              <p>
                Bibendum eu nulla feugiat justo, elit adipiscing. Ut tristique
                sit nisi lorem pulvinar. Urna, laoreet fusce nibh leo. Dictum et
                et et sit. Faucibus sed non gravida lectus dignissim imperdiet
                a.
              </p>
              <p>
                Dictum magnis risus phasellus vitae quam morbi. Quis lorem lorem
                arcu, metus, egestas netus cursus. In.
              </p>
            </div> */}
            {isLoaded(creator) && !isEmpty(creator) ? (
              <div className="mt-8">
                <RecipeCreatorNotes
                  {...creator}
                  description={mealPlan?.meta.notes.description}
                />
              </div>
            ) : null}
          </div>
        </div>

        <div className="mx-auto mt-8 max-w-7xl">
          <div className="px-4 sm:px-6 lg:px-8">
            <div className="pb-5 border-b border-gray-200 ">
              <h3 className="text-lg font-medium leading-6 text-gray-900">
                Suggested daily meals
              </h3>
              <p className="max-w-4xl mt-2 text-sm text-gray-500">
                This weekly meal plan includes the following recipes.
              </p>
            </div>
          </div>

          <div className="pb-4 mt-6 space-y-6 lg:space-y-8">
            {mealPlan?.days.map((day, ix) => (
              <div>
                <div className="flex flex-wrap items-baseline px-4 mb-1 -ml-2 sm:px-6 lg:px-8">
                  <h3 className="ml-2 text-lg font-medium leading-6 text-gray-900 ">
                    Day {ix + 1}
                  </h3>
                  <p className="mt-1 ml-2 text-sm text-gray-500 truncate">
                    of {mealPlan?.days.length}
                  </p>
                </div>
                <div className="sm:px-6 lg:px-8">
                  <MealPlanDayList meals={day.meals} dayIx={ix} key={"day" + ix} />
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
