import { ArrowCircleUpIcon } from "@heroicons/react/outline";
import React from "react";

function YoutubeVideoGridItem({
  videoTitle,
  videoDescription,
  videoUrl,
  videoPublishedAt,
  videoThumbnails,
}) {
  return (
    <li className="relative">
      <a
        href={videoUrl}
        target="_blank"
        rel="noreferrer"
        className="relative block overflow-hidden rounded-lg focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-cornflower-500 group"
      >
        <figure>
          <div className="relative w-full h-full overflow-hidden rounded-lg bg-gray-50 aspect-w-16 aspect-h-9">
            <div className="transition-all duration-300 group-hover:filter group-hover:blur-sm">
              <img
                src={videoThumbnails.medium.url}
                alt={videoTitle}
                className="object-cover w-full h-full pointer-events-none "
              />
            </div>

            <div className="absolute inset-0 z-10 flex items-center justify-center text-sm font-semibold text-white transition-opacity duration-300 opacity-0 group-hover:opacity-100">
              Watch video
              <ArrowCircleUpIcon className="w-5 h-5 ml-2 text-current transform rotate-45" />
            </div>
          </div>
          <figcaption>
            <p className="block mt-2 text-sm font-semibold text-gray-900 capitalize pointer-events-none line-clamp-2">
              {videoTitle}
            </p>
            {/* <p className="block text-xs font-medium text-gray-500 pointer-events-none">{videoPublishedAt}</p> */}
          </figcaption>
        </figure>
      </a>
    </li>
  );
}

export default YoutubeVideoGridItem;
