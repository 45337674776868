const initState = {
    error: null,
    isBusy: false
}

const userReducer = (state = initState, action) => {
    switch (action.type) {
        case 'ADD_FAVORITE_SUCCESS':
            // console.log(`SUCCESS: Add recipe ${action.recipeId} to user favorites.`);
            return {
                ...state,
                error: null
            }
        case 'ADD_FAVORITE_ERROR':
            // console.error("ERROR: Add recipe to user favorites: ", action.err);
            return {
                ...state,
                error: action.err
            }
        case 'REMOVE_FAVORITE_SUCCESS':
            // console.log(`SUCCESS: Remove recipe ${action.recipeId} from user favorites.`);
            return {
                ...state,
                error: null
            }
        case 'REMOVE_FAVORITE_ERROR':
            // console.warn("WARN: Recipe did not contain image");
            return {
                ...state,
                error: action.err
            }
        default:
            return state;
    }
};

export default userReducer;