import React from "react";
import BookmarkButton from "../buttons/BookmarkButton";
import FavoriteButton from "../buttons/FavoriteButton";
import { ArrowCircleUpIcon, LockClosedIcon } from "@heroicons/react/outline";
import ConditionalLink from "../utils/ConditionalLink";
import { getIsAdmin } from "../../store/selectors/selectors";
import { useDispatch, useSelector } from "react-redux";
import ConditionalDiv from "../utils/ConditionalDiv";
import { showModal } from "../../store/actions/modalActions";

function RecipeGridItem({ id, thumbnailUrl, title, meta }) {
  const dispatch = useDispatch();
  const isFree = meta?.visibility.isFree ?? false;
  const isAdmin = useSelector(getIsAdmin);
  const isAccessible = isFree || isAdmin;
  return (
    <>
      <li className="relative z-10">
        <div className="absolute z-20 top-1 left-1">
          {isFree ? (
            <BookmarkButton
              recipeId={id}
              thumbnailUrl={thumbnailUrl || "./android-chrome-512x512.jpg"}
              title={title}
              categories={meta.categories || []}
            />
          ) : (
            <div
              className="p-1 text-gray-600 rounded-full bg-gray-50 bg-opacity-70 focus:outline-none"
              aria-label="Locked (paid recipe)"
            >
              <LockClosedIcon className="w-6 h-6 stroke-current" />
            </div>
          )}
        </div>
        <div className="absolute z-20 top-1 right-1">
          <FavoriteButton recipeId={id} />
        </div>

        <ConditionalLink to={`/browse/recipes/${id}`} condition={isAccessible}>
          <ConditionalDiv
            className="cursor-pointer"
            condition={!isAccessible}
            onClick={() =>
              dispatch(
                showModal(
                  {
                    open: true,
                    title: "Sign up for our course to access this content!",
                    message:
                      "In addition no our free content we provide resources exclusive to members. Click below to find out more.",
                  },
                  "locked"
                )
              )
            }
          >
            <figure className="relative block overflow-hidden rounded-lg focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-cornflower-500 group">
              <div className="relative w-full h-full overflow-hidden rounded-lg bg-gray-50 aspect-w-3 aspect-h-4">
                <div className="transition-all duration-300 group-hover:filter group-hover:blur-sm">
                  <img
                    src={thumbnailUrl || "./android-chrome-512x512.jpg"}
                    alt="Recipe thumbnail"
                    className="object-cover w-full h-full pointer-events-none "
                  />
                </div>
                <div className="absolute inset-0 z-10 flex items-center justify-center text-sm font-semibold text-white transition-opacity duration-300 opacity-0 group-hover:opacity-100">
                  View recipe
                  <ArrowCircleUpIcon className="w-5 h-5 ml-2 text-current transform rotate-45" />
                </div>
              </div>
              <figcaption>
                <p className="block mt-2 text-sm font-semibold text-gray-900 capitalize pointer-events-none line-clamp-2">
                  {title}
                </p>
              </figcaption>
            </figure>
          </ConditionalDiv>
        </ConditionalLink>

        {/* <div className="block w-full overflow-hidden rounded-lg focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-cornflower-500 group aspect-w-3 aspect-h-4">
          <div className="group-hover:filter group-hover:blur-[6px] transition-all duration-300">
            <img
              src={thumbnailUrl}
              alt=""
              className="object-cover pointer-events-none"
            />
          </div>
          <div className="absolute inset-0 z-10 flex items-center justify-center text-sm font-medium text-gray-900 transition-opacity duration-300 opacity-0 group-hover:opacity-100">
            <Link to={`/recipes/${id}`} className="absolute inset-0">
              <span className="sr-only">View details for {title}</span>
            </Link>
          </div>
        </div> */}
        {/* <p className="block mt-2 text-sm font-semibold text-gray-900 capitalize pointer-events-none line-clamp-2">
          {title}
        </p> */}
        {/* <p className="block text-sm font-medium text-gray-500 pointer-events-none">
              {file.size}
            </p> */}
      </li>
    </>
  );
}

export default RecipeGridItem;
