import React, { useState, useEffect } from "react";
import { motion, useAnimation } from "framer-motion";
import { useScroll } from "react-use-gesture";
import { filter, includes } from "lodash";
import { useSelector, useDispatch } from "react-redux";
import { toggleTag } from "../../store/actions/filterActions";
import { getFilteredTags } from "../../store/selectors/selectors";

const clamp = (value, clampAt = 30) => {
  if (value > 0) {
    return value > clampAt ? clampAt : value;
  } else {
    return value < -clampAt ? -clampAt : value;
  }
};

const TagList = ({ allTags }) => {
  const dispatch = useDispatch();
  const controls = useAnimation();
  const filteredTags = useSelector(getFilteredTags);

  const [tags, setTags] = useState([]);
  useEffect(() => {
    setTags(filter(allTags, (t) => includes(filteredTags, t.name)));
  }, [allTags, filteredTags]);

  const bind = useScroll((event) => {
    controls.start({
      transform: `perspective(20rem) rotateY(${
        event.scrolling ? clamp(event.delta[0]) : 0
      }deg)`,
    });
  });
  // whileHover={{ scale: 1.05 }} whileTap={{ scale: 0.9 }}

  return tags.length ? (
    <ul {...bind()} className="flex w-full my-3 overflow-x-scroll space-x-2">
      {tags.map((tag) => {
        return (
          <motion.li key={tag.id} animate={controls} className="flex-none">
            <span className="inline-flex rounded-md items-center py-0.5 pl-2.5 pr-1 text-sm font-medium bg-gray-100 text-gray-800 capitalize">
              {tag.name}
              <button
                type="button"
                className="flex-shrink-0 ml-0.5 h-4 w-4 rounded-full inline-flex items-center justify-center text-gray-400 hover:bg-gray-200 hover:text-gray-500 focus:outline-none focus:bg-gray-500 focus:text-white"
                onClick={() => dispatch(toggleTag(tag.name))}
              >
                <span className="sr-only">Remove tag</span>
                <svg
                  className="w-2 h-2"
                  stroke="currentColor"
                  fill="none"
                  viewBox="0 0 8 8"
                >
                  <path
                    strokeLinecap="round"
                    strokeWidth="1.5"
                    d="M1 1l6 6m0-6L1 7"
                  />
                </svg>
              </button>
            </span>
          </motion.li>
        );
      })}
    </ul>
  ) : null;
};

export default TagList;
