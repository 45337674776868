import React from "react";
import RecipeGridItem from "./RecipeGridItem";
import { v4 as uuidv4 } from "uuid";

function RecipeGrid({ recipes }) {
  return (
    <>
      <ul className="grid grid-cols-2 gap-x-4 gap-y-8 xs:grid-cols-3 sm:grid-cols-4 sm:gap-x-6 lg:grid-cols-6 2xl:grid-cols-8 xl:gap-x-8">
        {recipes.map((recipe) => (
          <RecipeGridItem {...recipe} key={uuidv4()} />
        ))}
      </ul>
    </>
  );
}

export default RecipeGrid;
