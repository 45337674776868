import React from "react";
import { openPopupWidget } from "react-calendly";

const BookCallButton = ({ text, url, prefill, pageSettings, utm, classNames }) => {
  const onClick = () => openPopupWidget({ url, prefill, pageSettings, utm });

  return <button className={classNames} onClick={onClick}>{text}</button>;
};

export default BookCallButton;
