import React from "react";
import { ErrorMessage, Field, useField } from "formik";

function SelectField({ hint = "", options, label, requiredErrorMsg, ...props }) {
  const [field, meta, helpers] = useField(props);
  return (
    <>
      <div className="flex justify-between">
        <label
          htmlFor={field.name}
          className="block text-sm font-medium text-gray-700"
        >
          {label}
        </label>
        <span className="text-sm text-gray-500" id={field.name + "-hint"}>
          {hint}
        </span>
      </div>
      <Field
        as="select"
        type="text"
        name={field.name}
        id={field.name}
        className={
          (meta.error ? "border-red-500 " : "border-gray-300 ") +
          "block w-full px-3 py-2 mt-1 bg-white border rounded-md shadow-sm focus:outline-none focus:ring-cornflower-500 focus:border-cornflower-500 sm:text-sm"
        }
      >
        {options.map((option) => {
          return <option value={option.value} key={option.value}>{option.name}</option>;
        })}
      </Field>
      <ErrorMessage
        name={field.name}
        component="div"
        className="mt-1 text-xs text-red-600"
      />
    </>
  );
}

export default SelectField;
