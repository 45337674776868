export const formModel = () => {
  return {
    formId: "mealPlanForm",
    formField: {
      title: {
        name: "title",
        label: "Title",
        placeholder: "PLANT REBOOT - Week 1",
        hint: "Required",
      },
      description: {
        name: "description",
        label: "Description",
        hint: "Optional",
      },
      image: {
        name: "image",
        description: "Image",
        label: "Image (4:3)",
        hint: "Optional",
      },
      meta: {
        videoUrl: {
          url: {
            name: "meta.videoUrl.url",
            label: "Link / URL",
            placeholder: "https://www.youtube.com/watch",
            hint: "Optional",
          },
          description: {
            name: "meta.videoUrl.description",
            label: "Description",
            hint: "Optional",
          },
        },
        notes: {
          title: {
            name: "meta.notes.title",
            label: "Title / Headline",
            hint: "Optional",
          },
          description: {
            name: "meta.notes.description",
            label: "Description",
            hint: "Optional",
          },
        },
        visibility: {
          isPublished: {
            name: "meta.visibility.isPublished",
            label: "Public",
            description:
              "Publish recipe by setting public here. Until then it is in draft.",
          },
          isFree: {
            name: "meta.visibility.isFree",
            label: "Free",
            description:
              "Make recipe available to all users (including unregistered and unsubscribed).",
          },
        },
        tags: {
          name: "meta.tags",
          label: "Tags",
        },
      },
      days: {
        name: "days",
        description: "Days",
        hint: "Required",
      },
    },
  };
};
