import React from "react";
import { ErrorMessage, FieldArray, useField, useFormikContext } from "formik";
import { get } from "lodash";
import {
  MinusSmIcon,
  PlusSmIcon,
  ArrowSmUpIcon,
  ArrowSmDownIcon,
} from "@heroicons/react/solid";
import MealsArrayField from "./MealsArrayField";

function DaysArrayField({ ...props }) {
  const [field, meta] = useField(props);
  const { values } = useFormikContext();

  return (
    <FieldArray key={field.name} name={field.name}>
      {(arrayHelpers) => {
        return (
          <div className="space-y-4">
            {get(values, field.name, []) &&
            get(values, field.name, []).length > 0 ? (
              get(values, field.name, []).map((step, index) => (
                <div
                  className="flex p-4 space-x-4 shadow sm:rounded-lg bg-gray-50"
                  key={`${field.name}.${index}`}
                >
                  <div className="w-full space-y-4">
                    <div className="block md:space-x-4 md:flex">
                      <div className="flex-1 text-sm font-medium text-gray-700">
                        <span>Day </span>
                        <span>{index + 1}</span>
                        <span> / 7</span>
                      </div>
                      <div className="self-end flex-initial space-x-2 text-right">
                        <span className="relative z-0 inline-flex mt-1 rounded-md shadow-sm">
                          <button
                            type="button"
                            disabled={step?.length === 0 || index === 0}
                            onClick={() => arrayHelpers.swap(index - 1, index)}
                            className={
                              (index === 0
                                ? "text-gray-200 cursor-default "
                                : "text-gray-500 hover:bg-gray-50  ") +
                              "relative inline-flex items-center px-2 py-2 text-sm font-medium bg-white border border-gray-300 rounded-l-md focus:z-10 focus:outline-none focus:ring-1 focus:ring-cornflower-500 focus:border-cornflower-500"
                            }
                          >
                            <span className="sr-only">Up</span>
                            <ArrowSmUpIcon
                              className="w-5 h-5"
                              aria-hidden="true"
                            />
                          </button>
                          <button
                            type="button"
                            disabled={
                              step?.length === 0 ||
                              index >= get(values, field.name, []).length - 1
                            }
                            onClick={() => arrayHelpers.swap(index, index + 1)}
                            className={
                              (index >= get(values, field.name, []).length - 1
                                ? "text-gray-200 cursor-default "
                                : "text-gray-500 hover:bg-gray-50  ") +
                              "relative inline-flex items-center px-2 py-2 -ml-px text-sm font-medium bg-white border border-gray-300 rounded-r-md  focus:z-10 focus:outline-none focus:ring-1 focus:ring-cornflower-500 focus:border-cornflower-500"
                            }
                          >
                            <span className="sr-only">Down</span>
                            <ArrowSmDownIcon
                              className="w-5 h-5"
                              aria-hidden="true"
                            />
                          </button>
                        </span>

                        <span className="relative z-0 inline-flex mt-1 rounded-md shadow-sm">
                          <button
                            type="button"
                            onClick={() => arrayHelpers.remove(index)}
                            className="relative inline-flex items-center px-2 py-2 text-sm font-medium text-gray-500 bg-white border border-gray-300 rounded-l-md hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-cornflower-500 focus:border-cornflower-500"
                          >
                            <span className="sr-only">Remove</span>
                            <MinusSmIcon
                              className="w-5 h-5"
                              aria-hidden="true"
                            />
                          </button>
                          <button
                            type="button"
                            disabled={step?.length === 0}
                            onClick={() => arrayHelpers.push("")}
                            className="relative inline-flex items-center px-2 py-2 -ml-px text-sm font-medium text-gray-500 bg-white border border-gray-300 rounded-r-md hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-cornflower-500 focus:border-cornflower-500"
                          >
                            <span className="sr-only">Add</span>
                            <PlusSmIcon
                              className="w-5 h-5"
                              aria-hidden="true"
                            />
                          </button>
                        </span>
                      </div>
                    </div>

                    <div className="">
                      <MealsArrayField
                        fieldName={`${field.name}.${index}.meals`}
                      />
                    </div>

                    {/* {meta.error && meta.touched && (
                        <div className="mt-1 text-xs text-red-600">
                          {meta.error}
                        </div>
                      )} */}
                  </div>
                </div>
              ))
            ) : (
              <button
                type="button"
                onClick={() => arrayHelpers.push("")}
                className="inline-flex items-center px-4 py-2 text-sm font-medium border border-transparent rounded-md text-cornflower-700 bg-cornflower-100 hover:bg-cornflower-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cornflower-500"
              >
                Add day
              </button>
            )}
          </div>
        );
      }}
    </FieldArray>
  );
}

export default DaysArrayField;
