export const formInitialValues = (existing = null) => {
  return (
    existing ?? {
      title: "",
      description: "",
      image: "",
      meta: {
        videoUrl: {
          url: "",
          description: "",
        },
        notes: {
          title: "",
          description: "",
        },
        visibility: {
          isPublished: false,
          isFree: false,
        },
        tags: [],
      },
      days: [],
    }
  );
};
