import moment from "moment";

function readFile(file) {
  return new Promise((resolve, reject) => {
    var fr = new FileReader();
    fr.onload = () => {
      resolve(fr.result);
    };
    fr.onerror = reject;
    fr.readAsDataURL(file);
  });
}

const getBookmarkReference = (addDays) => {
  return moment().add(addDays, "days").format("[bookmarks.]dddd");
};

export const initCreateMealPlan = () => {
  return async (dispatch, getState, getFirebase) => {
    dispatch({ type: "CREATE_MEAL_PLAN_REQUEST" });
  };
};

export const createMealPlan = (formValues) => {
  return async (dispatch, getState, getFirebase) => {
    // author info
    const authorId = getState().firebase.auth.uid;
    const firestore = getFirebase().firestore();

    const { image, ...data } = { ...formValues };

    const docId = await firestore
      .collection("mealPlans")
      .add({
        ...data,
        authorId: authorId,
        createdAt: Date.now(),
      })
      .then((doc) => {
        return doc.id;
      })
      .catch((err) => {
        dispatch({ type: "CREATE_MEAL_PLAN_ERROR", error: err.message });
        return null;
      });

    if (docId === null) {
      return;
    }

    // 2. Upload image
    if (image !== "") {
      const storage = getFirebase().storage();
      const storageRef = storage.ref();
      const fileRef = storageRef.child(`mealPlans/${docId}/Landscape.jpg`); //${getImageName(data.portraitImage)}

      readFile(image)
        .then(async (result) => {
          await fileRef.putString(result, "data_url");
        })
        .catch((err) => {
          dispatch({ type: "CREATE_MEAL_PLAN_ERROR", error: err.message });
          return;
        });
    }

    return dispatch({ type: "CREATE_MEAL_PLAN_SUCCESS", mealPlanId: docId });
  };
};

export const updateMealPlan = (id, formValues) => {
  return async (dispatch, getState, getFirebase) => {
    // author info
    const authorId = getState().firebase.auth.uid;

    const firestore = getFirebase().firestore();

    const { image, ...data } = { ...formValues };

    await firestore
      .collection("mealPlans")
      .doc(id)
      .update({
        ...data,
        authorId: authorId,
        updatedAt: new Date(),
      })
      .catch((err) => {
        dispatch({ type: "CREATE_MEAL_PLAN_ERROR", error: err.message });
        return null;
      });

    // 2. Upload image
    if (image !== "") {
      const storage = getFirebase().storage();
      const storageRef = storage.ref();
      const fileRef = storageRef.child(`mealPlans/${id}/Landscape.jpg`); //${getImageName(data.portraitImage)}

      readFile(image)
        .then(async (result) => {
          await fileRef.putString(result, "data_url");
        })
        .catch((err) => {
          dispatch({ type: "CREATE_MEAL_PLAN_ERROR", error: err.message });
          return;
        });
    }
  };
};

export const finishCreateMealPlan = () => {
  return async (dispatch, getState, getFirebase) => {
    dispatch({ type: "CREATE_MEAL_PLAN_FINISH" });
  };
};

export const startMealPlan = (id) => {
  return async (dispatch, getState, getFirebase) => {
    const uid = getState().firebase.auth.uid;
    const firestore = getFirebase().firestore();

    if (uid === null) {
      return dispatch({
        type: "START_MEAL_PLAN_ERROR",
        error: "Pleas sign in to load a meal plan.",
      });
    }

    // load this meal plan
    const mealPlan = await firestore
      .collection("mealPlans")
      .doc(id)
      .get()
      .then((doc) => {
        if (doc.exists) {
          return doc.data();
        } else {
          return null;
        }
      })
      .catch((error) => {
        dispatch({
          type: "START_MEAL_PLAN_ERROR",
          error: "Could not load meal plan. Please try again later.",
        });
        return null;
      });

    if (mealPlan === null) {
      return;
    }

    // update user bookmarks
    await firestore
      .collection("users")
      .doc(uid)
      .update({
        [getBookmarkReference(0)]: mealPlan.days[0].meals.map(
          (meal) => meal.recipe
        ),
        [getBookmarkReference(1)]: mealPlan.days[1].meals.map(
          (meal) => meal.recipe
        ),
        [getBookmarkReference(2)]: mealPlan.days[2].meals.map(
          (meal) => meal.recipe
        ),
        [getBookmarkReference(3)]: mealPlan.days[3].meals.map(
          (meal) => meal.recipe
        ),
        [getBookmarkReference(4)]: mealPlan.days[4].meals.map(
          (meal) => meal.recipe
        ),
        [getBookmarkReference(5)]: mealPlan.days[5].meals.map(
          (meal) => meal.recipe
        ),
        [getBookmarkReference(6)]: mealPlan.days[6].meals.map(
          (meal) => meal.recipe
        ),
      })
      .then(() => {
        return dispatch({ type: "START_MEAL_PLAN_SUCCESS", mealPlanId: id });
      })
      .catch((err) => {
        return dispatch({ type: "START_MEAL_PLAN_ERROR", err });
      });
  };
};
