import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Route, Switch, Redirect, useLocation } from "react-router-dom";
import { getFirebase } from "react-redux-firebase";
import ReduxToastr from "react-redux-toastr";
import { motion, AnimatePresence } from "framer-motion";
import PrivateRoute from "./components/auth/PrivateRoute";
import * as ROUTES from "./constants/routes";
import SidebarNavigation from "./components/layout/sidebar/SidebarNavigation";
import Home from "./components/home/Home";
import Favorites from "./components/favorites/Favorites";
import ShoppingList from "./components/shoppingList/ShoppingList";
import SignIn from "./components/auth/SignIn";
import SingleRecipe from "./components/recipes/SingleRecipe";
import ErrorNotFound from "./components/error/Error";
import { updateUserProfile } from "./store/actions/authActions";
import EditMealPlan from "./components/mealPlan/EditMealPlan";
import RootModal from "./components/modals/RootModal";
import { hideModal } from "./store/actions/modalActions";
import Browse from "./components/browse/Browse";
import { ThemeProvider } from "styled-components";
import { theme } from "./theme";
import Drafts from "./components/drafts/Drafts";
import PrivacyPolicy from "./components/legal/PrivacyPolicy";
import TermsOfService from "./components/legal/TermsOfService";
import Reboot from "./components/courses/Reboot";
import { SelectAuthUID } from "./store/selectors/selectors";
import Profile from "./components/account/Profile";
import Settings from "./components/account/Settings";
import RecipeFormWizard from "./components/recipes/RecipeFormWizard";
import { Helmet } from "react-helmet";
import MealPlan from "./components/mealPlan/MealPlan";
import AdminOnlyRoute from "./components/auth/AdminOnlyRoute";
import MealPlanDetail from "./components/mealPlan/MealPlanDetail";
import Shop from "./components/shop/Shop";
import MainPage from "./components/nutrition/MainPage";

function App() {
  const dispatch = useDispatch();
  const location = useLocation();
  const uid = useSelector(SelectAuthUID);

  useEffect(() => {
    // listen for auth state changes
    const firebase = getFirebase();
    const unsubscribe = firebase.auth().onAuthStateChanged(async (user) => {
      if (user) {
        // User is signed in.
        // console.log("User signed in: ", user);

        if (!user.emailVerified) {
          user
            .sendEmailVerification()
            .then(() => {
              // Email sent.
            })
            .catch((err) => {
              // An error happened.
            });
        }

        dispatch(
          updateUserProfile({
            emailVerified: user.emailVerified,
            displayName: user.displayName,
            photoURL: user.photoURL,
            lastSignInTime: user.metadata.lastSignInTime,
          })
        );
      } else {
        // No user is signed in.
        //console.log("No user signed in!");
      }
    });

    // unsubscribe to the listener when unmounting
    return () => unsubscribe();
  }, [dispatch, uid]);

  return (
    <ThemeProvider theme={theme}>
      <Helmet
        titleTemplate="Deliish | %s"
        defaultTitle="Recipes and Meal Plans"
      >
        {/* General tags */}
        <title>Recipes and Meal Plans</title>
        <meta
          name="description"
          content="Wholefood plant-based recipes and meal plans for food-lovers."
        />
        {/* <link rel="canonical" href="https://deliish.app" /> */}

        {/* OpenGraph tags */}
        <meta property="og:url" content="https://deliish.app/" />
        <meta property="og:title" content="Deliish - Recipes and Meal Plans" />
        <meta
          property="og:description"
          content="Wholefood plant-based recipes and meal plans for food-lovers."
        />
        <meta property="og:image" content="android-chrome-512x512.jpg" />
        <meta property="fb:app_id" content="" />
        <meta
          property="og:site_name"
          content="Deliish. 2021 by The Hooray Media LTD"
        />
        <meta name="og:image:alt" content="" />
        <meta name="og:type" content="website" />

        {/* Twitter Card tags */}
        {/* <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:creator" content={seo.social.twitter} />
        <meta name="twitter:title" content={title} />
        <meta name="twitter:description" content={description} />
        <meta name="twitter:image" content={image} /> */}
      </Helmet>
      <>
        <RootModal onHide={() => dispatch(hideModal())} />
        <SidebarNavigation>
          <AnimatePresence
            exitBeforeEnter
            onExitComplete={() => dispatch(hideModal())}
          >
            <Switch location={location} key={location.pathname}>
              <Route exact path={ROUTES.LANDING} component={Home} />

              <Route
                exact
                path={ROUTES.BROWSE_RECIPES + "/:recipeId"}
                component={SingleRecipe}
              />
              <Redirect
                exact
                path="/recipes/:recipeId"
                to="/browse/recipes/:recipeId"
              />
              <Route
                exact
                path="/browse/mealPlans/:mealPlanId"
                component={MealPlanDetail}
              />

              <Route path={ROUTES.BROWSE} component={Browse} />

              <PrivateRoute
                key={ROUTES.MEAL_PLANNER}
                path={ROUTES.MEAL_PLANNER}
              >
                <MealPlan />
              </PrivateRoute>

              <Route path={ROUTES.SIGN_IN} component={SignIn}>
                {uid ? (
                  <motion.Fragment exit="undefined">
                    <Redirect to={ROUTES.LANDING} />
                  </motion.Fragment>
                ) : (
                  <SignIn />
                )}
              </Route>

              <PrivateRoute key={ROUTES.PROFILE} exact path={ROUTES.PROFILE}>
                <Profile />
              </PrivateRoute>

              <PrivateRoute key={ROUTES.SETTINGS} exact path={ROUTES.SETTINGS}>
                <Settings />
              </PrivateRoute>

              <AdminOnlyRoute
                key={ROUTES.CREATE_RECIPE}
                exact
                path={`${ROUTES.CREATE_RECIPE}/:id`}
              >
                <RecipeFormWizard />
              </AdminOnlyRoute>

              <AdminOnlyRoute
                key={ROUTES.CREATE_MEAL_PLAN}
                path={`${ROUTES.CREATE_MEAL_PLAN}/:id`}
              >
                <EditMealPlan />
              </AdminOnlyRoute>

              <AdminOnlyRoute key={ROUTES.DRAFTS} path={ROUTES.DRAFTS}>
                <Drafts />
              </AdminOnlyRoute>

              <PrivateRoute
                key={ROUTES.SHOPPING_LIST}
                path={ROUTES.SHOPPING_LIST}
              >
                <ShoppingList />
              </PrivateRoute>

              {/* <PrivateRoute key={ROUTES.FAVORITES} path={ROUTES.FAVORITES}>
                <Favorites />
              </PrivateRoute> */}

              {/* <Route path={ROUTES.COURSES_REBOOT} component={Reboot} /> */}
              <Route path={ROUTES.NUTRITION} component={MainPage} />
              <Route path={ROUTES.SHOP} component={Shop} />

              <Route path={ROUTES.PRIVACY_POLICY} component={PrivacyPolicy} />
              <Route
                path={ROUTES.TERMS_OF_SERVICE}
                component={TermsOfService}
              />

              <Route path={ROUTES.ERROR_NOT_FOUND} component={ErrorNotFound} />
              <motion.Fragment exit="undefined">
                <Redirect to={ROUTES.ERROR_NOT_FOUND} />
              </motion.Fragment>
            </Switch>
          </AnimatePresence>
        </SidebarNavigation>

        <ReduxToastr
          timeOut={5000}
          newestOnTop={true}
          position="top-right"
          transitionIn="fadeIn"
          transitionOut="fadeOut"
          preventDuplicates
          progressBar
          closeOnToastrClick
        />
      </>
    </ThemeProvider>
  );
}

export default App;
