import React, { useState, useEffect, useCallback } from "react";
import { isLoaded, isEmpty } from "react-redux-firebase";
import { useSelector, useDispatch } from "react-redux";
import { Link, useParams, Redirect, useHistory } from "react-router-dom";
import StepList from "./StepList";
import IngredientList from "./IngredientList";
import { useWindowSize } from "../utils/useWindowSize";
import { find, sumBy } from "lodash";
import { motion, useElementScroll, useTransform } from "framer-motion";
import * as ROUTES from "../../constants/routes";
import {
  adjustShoppingList,
  addToShoppingList,
} from "../../store/actions/userActions";
import FavoriteButton from "../buttons/FavoriteButton";
import styled from "styled-components";
import {
  setSingleCategory,
  setSingleTag,
} from "../../store/actions/filterActions";
import { v4 as uuidv4 } from "uuid";
import {
  PlusSmIcon,
  MinusSmIcon,
  ShoppingBagIcon,
} from "@heroicons/react/outline";
import { ClockIcon } from "@heroicons/react/solid";
import { PencilIcon } from "@heroicons/react/outline";
import RecipeCreatorNotes from "./RecipeCreatorNotes";
import {
  getCategories,
  getIsAdmin,
  getIsSubscriber,
  getPublishedRecipes,
  getShoppingList,
  getTags,
} from "../../store/selectors/selectors";
import { useFirestoreConnect } from "react-redux-firebase";
import RecipeGrid from "./RecipeGrid";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretUp } from "@fortawesome/free-solid-svg-icons";
import Loader from "../loaders/Loader";
import { Helmet } from "react-helmet";
import CreatorYoutubeBanner from "../creator/CreatorYoutubeBanner";
import BookmarkButton from "../buttons/BookmarkButton";

const BodyElement = styled.div`
  margin-top: calc(
    100vh - 4rem - 120px
  ); //search bar is 63px + 1px border (4rem)
`;

const BodyScrollContainer = styled.div`
  overflow-anchor: none;
  max-height: 100%;
  max-height: -webkit-fill-available;
`;

const BackgroundImageElement = styled.div`
  height: calc(100vh - 4rem);
  background-image: url(${(props) => props.url});
`;

const FlexElement = styled.div`
  height: 100%;
`;

const SingleRecipe = () => {
  let { recipeId } = useParams();
  useFirestoreConnect(() => [
    {
      collection: "categories",
      orderBy: [["sort"]],
      storeAs: "categories",
    },
    {
      collection: "tags",
      orderBy: [["createdAt"]],
      storeAs: "tags",
    },
    {
      collection: "creators",
      storeAs: "creators",
    },
    {
      collection: "recipes",
      orderBy: [["createdAt", "desc"]],
      storeAs: "recipes",
    },
  ]);

  const ref = useCallback((node) => {
    if (node !== null) {
      ref.current = node;
    }
  }, []);

  const history = useHistory();
  const dispatch = useDispatch();

  const recipes = useSelector(getPublishedRecipes);
  const recipe = useSelector(
    ({ firestore: { data } }) => data.recipes && data.recipes[recipeId]
  );
  const creator = useSelector(
    ({ firestore: { data } }) =>
      data.creators && data.creators[recipe?.authorId]
  );
  const isAdmin = useSelector(getIsAdmin);
  const isSubscriber = useSelector(getIsSubscriber);
  const shoppingList = useSelector(getShoppingList).filter(
    (i) => i.recipeId === recipeId
  );
  const allCategories = useSelector(getCategories);
  const allTags = useSelector(getTags);

  const [ingredients, setIngredients] = useState(recipe?.ingredients?.groups);
  const [servings, setServings] = useState(recipe?.ingredients?.servings);
  const [ingredientCount, setIngredientCount] = useState(0);

  useEffect(() => {
    setIngredients(recipe?.ingredients?.groups);
  }, [recipe]);

  useEffect(() => {
    setServings(recipe?.ingredients?.servings);
  }, [recipe]);

  useEffect(() => {
    setIngredientCount(sumBy(ingredients, (i) => i.ingredients?.length));
  }, [ingredients]);

  const { width, height } = useWindowSize();
  const { scrollYProgress } = useElementScroll(ref);
  const rotate = useTransform(scrollYProgress, [0, 0.2], [0, 180]);
  // console.log("SCROLL: ", scrollYProgress)
  // console.log("ROTATE: ", rotate)

  if (!isLoaded(recipe) && isEmpty(recipes)) {
    return (
      <motion.div
        ref={ref}
        exit="undefined"
        className="flex h-screen w-full items-center justify-center"
      >
        <Loader />
      </motion.div>
    );
  }

  if (isEmpty(recipe)) {
    return (
      <motion.Fragment ref={ref} exit="undefined">
        <Redirect to={ROUTES.ERROR_NOT_FOUND} />
      </motion.Fragment>
    );
  }

  if (
    !(recipe?.meta?.visibility?.isFree ?? false) &&
    !isAdmin &&
    !isSubscriber
  ) {
    return (
      <motion.Fragment ref={ref} exit="undefined">
        <Redirect to={ROUTES.ERROR_NOT_FOUND} />
      </motion.Fragment>
    );
  }

  const updateIngredientsAmount = (servings) => {
    const servingsBase = recipe?.ingredients?.servings;
    if (isNaN(servingsBase)) return;

    let updatedIngredients = recipe?.ingredients?.groups.map((v, i) => {
      return {
        ...v,
        ingredients: v.ingredients.map((e) => {
          return {
            ...e,
            amount:
              Math.round(
                ((e.amount * servings) / servingsBase + Number.EPSILON) * 100
              ) / 100,
            altAmount:
              Math.round(
                ((e.altAmount * servings) / servingsBase + Number.EPSILON) * 100
              ) / 100,
          };
        }),
      };
    });
    setIngredients(updatedIngredients);
  };

  const updateShoppingList = (factor) => {
    dispatch(adjustShoppingList(recipeId, factor));
  };

  const updateServings = (newServings) => {
    const oldServings = servings;
    setServings(newServings);
    updateIngredientsAmount(newServings);
    updateShoppingList(newServings / oldServings); // -> PROBLEM: Object no longer the same (introduce key??)
  };

  const decreaseServings = () => {
    if (servings === 1) return;
    updateServings(servings - 1);
  };

  const increaseServings = () => {
    if (servings === 6) return;
    updateServings(servings + 1);
  };

  const addAllToShoppingList = () => {
    ingredients?.forEach((ge, gi) => {
      ge?.ingredients?.forEach((e, i) => {
        // console.log({ ...e, id: `${gi}-${i}` });
        dispatch(addToShoppingList(recipeId, { ...e, id: `${gi}-${i}` }));
      });
    });
  };

  const handleCategoryClick = (name) => {
    let category = find(allCategories, (c) => c.name === name);
    dispatch(
      setSingleCategory({ value: category.name, label: category.displayName })
    );
    return history.push(ROUTES.BROWSE);
  };

  const handleTagClick = (name) => {
    let tag = find(allTags, (t) => t.name === name);
    dispatch(setSingleTag({ value: tag.name, label: tag.name }));
    return history.push(ROUTES.BROWSE);
  };

  const relatedRecipes = recipes?.filter((r) =>
    recipe?.relatedRecipes?.includes(r.id)
  );

  return (
    <motion.div exit={{ opacity: 0 }}>
      <Helmet>
        <title>{recipe?.title}</title>
        <meta name="description" content={recipe?.description} />
        <meta property="og:description" content={recipe?.description} />
        <meta name="og:type" content="article" />
      </Helmet>

      <BackgroundImageElement
        url={
          width < height ? recipe?.mainPortraitUrl : recipe?.mainLandscapeUrl
        }
        className="relative bg-cover bg-scroll bg-clip-border bg-center bg-no-repeat"
      >
        <BodyScrollContainer
          ref={ref}
          className="absolute bottom-0 left-0 right-0 max-h-full overflow-x-hidden overflow-y-scroll"
        >
          {recipe && (
            <BodyElement className="relative rounded-t-3xl bg-white p-4 sm:p-6 lg:p-8">
              <motion.div
                key={uuidv4()}
                className="absolute top-0 left-1/2 text-center sm:top-2"
                style={{ rotate }}
              >
                <FontAwesomeIcon
                  icon={faCaretUp}
                  className="w-5-i sm:w-6-i h-5 sm:h-6"
                  aria-hidden="true"
                />
              </motion.div>

              <div className="mb-3 flex justify-between">
                <div className="">
                  <ul className="mr-2 inline-block space-x-2 space-y-2">
                    {recipe?.meta?.categories?.map((t, i) => {
                      return (
                        <li
                          key={t + i}
                          className="inline-flex cursor-pointer items-center rounded-md bg-gray-800 px-2.5 py-0.5 text-sm font-medium capitalize text-gray-50"
                          onClick={() => handleCategoryClick(t)}
                        >
                          {t}
                        </li>
                      );
                    })}
                  </ul>
                  <ul className="inline-block space-x-2 space-y-2">
                    {recipe?.meta?.tags?.map((t, i) => {
                      return (
                        <li
                          key={t + i}
                          className="inline-flex cursor-pointer items-center rounded-md bg-gray-100 px-2.5 py-0.5 text-sm font-medium capitalize text-gray-800"
                          onClick={() => handleTagClick(t)}
                        >
                          {t}
                        </li>
                      );
                    })}
                  </ul>
                </div>

                <div className="space-x-2">
                  <BookmarkButton
                    recipeId={recipeId}
                    thumbnailUrl={
                      recipe.thumbnailUrl || "./android-chrome-512x512.jpg"
                    }
                    title={recipe.title}
                    categories={recipe.meta.categories || []}
                  />
                  <FavoriteButton recipeId={recipeId} />
                </div>
              </div>

              <div className="grid grid-cols-1 gap-8 overflow-x-hidden md:grid-cols-2">
                <FlexElement>
                  <h1 className="text-3xl font-extrabold capitalize text-gray-900">
                    {recipe?.title}
                  </h1>
                  <div className="pt-3">
                    <div className="flex flex-row items-center justify-between rounded-md bg-red-50 px-4 py-3">
                      <span className="text-xl font-semibold text-gray-900">
                        {servings > 1 ? "Servings" : "Serving"}
                      </span>

                      <div className="flex items-center justify-center">
                        <button
                          className="rounded-full bg-white p-2 text-red-400 hover:text-red-500"
                          onClick={() => decreaseServings()}
                        >
                          <MinusSmIcon className="h-6 w-6 stroke-current" />
                        </button>
                        <span className="px-4 text-xl font-semibold text-gray-900">
                          {servings}
                        </span>
                        <button
                          className="rounded-full bg-white p-2 text-red-400 hover:text-red-500"
                          onClick={() => increaseServings()}
                        >
                          <PlusSmIcon className="h-6 w-6 stroke-current" />
                        </button>
                      </div>
                    </div>
                  </div>
                  {recipe?.meta?.videoUrl?.url &&
                    isLoaded(creator) &&
                    !isEmpty(creator) &&
                    creator.youtubeChannelInfo && (
                      <div className="pt-3">
                        <CreatorYoutubeBanner
                          creator={creator}
                          videoUrl={recipe.meta.videoUrl.url}
                        />
                      </div>
                    )}
                </FlexElement>

                <FlexElement>
                  <div className="mb-2">
                    <span className="text-xl font-semibold text-gray-900">
                      About
                    </span>
                  </div>
                  <p className="text-justify text-base leading-7 text-gray-500">
                    {recipe?.description}
                  </p>
                </FlexElement>

                <FlexElement className="select-none">
                  <>
                    <div className="flex items-center justify-between">
                      <span className="flex items-center space-x-2 text-xl font-semibold text-gray-900">
                        <span className="rounded-md bg-gray-100 px-2.5 py-0.5 text-sm font-medium text-gray-800">
                          {ingredientCount}
                        </span>
                        <span>Ingredients</span>
                      </span>
                      <button
                        type="button"
                        className="inline-flex items-center rounded-md border border-transparent bg-red-50 px-4 py-2 text-sm font-medium text-red-400 hover:bg-red-100 hover:text-red-500 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
                        onClick={() => addAllToShoppingList()}
                      >
                        <PlusSmIcon
                          className="mr-2 -ml-1 h-5 w-5"
                          aria-hidden="true"
                        />
                        Add all
                      </button>
                    </div>
                    {ingredients?.map((g, i) => {
                      return (
                        <IngredientList
                          key={i}
                          ingredientGroup={g}
                          recipeId={recipeId}
                          id={i}
                        />
                      );
                    })}
                    {shoppingList.length ? (
                      <div className="mt-3 text-right">
                        <Link
                          to={ROUTES.SHOPPING_LIST}
                          className="inline-flex items-center rounded-md border border-transparent bg-red-50 px-4 py-2 text-sm font-medium text-red-400 hover:bg-red-100 hover:text-red-500 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
                          onClick={() => addAllToShoppingList()}
                        >
                          <ShoppingBagIcon
                            className="mr-2 -ml-1 h-5 w-5"
                            aria-hidden="true"
                          />
                          View shopping list
                        </Link>
                      </div>
                    ) : null}
                  </>
                </FlexElement>

                <div className="h-full">
                  <>
                    <div className="mb-2 flex items-center justify-between">
                      <span className="text-xl font-semibold text-gray-900">
                        Directions
                      </span>
                      <div>
                        <span className="flex items-center rounded-md bg-gray-100 px-2.5 py-0.5 text-sm font-medium text-gray-800">
                          <ClockIcon className="mr-2 h-5 w-5 text-gray-400" />
                          {recipe?.meta?.time?.amount}{" "}
                          {recipe?.meta?.time?.unit}
                        </span>
                      </div>
                    </div>
                    <StepList steps={recipe?.steps} />

                    {isLoaded(creator) && !isEmpty(creator) ? (
                      <div className="mt-8">
                        <RecipeCreatorNotes
                          {...creator}
                          description={recipe?.meta.notes.description}
                        />
                      </div>
                    ) : null}
                  </>
                </div>

                {recipe?.nutrition?.calories.amount ||
                recipe?.nutrition?.carbs.amount ||
                recipe?.nutrition?.protein.amount ||
                recipe?.nutrition?.fat.amount ||
                recipe?.nutrition?.sodium.amount ||
                recipe?.nutrition?.sugar.amount ? (
                  <FlexElement>
                    <div className="mb-2">
                      <span className="text-xl font-semibold text-gray-900">
                        Nutrition Information{" "}
                        <small className="text-muted">per serving</small>
                      </span>
                    </div>
                  </FlexElement>
                ) : null}

                {!isEmpty(relatedRecipes) ? (
                  <div className="md:col-span-2">
                    <div className="mb-2">
                      <span className="text-xl font-semibold text-gray-900">
                        Related Recipes
                      </span>
                    </div>
                    <RecipeGrid recipes={relatedRecipes} />
                  </div>
                ) : null}
              </div>

              {isAdmin ? (
                <div className="mt-3 flex justify-end space-x-2">
                  <Link
                    className="inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-cornflower-500 focus:ring-offset-2"
                    to={`${ROUTES.CREATE_RECIPE}/${recipeId}`}
                  >
                    <PencilIcon
                      className="mr-2 -ml-1 h-5 w-5"
                      aria-hidden="true"
                    />
                    Edit
                  </Link>
                </div>
              ) : null}
            </BodyElement>
          )}
        </BodyScrollContainer>
      </BackgroundImageElement>
    </motion.div>
  );
};

export default SingleRecipe;
