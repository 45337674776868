const initState = {
    finished: false,
    error: null,
    mealPlanId: null
}

const mealPlanReducer = (state = initState, action) => {
    switch (action.type) {
        case 'CREATE_MEAL_PLAN_REQUEST':
            return initState;
        case 'CREATE_MEAL_PLAN_SUCCESS':
            // console.log(`SUCCESS: Create meal plan with id ${action.mealPlanId}`);
            return {
                ...state,
                finished: true,
                mealPlanId: action.mealPlanId,
                error: null
            }
        case 'CREATE_MEAL_PLAN_ERROR':
            // console.error("ERROR: Creating meal plan data: ", action.error);
            return {
                ...state,
                finished: true,
                mealPlanId: null,
                error: action.error
            }
        case 'CREATE_MEAL_PLAN_FINISH':
            return initState;
        default:
            return state;
    }
};

export default mealPlanReducer;