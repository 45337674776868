import React from "react";
import { FieldArray, useField, useFormikContext } from "formik";
import { get, map } from "lodash";
import {
  MinusSmIcon,
  PlusSmIcon,
  ArrowSmUpIcon,
  ArrowSmDownIcon,
} from "@heroicons/react/solid";
import SelectField from "../SelectField";
// import AutoSuggestField from "../AutoSuggestField";
import TextAreaField from "../TextAreaField";
import { useSelector } from "react-redux";
import {
  getAllRecipes,
  getCategories,
} from "../../../store/selectors/selectors";

function MealsArrayField({ fieldName }) {
  const { values } = useFormikContext();
  const recipes = useSelector(getAllRecipes);
  const recipeOptions = [
    { name: "", value: "" },
    ...recipes.map((r) => ({
      name: r.title,
      value: r.id,
    })),
  ];
  //   const recipeOptions = {
  //     choices: {
  //       title: "All recipes",
  //       suggestions: map(recipes, (r) => ({
  //         id: r.id,
  //         text: r.title,
  //       })),
  //     },
  //   };
  const categories = useSelector(getCategories);
  const categoryOptions = [
    { name: "", value: "" },
    ...categories.map((category) => ({
      name: category.displayName,
      value: category.name,
    })),
  ];

  const model = (ix) => {
    return {
      category: {
        name: `${fieldName}.${ix}.category`,
        label: "Category/Department",
        hint: "Required",
        options: categoryOptions,
      },
      recipe: {
        name: `${fieldName}.${ix}.recipe`,
        label: "Recipe",
        hint: "Required",
        options: recipeOptions,
      },
      notes: {
        name: `${fieldName}.${ix}.notes`,
        label: "Notes",
        hint: "Optional",
      },
    };
  };

  return (
    <FieldArray name={fieldName} key={fieldName}>
      {(arrayHelpers) => {
        return (
          <div className="space-y-4">
            {get(values, fieldName, []) &&
            get(values, fieldName, []).length > 0 ? (
              get(values, fieldName, []).map((ingredient, index) => (
                <>
                  <div
                    key={`${fieldName}.${index}`}
                    className="p-4 space-y-4 bg-white shadow sm:rounded-lg"
                  >
                    <div className="grid w-full grid-cols-2 gap-4">
                      <div className="col-span-2">
                        <SelectField {...model(index).category} />
                      </div>

                      <div className="col-span-2">
                        {/* TODO: Limit based on category - dependent field! */}
                        {/* <AutoSuggestField
                          {...model(index).recipe}
                          options={recipeOptions}
                        /> */}
                        <SelectField {...model(index).recipe} />
                      </div>

                      <div className="col-span-2">
                        <TextAreaField {...model(index).notes} />
                      </div>
                    </div>

                    <div className="space-x-2 text-right">
                      <span className="relative z-0 inline-flex rounded-md shadow-sm">
                        <button
                          type="button"
                          disabled={ingredient?.length === 0 || index === 0}
                          onClick={() => arrayHelpers.swap(index - 1, index)}
                          className={
                            (index === 0
                              ? "text-gray-200 cursor-default "
                              : "text-gray-500 hover:bg-gray-50  ") +
                            "relative inline-flex items-center px-2 py-2 text-sm font-medium bg-white border border-gray-300 rounded-l-md focus:z-10 focus:outline-none focus:ring-1 focus:ring-cornflower-500 focus:border-cornflower-500"
                          }
                        >
                          <span className="sr-only">Up</span>
                          <ArrowSmUpIcon
                            className="w-5 h-5"
                            aria-hidden="true"
                          />
                        </button>
                        <button
                          type="button"
                          disabled={
                            ingredient?.length === 0 ||
                            index >= get(values, fieldName, []).length - 1
                          }
                          onClick={() => arrayHelpers.swap(index, index + 1)}
                          className={
                            (index >= get(values, fieldName, []).length - 1
                              ? "text-gray-200 cursor-default "
                              : "text-gray-500 hover:bg-gray-50  ") +
                            "relative inline-flex items-center px-2 py-2 -ml-px text-sm font-medium bg-white border border-gray-300 rounded-r-md  focus:z-10 focus:outline-none focus:ring-1 focus:ring-cornflower-500 focus:border-cornflower-500"
                          }
                        >
                          <span className="sr-only">Down</span>
                          <ArrowSmDownIcon
                            className="w-5 h-5"
                            aria-hidden="true"
                          />
                        </button>
                      </span>

                      <span className="relative z-0 inline-flex rounded-md shadow-sm">
                        <button
                          type="button"
                          onClick={() => arrayHelpers.remove(index)}
                          className="relative inline-flex items-center px-2 py-2 text-sm font-medium text-gray-500 bg-white border border-gray-300 rounded-l-md hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-cornflower-500 focus:border-cornflower-500"
                        >
                          <span className="sr-only">Remove</span>
                          <MinusSmIcon className="w-5 h-5" aria-hidden="true" />
                        </button>
                        <button
                          type="button"
                          disabled={ingredient?.length === 0}
                          onClick={() => arrayHelpers.insert(index, "")}
                          className="relative inline-flex items-center px-2 py-2 -ml-px text-sm font-medium text-gray-500 bg-white border border-gray-300 rounded-r-md hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-cornflower-500 focus:border-cornflower-500"
                        >
                          <span className="sr-only">Add</span>
                          <PlusSmIcon className="w-5 h-5" aria-hidden="true" />
                        </button>
                      </span>
                    </div>
                  </div>
                </>
              ))
            ) : (
              <button
                type="button"
                onClick={() => arrayHelpers.push("")}
                key={`${fieldName}.btn`}
                className="inline-flex items-center px-4 py-2 text-sm font-medium border border-transparent rounded-md text-cornflower-700 bg-cornflower-100 hover:bg-cornflower-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cornflower-500"
              >
                Add meal
              </button>
            )}
          </div>
        );
      }}
    </FieldArray>
  );
}

export default MealsArrayField;
