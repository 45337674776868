import React from "react";
import { ErrorMessage, Field, useField } from "formik";

function CheckboxField({ label, description, requiredErrorMsg, ...props }) {
  const [field, meta, helpers] = useField(props);
  return (
    <>
      <div className="flex items-start">
        <div className="flex items-center h-5">
          <Field
            type="checkbox"
            name={field.name}
            id={field.name}
            className="w-4 h-4 border-gray-300 rounded text-cornflower-600 focus:ring-cornflower-500"
          />
        </div>
        <div className="ml-3 text-sm">
          <label htmlFor={field.name} className="font-medium text-gray-700">
            {label}
          </label>
          <p className="text-gray-500">
            {description}
          </p>
        </div>
      </div>
    </>
  );
}

export default CheckboxField;
