import React from "react";

function PageHeader({ title, subtitle, description }) {
  return (
    <header className="mx-auto max-w-7xl p-4 sm:p-6 lg:p-8">
      <h2 className="text-4xl font-extralight capitalize leading-8 text-gray-900 sm:text-5xl sm:tracking-tight lg:text-6xl">
        <span className="block">{title}</span>
        <span className="mt-3 block font-script text-3xl font-normal lg:text-4xl">
          {subtitle}
        </span>
      </h2>
      <p className="mt-3 max-w-xl text-base text-gray-500">{description}</p>
    </header>
  );
}

export default PageHeader;
