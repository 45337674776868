import signInModal from "./SignInModal";
import loadingModal from "./LoadingModal";
import lockedModal from "./LockedModal";
import confirmLoadMealPlanModal from "./ConfirmLoadMealPlan";

const modalTypes = {
  signInModal,
  loadingModal,
  lockedModal,
  confirmLoadMealPlanModal,
};

export default modalTypes;
