import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  addToShoppingList,
  removeFromShoppingList,
} from "../../store/actions/userActions";
import styled from "styled-components";
import { PlusIcon, MinusCircleIcon } from "@heroicons/react/solid";
import { getShoppingList } from "../../store/selectors/selectors";

const Container = styled.div`
  display: grid;
  grid-template-columns: 70px 1fr 20px;
  gap: 15px 10px;
  justify-content: stretch;

  @media only screen and (min-width: 768px) {
    grid-template-columns: 90px 1fr 20px;
  }
`;

const Ingredient = ({ id, recipeId, ingredient }) => {
  const { amount, unit, name, altAmount, altUnit, preparation } = ingredient;

  const shoppingList = useSelector(getShoppingList).filter(
    (i) => i.recipeId === recipeId
  );
  const [isOnShoppingList, setIsOnShoppingList] = useState(
    shoppingList?.some((i) => i.id === id)
  );

  useEffect(() => {
    setIsOnShoppingList(shoppingList?.some((i) => i.id === id));
  }, [id, shoppingList]);

  const dispatch = useDispatch();
  const handleClick = (e) => {
    e.preventDefault();
    var newValue = !isOnShoppingList;
    setIsOnShoppingList(newValue);
    if (newValue) {
      dispatch(addToShoppingList(recipeId, { ...ingredient, id }));
    } else {
      dispatch(removeFromShoppingList(recipeId, ingredient.id));
    }
  };

  return (
    <li
      onClick={(e) => handleClick(e)}
      className="px-4 py-2 cursor-pointer hover:bg-gray-50"
    >
      <Container>
        <div className="flex flex-col">
          <p className="text-sm font-medium text-gray-900 truncate">
            {amount} {unit}
          </p>
          {altAmount ? (
            <p className="text-xs text-gray-400">
              {altAmount} {altUnit}
            </p>
          ) : null}
        </div>
        <div className="my-auto text-sm text-gray-500 capitalize">
          <span>{name}</span>
          {preparation && <span>, {preparation}</span>}
        </div>
        <div className="flex items-center justify-center text-red-400 hover:text-red-500">
          {isOnShoppingList ? (
            <MinusCircleIcon className="flex-shrink-0 w-5 h-5 text-current" />
          ) : (
            <PlusIcon className="flex-shrink-0 w-5 h-5 text-current" />
          )}
        </div>
      </Container>
    </li>
  );
};

export default Ingredient;
