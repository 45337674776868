import React, { useState } from "react";
import Autosuggest from "react-autosuggest";
import _ from "lodash-es";

const renderSuggestionsContainer = ({ containerProps, children }) => {
  containerProps.className = "rounded-md shadow-lg";
  return <ul {...containerProps}>{children}</ul>;
};

const renderSuggestion = (suggestion, { _query, isHighlighted }) => {
  const className =
    (isHighlighted
      ? "text-gray-900 font-medium bg-gray-50"
      : "text-gray-500 font-normal") +
    "relative bg-white px-6 py-1 text-sm text-gray-500 capitalize cursor-pointer";
  return (
    <li className={className} key={suggestion.id}>
      {suggestion.text}
    </li>
  );
};

const renderSectionTitle = (section) => {
  return section.suggestions.length ? (
    <div className="px-6 py-1 text-xs font-semibold text-gray-500 uppercase border-t border-b border-gray-200 bg-gray-50">
      {section.title}
    </div>
  ) : null;
};

// When suggestion is clicked, Autosuggest needs to populate the input
// based on the clicked suggestion. Teach Autosuggest how to calculate the
// input value for every given suggestion.
const getSuggestionValue = (suggestion) => {
  return suggestion.text;
};

const getSectionSuggestions = (section) => {
  return section.suggestions;
};

const FormAutosuggestStyled = ({ options, placeholder, ...props }) => {
  const [suggestions, setSuggestions] = useState([]);

  const getChoices = () => {
    return options.choices;
  };

  // Teach Autosuggest how to calculate suggestions for any given input value.
  const getSuggestions = (value = "") => {
    const inputValue = _.isString(value) ? _.trim(value.toLowerCase()) : "";
    const inputLength = inputValue.length;

    // let filtrate = _.filterDeep(
    //   this.getChoices(),
    //   (value, key) => {
    //     console.log(key, value);
    //     return value && value.text.toLowerCase().slice(0, inputLength) === inputValue;
    //   },
    //   { childrenPath: "suggestions" }
    // );

    const options =
      inputLength === 0
        ? []
        : _.map(getChoices(), (c) => {
            return {
              title: c.title,
              suggestions: _.filter(
                c.suggestions,
                (suggestion) =>
                  suggestion.text.toLowerCase().slice(0, inputLength) ===
                  inputValue
              ),
            };
          });

    // console.log("filtrate: ", filtrate);
    // console.log("options: ", options);
    return options;
  };

  const onChange = (event, { newValue }) => props.onChange(newValue);
  const onBlur = (event, { highlightedSuggestion }) =>
    props.field.onBlur(props.field.name);

  // Autosuggest will call this function every time you need to update suggestions.
  const onSuggestionsFetchRequested = ({ value = "" }) => {
    setSuggestions(getSuggestions(value));
  };

  // Autosuggest will call this function every time you need to clear suggestions.
  const onSuggestionsClearRequested = () => setSuggestions([]);

  const inputProps = {
    placeholder,
    onChange,
    onBlur,
    value: _.isString(props.value) ? props.value : "",
    className:
      "border-gray-300 block w-full mt-1 rounded-md shadow-sm focus:ring-cornflower-500 focus:border-cornflower-500 sm:text-sm",
  };

  return (
    <Autosuggest
      suggestions={suggestions}
      onSuggestionsFetchRequested={onSuggestionsFetchRequested}
      onSuggestionsClearRequested={onSuggestionsClearRequested}
      getSectionSuggestions={getSectionSuggestions}
      getSuggestionValue={getSuggestionValue}
      renderSuggestionsContainer={renderSuggestionsContainer}
      renderSectionTitle={renderSectionTitle}
      renderSuggestion={renderSuggestion}
      inputProps={inputProps}
      multiSection={true}
      highlightFirstSuggestion={true}
      {...options}
    />
  );
};

export default FormAutosuggestStyled;
