import React from "react";
import MealPlanGridItem from "./MealPlanGridItem";

function MealPlanGrid({ mealPlans }) {
  return (
    <ul className="grid grid-cols-2 gap-x-4 gap-y-8 sm:grid-cols-3 sm:gap-x-6 lg:grid-cols-4 2xl:grid-cols-6 xl:gap-x-8">
      {mealPlans.map((mp, ix) => (
        <MealPlanGridItem {...mp} key={mp.id + "-" + ix} />
      ))}
    </ul>
  );
}

export default MealPlanGrid;
