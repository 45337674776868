import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import * as ROUTES from "../../constants/routes";
import {
  getCreatorEva,
  getLatestRecipes,
} from "../../store/selectors/selectors";
import { useFirestoreConnect } from "react-redux-firebase";
import { ExternalLinkIcon, ViewGridIcon } from "@heroicons/react/solid";
import RecipeGrid from "../recipes/RecipeGrid";
import { v4 as uuidv4 } from "uuid";
import FooterSocial from "../layout/FooterSocials";
import YoutubeVideoGrid from "../videos/YoutubeVideoGrid";

const Home = () => {
  useFirestoreConnect(() => [
    {
      collection: "recipes",
      orderBy: [["createdAt", "desc"]],
      storeAs: "recipes",
    },
    {
      collection: "creators",
      storeAs: "creators",
    },
  ]);

  const latestRecipes = useSelector(getLatestRecipes);
  const creator = useSelector(getCreatorEva);

  return (
    <>
      <motion.div exit={{ opacity: 0 }} className="relative bg-white">
        <section>
          <div className="relative overflow-hidden bg-white">
            <div className="pt-0 pb-64 sm:pt-8 sm:pb-24 lg:pt-24 lg:pb-32">
              <div className="relative mx-auto max-w-7xl px-4 sm:static sm:px-6 lg:px-8">
                <div className="sm:max-w-lg">
                  <div className="flex flex-shrink-0">
                    <img
                      className="h-32 w-32"
                      src="/logo_deliish.svg"
                      alt="Deliish"
                    />
                  </div>
                  <h1 className="text-4xl font-extralight capitalize leading-8 text-gray-900 sm:text-5xl sm:tracking-tight lg:text-6xl">
                    <span>Eating Plants Can Be </span>
                    <span className="font-script font-normal">Deliish</span>
                  </h1>
                  <p className="mt-3 text-xl uppercase text-gray-700 sm:mt-4">
                    Deliish Nutrition created by certified nutritionist Eva is a
                    place for your health!
                  </p>
                  <div className="prose-cornflower prose mx-auto mt-5 text-gray-500">
                    <p>
                      You will find nutrition optimized whole food recipes,
                      personal coaching and free tools such as meal planner
                      &amp; grocery list to get you started today.
                    </p>
                  </div>
                </div>
                <div>
                  <div className="mt-10">
                    {/* Decorative image grid */}
                    <div
                      aria-hidden="true"
                      className="pointer-events-none lg:absolute lg:inset-y-0 lg:mx-auto lg:w-full lg:max-w-7xl"
                    >
                      <div className="absolute transform sm:left-1/2 sm:top-0 sm:translate-x-8 lg:left-1/2 lg:top-1/2 lg:-translate-y-1/2 lg:translate-x-8">
                        <div className="flex items-center space-x-6 lg:space-x-8">
                          <div className="grid flex-shrink-0 grid-cols-1 gap-y-6 lg:gap-y-8">
                            <div className="h-64 w-44 overflow-hidden rounded-lg sm:opacity-0 lg:opacity-100">
                              <img
                                src="/assets/img/352x512/header-2.jpeg"
                                alt=""
                                className="h-full w-full object-cover object-center"
                              />
                            </div>
                            <div className="h-64 w-44 overflow-hidden rounded-lg">
                              <img
                                src="/assets/img/352x512/header-1.jpeg"
                                alt=""
                                className="h-full w-full object-cover object-center"
                              />
                            </div>
                          </div>
                          <div className="grid flex-shrink-0 grid-cols-1 gap-y-6 lg:gap-y-8">
                            <div className="h-64 w-44 overflow-hidden rounded-lg">
                              <img
                                src="/assets/img/352x512/header-3.jpeg"
                                alt=""
                                className="h-full w-full object-cover object-center"
                              />
                            </div>
                            <div className="h-64 w-44 overflow-hidden rounded-lg">
                              <img
                                src="/assets/img/352x512/header-4.jpeg"
                                alt=""
                                className="h-full w-full object-cover object-center"
                              />
                            </div>
                            <div className="h-64 w-44 overflow-hidden rounded-lg">
                              <img
                                src="/assets/img/352x512/header-5.jpeg"
                                alt=""
                                className="h-full w-full object-cover object-center"
                              />
                            </div>
                          </div>
                          <div className="grid flex-shrink-0 grid-cols-1 gap-y-6 lg:gap-y-8">
                            <div className="h-64 w-44 overflow-hidden rounded-lg">
                              <img
                                src="/assets/img/352x512/header-7.jpeg"
                                alt=""
                                className="h-full w-full object-cover object-center"
                              />
                            </div>
                            <div className="h-64 w-44 overflow-hidden rounded-lg">
                              <img
                                src="/assets/img/352x512/header-6.jpeg"
                                alt=""
                                className="h-full w-full object-cover object-center"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* <a
                      href="#"
                      className="inline-block rounded-md border border-transparent bg-indigo-600 py-3 px-8 text-center font-medium text-white hover:bg-indigo-700"
                    >
                      Shop Collection
                    </a> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* <div className="relative bg-gray-800">
          <div className="h-56 bg-cornflower-600 sm:h-72 md:absolute md:left-0 md:h-full md:w-1/2">
            <img
              className="h-full w-full object-cover object-bottom"
              src="https://images.unsplash.com/photo-1617201835243-d7d48c53e3f6?ixid=MnwyMjgyNTd8MHwxfGFsbHx8fHx8fHx8fDE2MjAwODY2MDU&ixlib=rb-1.2.1&auto=format&fit=crop&w=1920&q=60"
              alt=""
            />
          </div>
          <div className="relative mx-auto max-w-7xl px-4 py-12 sm:px-6 lg:px-8 lg:py-16">
            <div className="md:ml-auto md:w-1/2 md:pl-10">
              <h2 className="text-base font-semibold uppercase tracking-wider text-gray-300">
                Join our
              </h2>
              <p className="mt-2 text-3xl font-extrabold tracking-tight text-white sm:text-4xl">
                PLANT REBOOT PROGRAM
              </p>
              <p className="mt-3 text-lg text-gray-300">
                A compact 4-week online program, teaching you the basics of a
                whole food plant-based lifestyle. 5 years of experience packaged
                in an easy and fun course with a growing and loving community.
              </p>
              <div className="mt-8">
                <div className="inline-flex rounded-md shadow">
                  <Link
                    to={ROUTES.COURSES_REBOOT}
                    className="inline-flex items-center justify-center rounded-md border border-transparent bg-white px-5 py-3 text-base font-medium text-gray-900 hover:bg-gray-50"
                  >
                    Visit course page
                    <ExternalLinkIcon
                      className="ml-3 -mr-1 h-5 w-5 text-gray-400"
                      aria-hidden="true"
                    />
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div> */}

        <div className="relative overflow-hidden bg-white p-4 pt-8 sm:p-6 sm:pt-12 lg:p-8 lg:pt-16">
          <div className="pb-5 xs:flex xs:items-center xs:justify-between">
            <h1 className="text-3xl font-extrabold capitalize text-gray-900">
              Latest recipes
            </h1>
            <div className="mt-3 xs:mt-0 xs:ml-4">
              <Link
                to={ROUTES.BROWSE}
                className="inline-flex w-full items-center justify-center rounded-md border border-transparent bg-red-50 px-4 py-2 text-base font-medium text-red-400 hover:bg-red-100 hover:text-red-500 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 xs:w-auto xs:justify-start"
              >
                <ViewGridIcon
                  className="mr-3 -ml-1 h-5 w-5"
                  aria-hidden="true"
                />
                See all
              </Link>
            </div>
          </div>

          <RecipeGrid key={uuidv4()} recipes={latestRecipes} />
        </div>

        {creator && creator.youtubeChannelInfo && (
          <div className="relative overflow-hidden bg-white p-4 sm:p-6 lg:p-8">
            <div className="pb-5 xs:flex xs:items-center xs:justify-between">
              <h1 className="text-3xl font-extrabold capitalize text-gray-900">
                Latest videos
              </h1>
              <div className="mt-3 xs:mt-0 xs:ml-4">
                <a
                  href={
                    "https://www.youtube.com/channel/" +
                    creator.youtubeChannelInfo.id
                  }
                  target="_blank"
                  rel="noreferrer"
                  className="inline-flex w-full items-center justify-center rounded-md border border-transparent bg-red-50 px-4 py-2 text-base font-medium text-red-400 hover:bg-red-100 hover:text-red-500 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 xs:w-auto xs:justify-start"
                >
                  <ExternalLinkIcon
                    className="mr-3 -ml-1 h-5 w-5"
                    aria-hidden="true"
                  />
                  See all
                </a>
              </div>
            </div>

            <YoutubeVideoGrid
              videos={creator.youtubeChannelInfo.recentVideos}
            />
          </div>
        )}

        <FooterSocial />
      </motion.div>
    </>

    // <RootElement
    //   key="home-container"
    //   initial={{ opacity: 0 }}
    //   animate={{ opacity: 1, transition: { delay: 0.1 } }}
    //   exit={{ opacity: 0 }}
    // >
    //   <Heading title="Recipes" to={ROUTES.BROWSE} linkText="See all" />

    //   <div className="mt-5">
    //     <FeaturedRecipesList featuredRecipes={featuredRecipesData} />
    //   </div>

    //   <div className="mt-5">
    //     <RebootBannerCard />
    //   </div>

    // </RootElement>
  );
};

export default Home;
