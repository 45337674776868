import { createGlobalStyle } from "styled-components";

export const GlobalStyles = createGlobalStyle`

  html, body {
        scroll-behavior: smooth;
        height: -webkit-fill-available;
        -webkit-text-size-adjust: 100%;
        
    }

    *, *::after, *::before {
        box-sizing: border-box;
    }

    ::-webkit-scrollbar {
        display: none;
    }

    body {
        -webkit-overflow-scrolling: touch;
        min-height: -webkit-fill-available;
        font-family: 'Poppins', sans-serif;
    }

  `;
