import React, { useState } from "react";
import axios from "axios";

function SubscribeForm() {
  const url = "https://api.convertkit.com/v3/forms/5481849/subscribe";

  const [firstName, setFirstName] = useState("");
  const [email, setEmail] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault()

    await axios.post(url, {
      "api_key": "l4qY2T8_fv4ihdtHL7d0Yg",
      "email": email,
      "first_name": firstName,
      "tags": [4041351]
    });
  };

  return (
    <form
      className="sm:flex"
      action={url}
      method="post"
      id="mc-embedded-subscribe-form"
      name="mc-embedded-subscribe-form"
      target="_blank"
      onSubmit={handleSubmit}
    >
      <label htmlFor="first-name" className="sr-only">
        First Name
      </label>
      <input
        id="first-name"
        name="FNAME"
        type="text"
        required
        className="w-full rounded-md border-gray-300 px-5 py-3 placeholder-gray-500 focus:border-cornflower-500 focus:ring-cornflower-500"
        placeholder="First Name"
        onChange={(e) => setFirstName(e.target.value)}
      />

      <label htmlFor="email-address" className="sr-only">
        Email address
      </label>
      <input
        id="email-address"
        name="EMAIL"
        type="email"
        autoComplete="email"
        required
        className="mt-3 w-full rounded-md border-gray-300 px-5 py-3 placeholder-gray-500 focus:border-cornflower-500 focus:ring-cornflower-500 sm:mt-0 sm:ml-3"
        placeholder="Email"
        onChange={(e) => setEmail(e.target.value)}
      />
      {/* real people should not fill this in and expect good things - do not remove this or risk form bot signups*/}
      <div style={{ position: "absolute", left: "-5000px" }} aria-hidden="true">
        <input
          type="text"
          name="b_063f1fea1142619ce79da9fc5_74bcfd37d0"
          tabIndex={-1}
          defaultValue
        />
      </div>
      <button
        type="submit"
        className="mt-3 flex w-full items-center justify-center rounded-md border border-transparent bg-cornflower-600 px-5 py-3 text-base font-medium text-white hover:bg-cornflower-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-cornflower-500 sm:mt-0 sm:ml-3 sm:w-auto sm:flex-shrink-0"
        id="mc-embedded-subscribe"
      >
        Register
      </button>
    </form>
  );
}

export default SubscribeForm;
