export const formModel = () => {
  return {
    formId: "shoppingListForm",
    formField: {
      name: {
        name: "name",
        label: "Name",
        placeholder: "Coffee, tea,..",
      },
      category: {
        name: "category",
        label: "Category/Department",
        options: [
          { name: "Other", value: "other" },
          { name: "Produce", value: "produce" },
          { name: "Legumes", value: "legumes" },
          { name: "Canned Goods", value: "canned goods" },
          { name: "Pasta & Grains", value: "pasta & grains" },
          { name: "Baking & Spices", value: "baking & spices" },
          { name: "Oils & Vinegars", value: "oils & vinegars" },
          { name: "Nuts & Seeds", value: "nuts & seeds" },
          {
            name: "Sauces & Condiments",
            value: "sauces & condiments",
          },
          { name: "Frozen Goods", value: "frozen goods" },
          { name: "Vegan Dairy", value: "vegan dairy" },
          { name: "Vegan Protein", value: "vegan protein" },
        ],
      },
    },
  };
};
