import React from "react";
import Ingredient from "./Ingredient";

const IngredientList = ({ id, ingredientGroup, recipeId }) => {
  return (
    <div className="mt-3">
      {ingredientGroup.title !== "" ? (
        <p className="text-sm font-semibold text-left text-gray-400 uppercase leading-6">{ingredientGroup.title}</p>
      ) : null}
      <ul className="divide-y divide-gray-200">
        {ingredientGroup.ingredients.map((ingredient, index) => {
          return (
            <Ingredient
              key={index}
              id={`${id}-${index}`}
              ingredient={ingredient}
              recipeId={recipeId}
            />
          );
        })}
      </ul>
    </div>
  );
};

export default IngredientList;
