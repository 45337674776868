import React from "react";
import { motion } from "framer-motion";
import FooterSocial from "../layout/FooterSocials";

const TermsOfService = () => {
  return (
    <motion.div exit={{ opacity: 0 }} className="relative bg-white">
      <div className="bg-white">
        <div className="mx-auto max-w-7xl p-4 sm:py-16 sm:px-6 lg:px-8">
          <div className="text-center">
            <p className="mt-1 text-4xl font-extrabold text-gray-900 sm:text-5xl sm:tracking-tight lg:text-6xl">
              Terms &amp; Conditions
            </p>
            <p className="mx-auto mt-5 max-w-xl text-xl text-gray-500">
              Effective date: May 1, 2020
            </p>
          </div>
        </div>
      </div>
      <div>
        <div className="relative overflow-hidden bg-white pb-16">
          <div className="hidden lg:absolute lg:inset-y-0 lg:block lg:h-full lg:w-full">
            <div
              className="relative mx-auto h-full max-w-prose text-lg"
              aria-hidden="true"
            >
              <svg
                className="absolute top-12 left-full translate-x-32 transform"
                width={404}
                height={384}
                fill="none"
                viewBox="0 0 404 384"
              >
                <defs>
                  <pattern
                    id="74b3fd99-0a6f-4271-bef2-e80eeafdf357"
                    x={0}
                    y={0}
                    width={20}
                    height={20}
                    patternUnits="userSpaceOnUse"
                  >
                    <rect
                      x={0}
                      y={0}
                      width={4}
                      height={4}
                      className="text-gray-200"
                      fill="currentColor"
                    />
                  </pattern>
                </defs>
                <rect
                  width={404}
                  height={384}
                  fill="url(#74b3fd99-0a6f-4271-bef2-e80eeafdf357)"
                />
              </svg>
              <svg
                className="absolute top-1/2 right-full -translate-x-32 -translate-y-1/2 transform"
                width={404}
                height={384}
                fill="none"
                viewBox="0 0 404 384"
              >
                <defs>
                  <pattern
                    id="f210dbf6-a58d-4871-961e-36d5016a0f49"
                    x={0}
                    y={0}
                    width={20}
                    height={20}
                    patternUnits="userSpaceOnUse"
                  >
                    <rect
                      x={0}
                      y={0}
                      width={4}
                      height={4}
                      className="text-gray-200"
                      fill="currentColor"
                    />
                  </pattern>
                </defs>
                <rect
                  width={404}
                  height={384}
                  fill="url(#f210dbf6-a58d-4871-961e-36d5016a0f49)"
                />
              </svg>
              <svg
                className="absolute bottom-12 left-full translate-x-32 transform"
                width={404}
                height={384}
                fill="none"
                viewBox="0 0 404 384"
              >
                <defs>
                  <pattern
                    id="d3eb07ae-5182-43e6-857d-35c643af9034"
                    x={0}
                    y={0}
                    width={20}
                    height={20}
                    patternUnits="userSpaceOnUse"
                  >
                    <rect
                      x={0}
                      y={0}
                      width={4}
                      height={4}
                      className="text-gray-200"
                      fill="currentColor"
                    />
                  </pattern>
                </defs>
                <rect
                  width={404}
                  height={384}
                  fill="url(#d3eb07ae-5182-43e6-857d-35c643af9034)"
                />
              </svg>
            </div>
          </div>
          <div className="relative px-4 sm:px-6 lg:px-8">
            <div className="prose mx-auto max-w-prose text-lg">
              <div id="terms">
                <h3>1. Terms</h3>
                <p>
                  By accessing our app, DELIISH, you are agreeing to be bound by
                  these terms of service, all applicable laws and regulations,
                  and agree that you are responsible for compliance with any
                  applicable local laws. If you do not agree with any of these
                  terms, you are prohibited from using or accessing DELIISH. The
                  materials contained in DELIISH are protected by applicable
                  copyright and trademark law.
                </p>
              </div>
              <div id="use-license">
                <h3>2. Use License</h3>
                <ol type="a">
                  <li>
                    Permission is granted to temporarily download one copy of
                    DELIISH per device for personal, non-commercial transitory
                    viewing only. This is the grant of a license, not a transfer
                    of title, and under this license you may not:
                    <ol type="i">
                      <li>modify or copy the materials;</li>
                      <li>
                        use the materials for any commercial purpose, or for any
                        public display (commercial or non-commercial);
                      </li>
                      <li>
                        attempt to decompile or reverse engineer any software
                        contained in DELIISH;
                      </li>
                      <li>
                        remove any copyright or other proprietary notations from
                        the materials; or
                      </li>
                      <li>
                        transfer the materials to another person or "mirror" the
                        materials on any other server.
                      </li>
                    </ol>
                  </li>
                  <li>
                    This license shall automatically terminate if you violate
                    any of these restrictions and may be terminated by The
                    Hooray Media LTD at any time. Upon terminating your viewing
                    of these materials or upon the termination of this license,
                    you must destroy any downloaded materials in your possession
                    whether in electronic or printed format.
                  </li>
                </ol>
              </div>
              <div id="disclaimer">
                <h3>3. Disclaimer</h3>
                <ol type="a">
                  <li>
                    The materials within DELIISH are provided on an 'as is'
                    basis. The Hooray Media LTD makes no warranties, expressed
                    or implied, and hereby disclaims and negates all other
                    warranties including, without limitation, implied warranties
                    or conditions of merchantability, fitness for a particular
                    purpose, or non-infringement of intellectual property or
                    other violation of rights.
                  </li>
                  <li>
                    Further, The Hooray Media LTD does not warrant or make any
                    representations concerning the accuracy, likely results, or
                    reliability of the use of the materials on its website or
                    otherwise relating to such materials or on any sites linked
                    to DELIISH.
                  </li>
                </ol>
              </div>
              <div id="limitations">
                <h3>4. Limitations</h3>
                <p>
                  In no event shall The Hooray Media LTD or its suppliers be
                  liable for any damages (including, without limitation, damages
                  for loss of data or profit, or due to business interruption)
                  arising out of the use or inability to use DELIISH, even if
                  The Hooray Media LTD or a The Hooray Media LTD authorized
                  representative has been notified orally or in writing of the
                  possibility of such damage. Because some jurisdictions do not
                  allow limitations on implied warranties, or limitations of
                  liability for consequential or incidental damages, these
                  limitations may not apply to you.
                </p>
              </div>
              <div id="accuracy-of-materials">
                <h3>5. Accuracy of materials</h3>
                <p>
                  The materials appearing in DELIISH could include technical,
                  typographical, or photographic errors. The Hooray Media LTD
                  does not warrant that any of the materials on DELIISH are
                  accurate, complete or current. The Hooray Media LTD may make
                  changes to the materials contained in DELIISH at any time
                  without notice. However The Hooray Media LTD does not make any
                  commitment to update the materials.
                </p>
              </div>
              <div id="links">
                <h3>6. Links</h3>
                <p>
                  The Hooray Media LTD has not reviewed all of the sites linked
                  to its app and is not responsible for the contents of any such
                  linked site. The inclusion of any link does not imply
                  endorsement by The Hooray Media LTD of the site. Use of any
                  such linked website is at the user's own risk.
                </p>
              </div>
              <div id="affiliate-links">
                <h3>7. Affiliate Links Disclosure</h3>
                <p>
                  Various hyperlinks on the Site direct the user to a Third
                  Party website. We may receive compensation for this.
                  Specifically, as an Amazon Associate we earn from qualifying
                  purchases, but the compensation will never influence our
                  content.
                </p>
              </div>

              <div id="modifications">
                <h3>8. Modifications</h3>
                <p>
                  The Hooray Media LTD may revise these terms of service for its
                  app at any time without notice. By using DELIISH you are
                  agreeing to be bound by the then current version of these
                  terms of service.
                </p>
              </div>

              <div id="governing-law">
                <h3>9. Governing Law</h3>
                <p>
                  These terms and conditions are governed by and construed in
                  accordance with the laws of Cyprus and you irrevocably submit
                  to the exclusive jurisdiction of the courts in that State or
                  location.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <FooterSocial />
    </motion.div>
  );
};

export default TermsOfService;
