import React, { useEffect, useRef } from "react";
import { Link, useParams } from "react-router-dom";
import { Formik, Form } from "formik";
import * as ROUTES from "../../constants/routes";
import { motion } from "framer-motion";
import BasicForm from "../forms/recipe/BasicForm";
import { formModel } from "../forms/recipe/models/formModel";
import validationSchema from "../forms/recipe/models/validationSchema";
import { formInitialValues } from "../forms/recipe/models/formInitialValues";
import SubmitForm from "../forms/recipe/SubmitForm";
import { useState } from "react";
import { CheckIcon } from "@heroicons/react/outline";
import MetaForm from "../forms/recipe/MetaForm";
import { isEmpty, useFirestoreConnect } from "react-redux-firebase";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllRecipes,
  getCategories,
  getIngredients,
  getTags,
  isCreateRecipePending,
} from "../../store/selectors/selectors";
import IngredientsForm from "../forms/recipe/IngredientsForm";
import ReferencesForm from "../forms/recipe/ReferencesForm";
import { hideModal, showModal } from "../../store/actions/modalActions";
import Loader from "../loaders/Loader";
import {
  createRecipe,
  finishCreateRecipe,
  initCreateRecipe,
  updateRecipe,
  updateRecipeIngredients,
  updateRecipeTags,
} from "../../store/actions/recipeActions";
import { differenceBy } from "lodash";
import { onlyUnique } from "../utils/Utils";

const steps = [
  { name: "Step 1" },
  { name: "Step 2" },
  { name: "Step 3" },
  { name: "Step 4" },
  { name: "Step 5" },
];

function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

function RecipeFormWizard() {
  useFirestoreConnect(() => [
    {
      collection: "categories",
      orderBy: [["sort"]],
      storeAs: "categories",
    },
    {
      collection: "ingredients",
      storeAs: "ingredients",
    },
    {
      collection: "tags",
      orderBy: [["createdAt"]],
      storeAs: "tags",
    },
    {
      collection: "recipes",
      orderBy: [["createdAt", "desc"]],
      storeAs: "recipes",
    },
  ]);
  let { id } = useParams();

  const categories = useSelector(getCategories);
  const ingredientList = useSelector(getIngredients);
  const tagList = useSelector(getTags);
  const recipes = useSelector(getAllRecipes);
  const recipe = useSelector(
    ({ firestore: { data } }) => data.recipes && data.recipes[id]
  );

  const dispatch = useDispatch();

  const [activeStep, setActiveStep] = useState(0);
  const currentValidationSchema = validationSchema[activeStep];
  const isLastStep = activeStep === steps.length - 1;
  const { formId, formField } = formModel(categories);

  //#region State of recipe processing
  const isPending = useSelector(isCreateRecipePending);
  const prevIsPending = usePrevious(isPending);
  const recipeState = useSelector((state) => state.recipe);
  const prevRecipeState = usePrevious(recipeState);

  if (prevIsPending === false && isPending === true) {
    dispatch(
      showModal(
        {
          open: true,
          title: "Processing...",
          message: "Please wait while we process your request.",
        },
        "loading"
      )
    );
  } else if (prevIsPending === true && isPending === false) {
    dispatch(hideModal());
  }

  // if (recipeState.finished === true && prevRecipeState.finished === false) {
  //   if (recipeState.error === null) {
  //     setActiveStep(activeStep + 1);
  //     // toastr.success(
  //     //   "SUCCESS",
  //     //   `Created recipe with id ${recipeState.recipeId}`
  //     // );
  //   } else {
  //     // toastr.error("ERROR", recipeState.error);
  //   }
  // }

  useEffect(() => {
    if (recipeState.finished === true && recipeState.error === null) {
      setActiveStep(activeStep + 1);
      dispatch(finishCreateRecipe());
    }
  }, [recipeState, activeStep, dispatch]);
  //#endregion

  function _renderStepContent(step) {
    switch (step) {
      case 0:
        return <BasicForm formField={formField} />;
      case 1:
        return <MetaForm formField={formField} />;
      case 2:
        return (
          <IngredientsForm formField={formField} ingredients={ingredientList} />
        );
      case 3:
        return <ReferencesForm formField={formField} recipes={recipes} />;
      case 4:
        return <SubmitForm formField={formField} />;
      default:
        return <div>Not Found</div>;
    }
  }

  async function _submitForm(values, actions) {
    dispatch(initCreateRecipe())
      .then(() => {
        recipe
          ? dispatch(updateRecipe(id, values))
          : dispatch(createRecipe(values));
      })
      .then(() => {
        const ingredients = [];

        for (var key in values.data.ingredients.groups) {
          // skip loop if the property is from prototype
          if (!values.data.ingredients.groups.hasOwnProperty(key)) continue;

          var obj = values.data.ingredients.groups[key]["ingredients"];
          var ingredientObj = obj.map(({ name, category }) => {
            return {
              name: name,
              category: category,
            };
          });
          for (var i in ingredientObj) {
            ingredients.push(ingredientObj[i]);
          }
        }
        const existingIngredients = ingredientList.map(({ name, category }) => {
          return {
            name: name,
            category: category,
          };
        });
        var newIngredients = differenceBy(
          ingredients,
          existingIngredients,
          "name"
        );
        dispatch(updateRecipeIngredients(newIngredients));
      })
      .then(() => {
        const tags = values.data.meta.tags;
        const existingTagNames = tagList.map(({ name }) => name);
        var newTagNames = tags.filter((tag) => {
          return !existingTagNames.includes(tag);
        });
        dispatch(updateRecipeTags(newTagNames.filter(onlyUnique)));
      })
      .catch((err) => {
        console.error(err);
      });

    actions.setSubmitting(false);
  }

  function _handleSubmit(values, actions) {
    if (isLastStep) {
      _submitForm(values, actions);
    } else {
      setActiveStep(activeStep + 1);
      actions.setTouched({});
      actions.setSubmitting(false);
    }
  }

  function _handleBack() {
    setActiveStep(activeStep - 1);
  }

  if (isEmpty(recipes)) {
    return <Loader />;
  }

  return (
    <motion.div
      exit={{ opacity: 0 }}
      className="py-4 min-h-screen-content bg-gray-50 sm:p-6 lg:p-8"
    >
      <h1 className="px-4 mb-4 text-3xl font-extrabold text-center text-gray-900 capitalize sm:text-left sm:px-0">
        Create recipe
      </h1>
      {activeStep < steps.length ? (
        <div className="mb-4">
          <nav
            className="flex items-center justify-center sm:justify-start"
            aria-label="Progress"
          >
            <p className="text-sm font-medium">
              Step {activeStep + 1} of {steps.length}
            </p>
            <ol className="flex items-center ml-8 space-x-5">
              {steps.map((step, ix) => (
                <li key={step.name}>
                  {ix < activeStep ? (
                    <button
                      disabled
                      onClick={() => setActiveStep(ix)}
                      className="block w-2.5 h-2.5 bg-cornflower-600 rounded-full hover:bg-cornflower-900"
                    >
                      <span className="sr-only">{step.name}</span>
                    </button>
                  ) : ix === activeStep ? (
                    <button
                      disabled
                      onClick={() => setActiveStep(ix)}
                      className="relative flex items-center justify-center"
                      aria-current="step"
                    >
                      <span
                        className="absolute flex w-5 h-5 p-px"
                        aria-hidden="true"
                      >
                        <span className="w-full h-full rounded-full bg-cornflower-200" />
                      </span>
                      <span
                        className="relative block w-2.5 h-2.5 bg-cornflower-600 rounded-full"
                        aria-hidden="true"
                      />
                      <span className="sr-only">{step.name}</span>
                    </button>
                  ) : (
                    <button
                      disabled
                      onClick={() => setActiveStep(ix)}
                      className="block w-2.5 h-2.5 bg-gray-200 rounded-full hover:bg-gray-400"
                    >
                      <span className="sr-only">{step.name}</span>
                    </button>
                  )}
                </li>
              ))}
            </ol>
          </nav>
        </div>
      ) : null}
      {/* <Prompt
        when={true}
        message={({ pathname }) => {
          return matchPath(pathname, { path: ROUTES.CREATE_RECIPE })
            ? true
            : "Are you sure you want to navigate away?";
        }}
      /> */}
      {activeStep === steps.length ? (
        <div className="flex items-end justify-center px-4 pt-4 pb-20 text-center sm:block sm:p-0">
          <div className="inline-block px-4 pt-5 pb-4 overflow-hidden text-left align-bottom transition-all transform bg-white rounded-lg shadow-xl sm:my-8 sm:align-middle sm:max-w-sm sm:w-full sm:p-6">
            <div>
              <div className="flex items-center justify-center w-12 h-12 mx-auto bg-green-100 rounded-full">
                <CheckIcon
                  className="w-6 h-6 text-green-600"
                  aria-hidden="true"
                />
              </div>
              <div className="mt-3 text-center sm:mt-5">
                <h3 className="text-lg font-medium leading-6 text-gray-900">
                  Recipe successfully {recipe ? "updated" : "created"}
                </h3>
                <div className="mt-2">
                  <p className="text-sm text-gray-500">
                    {recipeState?.recipe || prevRecipeState?.recipeId
                      ? "ID: " +
                        (recipeState?.recipe || prevRecipeState?.recipeId)
                      : null}
                  </p>
                </div>
              </div>
            </div>
            <div className="mt-5 sm:mt-6">
              <Link
                to={ROUTES.BROWSE}
                className="inline-flex justify-center w-full px-4 py-2 text-base font-medium text-white border border-transparent rounded-md shadow-sm bg-cornflower-600 hover:bg-cornflower-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cornflower-500 sm:text-sm"
              >
                Go back to all recipes
              </Link>
            </div>
          </div>
        </div>
      ) : (
        <Formik
          initialValues={formInitialValues(recipe)}
          validationSchema={currentValidationSchema}
          onSubmit={_handleSubmit}
        >
          {({ isSubmitting }) => (
            <Form id={formId}>
              <div className="space-y-6">
                {_renderStepContent(activeStep)}

                <div className="px-4 sm:px-0 sm:flex sm:flex-row-reverse">
                  <button
                    type="submit"
                    className="inline-flex justify-center w-full px-4 py-2 text-base font-medium text-white border border-transparent rounded-md shadow-sm bg-cornflower-600 hover:bg-cornflower-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cornflower-500 sm:ml-3 sm:w-auto sm:text-sm"
                    disabled={isSubmitting}
                  >
                    {isLastStep ? "Submit" : "Next"}
                  </button>

                  {activeStep !== 0 && (
                    <button
                      type="button"
                      className="inline-flex justify-center w-full px-4 py-2 mt-3 text-base font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cornflower-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                      onClick={_handleBack}
                    >
                      Back
                    </button>
                  )}
                </div>
              </div>
            </Form>
          )}
        </Formik>
      )}
    </motion.div>
  );
}

export default RecipeFormWizard;
