import React from "react";
import { Field, useField } from "formik";
import ImageUploadThumbnail from "../utils/ImageUploadThumbnail";

function ImageUploadField({ hint = "", label, ...props }) {
  const [field, meta, helpers] = useField(props);
  const { setValue } = helpers;

  return (
    <>
      <div>
        <div className="flex justify-between">
          <label
            htmlFor={field.name}
            className="block text-sm font-medium text-gray-700"
          >
            {label}
          </label>
          <span className="text-sm text-gray-500" id={field.name + "-hint"}>
            {hint}
          </span>
        </div>
        {field.value === "" ? (
          <div className="flex justify-center px-6 pt-5 pb-6 mt-1 border-2 border-gray-300 border-dashed rounded-md">
            <div className="space-y-1 text-center">
              <svg
                className="w-12 h-12 mx-auto text-gray-400"
                stroke="currentColor"
                fill="none"
                viewBox="0 0 48 48"
                aria-hidden="true"
              >
                <path
                  d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                  strokeWidth={2}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              <div className="flex text-sm text-gray-600">
                <label
                  htmlFor={field.name}
                  className="relative font-medium bg-white rounded-md cursor-pointer text-cornflower-600 hover:text-cornflower-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-cornflower-500"
                >
                  <span>Upload a file</span>
                  <Field
                    as="input"
                    name={field.name}
                    id={field.name}
                    value={undefined}
                    type="file"
                    accept="image/*"
                    className="sr-only"
                    onChange={(event) => setValue(event.target.files[0])}
                  />
                </label>
                <p className="pl-1">or drag and drop</p>
              </div>
              <p className="text-xs text-gray-500">JPG or PNG, up to 10MB</p>
            </div>
          </div>
        ) : (
          <div className="flex justify-between mt-1">
            <ImageUploadThumbnail file={field.value} />
            <div>
              <button
                type="button"
                onClick={() => setValue("")}
                className="inline-flex items-center px-4 py-2 text-sm font-medium border border-transparent rounded-md text-cornflower-700 bg-cornflower-100 hover:bg-cornflower-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cornflower-500"
              >
                Remove
              </button>
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default ImageUploadField;
