import React from "react";
import ArrayField from "../../formFields/ArrayField";
import CheckboxField from "../../formFields/CheckboxField";
import CheckboxGroupField from "../../formFields/CheckboxGroupField";
import SelectField from "../../formFields/SelectField";
import TextAreaField from "../../formFields/TextAreaField";
import TextField from "../../formFields/TextField";

function MetaForm(props) {
  
  const {
    formField: { data },
  } = props;

  return (
    <>
      <div className="px-4 py-5 bg-white shadow sm:rounded-lg sm:p-6">
        <div className="md:grid md:grid-cols-3 md:gap-6">
          <div className="md:col-span-1">
            <h3 className="text-lg font-medium leading-6 text-gray-900">
              YouTube Video
            </h3>
            <p className="mt-1 text-sm text-gray-500">
              Optionally link a YouTube video.
            </p>
          </div>

          <div className="mt-5 space-y-4 md:mt-0 md:col-span-2">
            <div>
              <TextField {...data.meta.videoUrl.url} />
            </div>
            <div>
              <TextAreaField {...data.meta.videoUrl.description} />
            </div>
          </div>
        </div>
      </div>

      <div className="px-4 py-5 bg-white shadow sm:rounded-lg sm:p-6">
        <div className="md:grid md:grid-cols-3 md:gap-6">
          <div className="md:col-span-1">
            <h3 className="text-lg font-medium leading-6 text-gray-900">
              Notes / Remarks / Additional Info
            </h3>
            <p className="mt-1 text-sm text-gray-500">
              Optionally add creator notes.
            </p>
          </div>

          <div className="mt-5 space-y-4 md:mt-0 md:col-span-2">
            <div>
              <TextField {...data.meta.notes.title} />
            </div>
            <div>
              <TextAreaField {...data.meta.notes.description} />
            </div>
          </div>
        </div>
      </div>

      <div className="px-4 py-5 bg-white shadow sm:rounded-lg sm:p-6">
        <div className="md:grid md:grid-cols-3 md:gap-6">
          <div className="md:col-span-1">
            <h3 className="text-lg font-medium leading-6 text-gray-900">
              Time*
            </h3>
            <p className="mt-1 text-sm text-gray-500">
              Define time needed for recipe.
            </p>
          </div>

          <div className="mt-5 space-y-4 md:mt-0 md:col-span-2">
            <div>
              <TextField {...data.meta.time.title} />
            </div>
            <div>
              <TextField {...data.meta.time.amount} />
            </div>
            <div>
              <SelectField {...data.meta.time.unit} />
            </div>
          </div>
        </div>
      </div>

      <div className="px-4 py-5 bg-white shadow sm:rounded-lg sm:p-6">
        <div className="md:grid md:grid-cols-3 md:gap-6">
          <div className="md:col-span-1">
            <h3 className="text-lg font-medium leading-6 text-gray-900">
              Visibility
            </h3>
            <p className="mt-1 text-sm text-gray-500">Set visibility.</p>
          </div>

          <div className="mt-5 space-y-4 md:mt-0 md:col-span-2">
            <div>
              <CheckboxField {...data.meta.visibility.isPublished} />
            </div>
            <div>
              <CheckboxField {...data.meta.visibility.isFree} />
            </div>
          </div>
        </div>
      </div>

      <div className="px-4 py-5 bg-white shadow sm:rounded-lg sm:p-6">
        <div className="md:grid md:grid-cols-3 md:gap-6">
          <div className="md:col-span-1">
            <h3 className="text-lg font-medium leading-6 text-gray-900">
              Categories
            </h3>
            <p className="mt-1 text-sm text-gray-500">Select categories.</p>
          </div>

          <div className="mt-5 space-y-4 md:mt-0 md:col-span-2">
            <div>
              <CheckboxGroupField {...data.meta.categories} />
            </div>
          </div>
        </div>
      </div>

      <div className="px-4 py-5 bg-white shadow sm:rounded-lg sm:p-6">
        <div className="md:grid md:grid-cols-3 md:gap-6">
          <div className="md:col-span-1">
            <h3 className="text-lg font-medium leading-6 text-gray-900">
              Claims
            </h3>
            <p className="mt-1 text-sm text-gray-500">Select claims.</p>
          </div>

          <div className="mt-5 space-y-4 md:mt-0 md:col-span-2">
            <div>
              <CheckboxGroupField {...data.meta.claims} />
            </div>
          </div>
        </div>
      </div>

      <div className="px-4 py-5 bg-white shadow sm:rounded-lg sm:p-6">
        <div className="md:grid md:grid-cols-3 md:gap-6">
          <div className="md:col-span-1">
            <h3 className="text-lg font-medium leading-6 text-gray-900">
              Tags
            </h3>
            <p className="mt-1 text-sm text-gray-500">Add tags.</p>
          </div>

          <div className="mt-5 space-y-4 md:mt-0 md:col-span-2">
            <div>
              <ArrayField {...data.meta.tags} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default MetaForm;
