import { PlusIcon } from "@heroicons/react/solid";
import React from "react";
import { useSelector } from "react-redux";
import { getFilteredRecipes, getTags } from "../../store/selectors/selectors";
import RecipeFilter from "../popovers/RecipeFilter";
import RecipeGrid from "../recipes/RecipeGrid";
import TagList from "../tags/TagList";
import BrowseRecipesFilter from "./BrowseRecipesFilter";
import { EmojiProvider, Emoji } from "react-apple-emojis";
import emojiData from "react-apple-emojis/lib/data.json";

function BrowseRecipes() {
  // const tags = useSelector(getTags);
  const recipes = useSelector(getFilteredRecipes);

  return (
    <>
      {/* <RecipeFilter /> */}
      <div className="relative flex flex-col">
        <div className="relative mx-auto max-w-7xl p-4 sm:px-6 lg:px-8">
          {recipes.length > 0 ? (
            <RecipeGrid recipes={recipes} />
          ) : (
            <div className="text-center">
              <div className="flex justify-center">
                <EmojiProvider data={emojiData}>
                  <Emoji name="woman-shrugging-light-skin-tone" />
                </EmojiProvider>
              </div>
              <h3 className="mt-2 text-sm font-medium text-gray-900">
                No results
              </h3>
              <p className="mt-1 text-sm text-gray-500">
                Please try adjusting your filters.
              </p>
            </div>
          )}
        </div>

        <div className="order-first">
          <BrowseRecipesFilter />
        </div>
      </div>
    </>
  );
}

export default BrowseRecipes;
