import React from "react";
import { useSelector } from "react-redux";
import { motion } from "framer-motion";
import { useFirestoreConnect } from "react-redux-firebase";
import { getUnpublishedMealPlans, getUnpublishedRecipes } from "../../store/selectors/selectors";
import RecipeGrid from "../recipes/RecipeGrid";
import FooterSocial from "../layout/FooterSocials";
import MealPlanGrid from "../mealPlan/MealPlanGrid";

const Drafts = () => {
  useFirestoreConnect(() => [
    {
      collection: "recipes",
      orderBy: [["createdAt", "desc"]],
      storeAs: "recipes",
    },
    {
      collection: "mealPlans",
      orderBy: [["createdAt", "desc"]],
      storeAs: "mealPlans",
    },
  ]);

  const recipes = useSelector(getUnpublishedRecipes);
  const mealPlans = useSelector(getUnpublishedMealPlans);
  return (
    <motion.div
      exit={{ opacity: 0 }}
      className="relative flex flex-col bg-white min-h-screen-content"
    >
      <div className="relative p-4 overflow-hidden bg-white sm:px-6 lg:px-8">
        <h1>
          <span className="block text-4xl font-extrabold tracking-tight sm:text-5xl xl:text-6xl">
            <span className="text-yellow-500">Recipe Drafts</span>
          </span>
        </h1>
        <div className="py-4">
          <RecipeGrid recipes={recipes} />
        </div>

        <h1>
          <span className="block text-4xl font-extrabold tracking-tight sm:text-5xl xl:text-6xl">
            <span className="text-yellow-500">Meal Plan Drafts</span>
          </span>
        </h1>
        <div className="py-4">
          <MealPlanGrid mealPlans={mealPlans} />
        </div>
      </div>
      <div className="mt-auto">
        <FooterSocial />
      </div>
    </motion.div>
  );
};

export default Drafts;
