import React, { useEffect } from "react";
import { Fragment, useState } from "react";
import {
  Dialog,
  Disclosure,
  Menu,
  Popover,
  Transition,
} from "@headlessui/react";
import { HeartIcon as HeartIconOutline, XIcon } from "@heroicons/react/outline";
import {
  HeartIcon as HeartIconSolid,
  ChevronDownIcon,
} from "@heroicons/react/solid";
import { some } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import {
  getCategories,
  getFilteredCategories,
  getFilteredDietaryClaims,
  getFilteredTags,
  getFilterFavoritesActive,
} from "../../store/selectors/selectors";
import {
  reset,
  toggleCategory,
  toggleClaims,
  toggleFavorites,
  toggleTag,
} from "../../store/actions/filterActions";

const sortOptions = [
  { name: "Most Popular", href: "#", current: true },
  { name: "Best Rating", href: "#", current: false },
  { name: "Newest", href: "#", current: false },
];

const dietaryClaims = [
  { value: "gluten-free", label: "Gluten Free" },
  { value: "nut-free", label: "Nut Free" },
  { value: "oil-free", label: "Oil Free" },
  { value: "soy-free", label: "Soy Free" },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const ID_CATEGORY = "category";
const ID_CLAIMS = "claims";
const ID_TAGS = "tags";

function BrowseRecipesFilter() {
  const allCategories = useSelector(getCategories);
  const filteredCategories = useSelector(getFilteredCategories);
  const filteredDietaryClaims = useSelector(getFilteredDietaryClaims);
  const filteredTags = useSelector(getFilteredTags);
  const isFilterFavoritesActive = useSelector(getFilterFavoritesActive);

  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();

  const [filters, setFilters] = useState([]);
  useEffect(() => {
    setFilters([
      {
        id: ID_CATEGORY,
        name: "Meal Type",
        options: allCategories.map((c, ix) => ({
          value: c.name,
          label: c.displayName,
        })),
      },
      {
        id: ID_CLAIMS,
        name: "Dietary Preferences",
        options: dietaryClaims,
      },
    ]);
  }, [allCategories]);

  function handleToggleFilter(sectionId, filterValue, filterLabel) {
    switch (sectionId) {
      case ID_CATEGORY:
        dispatch(toggleCategory({ value: filterValue, label: filterLabel }));
        break;
      case ID_CLAIMS:
        dispatch(toggleClaims({ value: filterValue, label: filterLabel }));
        break;
      case ID_TAGS:
        dispatch(toggleTag({ value: filterValue, label: filterLabel }));
        break;
      default:
        break;
    }
  }

  function isActiveFilter(sectionId, filterValue, filterLabel) {
    switch (sectionId) {
      case ID_CATEGORY:
        return some(filteredCategories, {
          value: filterValue,
          label: filterLabel,
        });
      case ID_CLAIMS:
        return some(filteredDietaryClaims, {
          value: filterValue,
          label: filterLabel,
        });
      default:
        break;
    }
  }

  function countActiveFilter(sectionId) {
    switch (sectionId) {
      case ID_CATEGORY:
        return filteredCategories.length;
      case ID_CLAIMS:
        return filteredDietaryClaims.length;
      default:
        break;
    }
  }

  function getActiveFilters(sectionId) {
    switch (sectionId) {
      case ID_CATEGORY:
        return filteredCategories;
      case ID_CLAIMS:
        return filteredDietaryClaims;
      case ID_TAGS:
        return filteredTags;
      default:
        break;
    }
  }

  //   console.log("FILTERED CATEGORIES: ", filteredCategories);
  //   console.log("FILTERED CLAIMS: ", filteredDietaryClaims);

  return (
    <div className="relative bg-white">
      {/* Mobile filter dialog */}
      <Transition.Root show={open} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-40 flex sm:hidden"
          onClose={setOpen}
        >
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <Transition.Child
            as={Fragment}
            enter="transition ease-in-out duration-300 transform"
            enterFrom="translate-x-full"
            enterTo="translate-x-0"
            leave="transition ease-in-out duration-300 transform"
            leaveFrom="translate-x-0"
            leaveTo="translate-x-full"
          >
            <div className="relative ml-auto flex h-full w-full max-w-xs flex-col overflow-y-auto bg-white py-4 pb-12 shadow-xl">
              <div className="flex items-center justify-between px-4">
                <h2 className="text-lg font-medium text-gray-900">Filters</h2>
                <button
                  type="button"
                  className="-mr-2 flex h-10 w-10 items-center justify-center rounded-md bg-white p-2 text-gray-400"
                  onClick={() => setOpen(false)}
                >
                  <span className="sr-only">Close menu</span>
                  <XIcon className="h-6 w-6" aria-hidden="true" />
                </button>
              </div>

              {/* Filters */}
              <form className="mt-4">
                {filters.map((section) => (
                  <Disclosure
                    as="div"
                    key={section.name}
                    className="border-t border-gray-200 px-4 py-6"
                  >
                    {({ open }) => (
                      <>
                        <h3 className="-mx-2 -my-3 flow-root">
                          <Disclosure.Button className="flex w-full items-center justify-between bg-white px-2 py-3 text-sm text-gray-400">
                            <span className="font-medium text-gray-900">
                              {section.name}
                            </span>
                            <span className="ml-6 flex items-center">
                              <ChevronDownIcon
                                className={classNames(
                                  open ? "-rotate-180" : "rotate-0",
                                  "h-5 w-5 transform"
                                )}
                                aria-hidden="true"
                              />
                            </span>
                          </Disclosure.Button>
                        </h3>
                        <Disclosure.Panel className="pt-6">
                          <div className="space-y-6">
                            {section.options.map((option, optionIdx) => (
                              <div
                                key={option.value}
                                className="flex items-center"
                              >
                                <input
                                  id={`filter-mobile-${section.id}-${optionIdx}`}
                                  name={`${section.id}[]`}
                                  defaultValue={option.value}
                                  type="checkbox"
                                  defaultChecked={isActiveFilter(
                                    section.id,
                                    option.value,
                                    option.label
                                  )}
                                  onChange={(e) =>
                                    handleToggleFilter(
                                      section.id,
                                      option.value,
                                      option.label
                                    )
                                  }
                                  className="h-4 w-4 rounded border-gray-300 text-cornflower-600 focus:ring-cornflower-500"
                                />
                                <label
                                  htmlFor={`filter-mobile-${section.id}-${optionIdx}`}
                                  className="ml-3 text-sm text-gray-500"
                                >
                                  {option.label}
                                </label>
                              </div>
                            ))}
                          </div>
                        </Disclosure.Panel>
                      </>
                    )}
                  </Disclosure>
                ))}
              </form>
            </div>
          </Transition.Child>
        </Dialog>
      </Transition.Root>

      {/* Filters */}
      <section aria-labelledby="filter-heading">
        <h2 id="filter-heading" className="sr-only">
          Filters
        </h2>

        <div className="relative z-10 border-b border-gray-200 bg-white pb-4">
          <div className="mx-auto flex max-w-7xl items-center justify-between px-4 sm:px-6 lg:px-8">
            <Menu as="div" className="relative inline-block text-left">
              {/* <div>
                  <Menu.Button className="group inline-flex justify-center text-sm font-medium text-gray-700 hover:text-gray-900">
                    Sort
                    <ChevronDownIcon
                      className="-mr-1 ml-1 h-5 w-5 flex-shrink-0 text-gray-400 group-hover:text-gray-500"
                      aria-hidden="true"
                    />
                  </Menu.Button>
                </div>

                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-100"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                >
                  <Menu.Items className="absolute left-0 mt-2 w-40 origin-top-left rounded-md bg-white shadow-2xl ring-1 ring-black ring-opacity-5 focus:outline-none">
                    <div className="py-1">
                      {sortOptions.map((option) => (
                        <Menu.Item key={option.name}>
                          {({ active }) => (
                            <a
                              href={option.href}
                              className={classNames(
                                option.current
                                  ? "font-medium text-gray-900"
                                  : "text-gray-500",
                                active ? "bg-gray-100" : "",
                                "block px-4 py-2 text-sm"
                              )}
                            >
                              {option.name}
                            </a>
                          )}
                        </Menu.Item>
                      ))}
                    </div>
                  </Menu.Items>
                </Transition> */}
            </Menu>

            <button
              type="button"
              className="inline-block text-sm font-medium text-gray-700 hover:text-gray-900 sm:hidden"
              onClick={() => setOpen(true)}
            >
              Filters
            </button>

            <div className="hidden sm:block">
              <div className="flow-root">
                <Popover.Group className="-mx-4 flex items-center divide-x divide-gray-200">
                  {filters.map((section) => (
                    <Popover
                      key={section.name}
                      className="relative inline-block px-4 text-left"
                    >
                      <Popover.Button className="group inline-flex justify-center text-sm font-medium text-gray-700 hover:text-gray-900">
                        <span>{section.name}</span>
                        <span className="ml-1.5 rounded bg-gray-200 py-0.5 px-1.5 text-xs font-semibold tabular-nums text-gray-700">
                          {countActiveFilter(section.id)}
                        </span>
                        <ChevronDownIcon
                          className="-mr-1 ml-1 h-5 w-5 flex-shrink-0 text-gray-400 group-hover:text-gray-500"
                          aria-hidden="true"
                        />
                      </Popover.Button>

                      <Transition
                        as={Fragment}
                        enter="transition ease-out duration-100"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                      >
                        <Popover.Panel className="absolute right-0 mt-2 origin-top-right rounded-md bg-white p-4 shadow-2xl ring-1 ring-black ring-opacity-5 focus:outline-none">
                          <form className="space-y-4">
                            {section.options.map((option, optionIdx) => (
                              <div
                                key={option.value}
                                className="flex items-center"
                              >
                                <input
                                  id={`filter-${section.id}-${optionIdx}`}
                                  name={`${section.id}[]`}
                                  defaultValue={option.value}
                                  type="checkbox"
                                  defaultChecked={isActiveFilter(
                                    section.id,
                                    option.value,
                                    option.label
                                  )}
                                  className="h-4 w-4 rounded border-gray-300 text-cornflower-600 focus:ring-cornflower-500"
                                  onChange={(e) =>
                                    handleToggleFilter(
                                      section.id,
                                      option.value,
                                      option.label
                                    )
                                  }
                                />
                                <label
                                  htmlFor={`filter-${section.id}-${optionIdx}`}
                                  className="ml-3 whitespace-nowrap pr-6 text-sm font-medium text-gray-900"
                                >
                                  {option.label}
                                </label>
                              </div>
                            ))}
                          </form>
                        </Popover.Panel>
                      </Transition>
                    </Popover>
                  ))}
                  <div className="relative inline-flex items-center px-4 text-left">
                    <button
                      type="button"
                      className="group inline-flex items-center justify-center text-sm font-medium text-gray-400 hover:text-gray-500"
                      onClick={() => dispatch(toggleFavorites())}
                    >
                      <span className="sr-only">View Favorites</span>
                      {isFilterFavoritesActive ? (
                        <HeartIconSolid
                          className="h-5 w-5"
                          aria-hidden="true"
                        />
                      ) : (
                        <HeartIconOutline
                          className="h-5 w-5"
                          aria-hidden="true"
                        />
                      )}
                    </button>
                  </div>
                </Popover.Group>
              </div>
            </div>
          </div>
        </div>

        {/* Active filters */}
        <div className="bg-gray-100">
          <div className="mx-auto max-w-7xl py-3 px-4 sm:flex sm:items-center sm:px-6 lg:px-8">
            <h3 className="text-xs font-semibold uppercase tracking-wide text-gray-500">
              Filters
              <span className="sr-only">, active</span>
            </h3>
            <div
              aria-hidden="true"
              className="hidden h-5 w-px bg-gray-300 sm:ml-4 sm:block"
            />
            <div className="mt-2 sm:mt-0 sm:ml-4">
              <div className="-m-1 flex flex-wrap items-center">
                {[...filters, { id: ID_TAGS }].map((section) =>
                  (getActiveFilters(section.id) || []).map((activeFilter) => (
                    <span
                      key={activeFilter.value}
                      className="m-1 inline-flex items-center rounded-full border border-gray-200 bg-white py-1.5 pl-3 pr-2 text-sm font-medium text-gray-900"
                    >
                      <span>{activeFilter.label}</span>
                      <button
                        type="button"
                        className="ml-1 inline-flex h-4 w-4 flex-shrink-0 rounded-full p-1 text-gray-400 hover:bg-gray-200 hover:text-gray-500"
                        onClick={() => {
                          handleToggleFilter(
                            section.id,
                            activeFilter.value,
                            activeFilter.label
                          );
                        }}
                      >
                        <span className="sr-only">
                          Remove filter for {activeFilter.label}
                        </span>
                        <svg
                          className="h-2 w-2"
                          stroke="currentColor"
                          fill="none"
                          viewBox="0 0 8 8"
                        >
                          <path
                            strokeLinecap="round"
                            strokeWidth="1.5"
                            d="M1 1l6 6m0-6L1 7"
                          />
                        </svg>
                      </button>
                    </span>
                  ))
                )}
              </div>
            </div>
            {/* <div
              aria-hidden="true"
              className="ml-auto hidden h-5 w-px bg-gray-300 sm:mx-4 sm:block"
            /> */}
            <div className="ml-auto">
              <button
                type="button"
                className="text-xs text-gray-500"
                onClick={() => dispatch(reset())}
              >
                Clear all
              </button>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default BrowseRecipesFilter;
