import React from "react";
import { motion } from "framer-motion";
import { useFirestoreConnect } from "react-redux-firebase";
import FooterSocial from "../layout/FooterSocials";
import * as ROUTES from "../../constants/routes";
import BrowseRecipes from "./BrowseRecipes";
import { NavLink, Redirect, Route, Switch } from "react-router-dom";
import BrowseMealPlans from "./BrowseMealPlans";
import BrowseRecipesFilter from "./BrowseRecipesFilter";
import PageHeader from "../headers/PageHeader";

const tabs = [
  { name: "Recipes", href: ROUTES.BROWSE_RECIPES, current: true },
  { name: "Meal Plans", href: ROUTES.BROWSE_MEAL_PLANS, current: false },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const Browse = () => {
  useFirestoreConnect(() => [
    {
      collection: "recipes",
      orderBy: [["createdAt", "desc"]],
      storeAs: "recipes",
    },
    {
      collection: "mealPlans",
      orderBy: [["createdAt", "desc"]],
      storeAs: "mealPlans",
    },
    {
      collection: "categories",
      orderBy: [["sort"]],
      storeAs: "categories",
    },
    {
      collection: "tags",
      orderBy: [["createdAt"]],
      storeAs: "tags",
    },
  ]);

  return (
    <>
      <motion.div exit="undefined" className="relative bg-white">
        <PageHeader
          title={"Recipes & Meal Plans"}
          subtitle={"Enjoy browsing"}
          description={
            "Check out all our recipes and meal plans below. Tip: Use available filters in case you have food sensibilities."
          }
        />
        <div className="mx-auto max-w-7xl px-4 pb-4 sm:px-6 sm:pb-6 lg:px-8 lg:pb-8">
          <div className="mt-3 border-b border-gray-200">
            <nav className="-mb-px flex space-x-8" aria-label="Tabs">
              {tabs.map((tab) => (
                <NavLink
                  key={tab.name}
                  to={tab.href}
                  activeClassName="border-cornflower-500 text-cornflower-600 hover:border-cornflower-500 hover:text-cornflower-600"
                  className="whitespace-nowrap border-b-2 border-transparent px-1 py-4 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700"
                  aria-current={tab.current ? "page" : undefined}
                >
                  {tab.name}
                </NavLink>
              ))}
            </nav>
          </div>
        </div>
        <Switch>
          <Redirect exact path={ROUTES.BROWSE} to={ROUTES.BROWSE_RECIPES} />
          <Route path={ROUTES.BROWSE_RECIPES} component={BrowseRecipes} />
          <Route path={ROUTES.BROWSE_MEAL_PLANS} component={BrowseMealPlans} />
        </Switch>
        {/* <footer>
          <FooterSocial />
        </footer> */}
      </motion.div>
    </>
  );
};

export default Browse;
