import React, { useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { CheckIcon } from "@heroicons/react/solid";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const Step = ({ details, ix, len }) => {
  const [completed, setCompleted] = useState(false);

  return (
    <li key={uuidv4()} onClick={() => setCompleted(!completed)}>
      <div className="relative pb-8">
        {ix !== len - 1 ? (
          <span
            className="absolute top-4 left-4 -ml-px h-full w-0.5 bg-gray-200"
            aria-hidden="true"
          />
        ) : null}
        <div className="relative flex space-x-3">
          <div>
            <span
              className={classNames(
                completed
                  ? "bg-red-100 text-white"
                  : "bg-gray-50 text-gray-600",
                "text-sm font-semibold  h-8 w-8 rounded-full flex items-center justify-center ring-8 ring-white"
              )}
            >
              {completed ? (
                <CheckIcon className="w-5 h-5 stroke-current" />
              ) : (
                ix + 1
              )}
            </span>
          </div>
          <div className="min-w-0 flex-1 flex justify-between space-x-4 pt-0.5">
            <div>
              <p
                className={classNames(
                  completed ? "text-gray-300" : "text-gray-900",
                  "text-base leading-7"
                )}
              >
                {details}
              </p>
            </div>
          </div>
        </div>
      </div>
    </li>
  );
};

export default Step;
