import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faYoutube } from "@fortawesome/free-brands-svg-icons";

function CreatorYoutubeBanner({ creator, videoUrl }) {
  return (
    <div className="relative px-4 py-3 rounded-md group bg-gray-50">
      <div className="flex items-center space-x-4 lg:space-x-6">
        <img
          className="w-16 h-16 rounded-full lg:w-20 lg:h-20"
          src={creator.youtubeChannelInfo.channelThumbnails.medium.url}
          alt={creator.youtubeChannelInfo.channelTitle}
        />
        <div className="text-base font-medium leading-6">
          <h3>
            <a
              href={videoUrl}
              target="_blank"
              rel="noreferrer"
              className="flex items-center focus:outline-none"
            >
              {/* Extend touch target to entire panel */}
              <span className="absolute inset-0" aria-hidden="true" />
              <span className="flex items-center">
                <FontAwesomeIcon
                  icon={faYoutube}
                  className="h-6 mb-0 mr-2 w-6-i"
                  aria-hidden="true"
                />
              </span>
              <span>Watch on Youtube</span>
            </a>
          </h3>
          <p className="mt-1 text-sm font-medium text-cornflower-500">
            {creator.youtubeChannelInfo.channelTitle}
          </p>
          <p className="text-xs font-normal text-gray-500">
            <span>
              {Math.floor(
                Number(creator.youtubeChannelInfo.subscriberCount) / 10
              ) / 100}
            </span>
            <span>K subscribers</span>
          </p>
        </div>
      </div>
      <span
        className="absolute text-gray-300 pointer-events-none top-3 right-3 group-hover:text-gray-400"
        aria-hidden="true"
      >
        <svg
          className="w-6 h-6"
          xmlns="http://www.w3.org/2000/svg"
          fill="currentColor"
          viewBox="0 0 24 24"
        >
          <path d="M20 4h1a1 1 0 00-1-1v1zm-1 12a1 1 0 102 0h-2zM8 3a1 1 0 000 2V3zM3.293 19.293a1 1 0 101.414 1.414l-1.414-1.414zM19 4v12h2V4h-2zm1-1H8v2h12V3zm-.707.293l-16 16 1.414 1.414 16-16-1.414-1.414z" />
        </svg>
      </span>
    </div>
  );
}

export default CreatorYoutubeBanner;
