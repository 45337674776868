import React from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  addToFavorites,
  removeFromFavorites,
} from "../../store/actions/userActions";
import { showModal } from "../../store/actions/modalActions";
import { HeartIcon as HeartIconOutline } from "@heroicons/react/outline";
import { HeartIcon as HeartIconSolid } from "@heroicons/react/solid";
import { getFavorites, getProfile } from "../../store/selectors/selectors";

const FavoriteButton = ({ recipeId }) => {
  const dispatch = useDispatch();
  const profile = useSelector(getProfile);
  const favorites = useSelector(getFavorites);

  const handleClick = () => {
    dispatch(
      showModal(
        {
          open: true,
          title: "Sign up for free to save your favorites!",
          message:
            "We cannot save your favorite recipes if you are not signed in to your account. If you have not already, you can create a new account for free in just a few seconds.",
        },
        "sign-in"
      )
    );
  };

  if (profile === null) {
    return (
      <button
        className="p-1 text-red-400 rounded-full bg-red-50 bg-opacity-70 hover:text-red-500 focus:outline-none"
        onClick={handleClick}
        aria-label="Add to favorites"
      >
        <HeartIconOutline className="w-6 h-6 stroke-current" />
      </button>
    );
  }

  if (recipeId === null || recipeId === undefined) {
    return null;
  }

  return favorites.includes(recipeId) ? (
    <button
      className="p-1 text-red-400 rounded-full bg-red-50 bg-opacity-70 hover:text-red-500 focus:outline-none"
      onClick={() => dispatch(removeFromFavorites(recipeId))}
      aria-label="Remove from favorites"
    >
      <HeartIconSolid className="w-6 h-6 fill-current" />
    </button>
  ) : (
    <button
      className="p-1 text-red-400 rounded-full bg-red-50 bg-opacity-70 hover:text-red-500 focus:outline-none"
      onClick={() => dispatch(addToFavorites(recipeId))}
      aria-label="Add to favorites"
    >
      <HeartIconOutline className="w-6 h-6 stroke-current" />
    </button>
  );
};

export default FavoriteButton;
