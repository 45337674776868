import React from "react";
import { ErrorMessage, Field, useField } from "formik";
import { ExclamationCircleIcon } from "@heroicons/react/solid";

function TextField({
  placeholder = "",
  label,
  hint = "",
  requiredErrorMsg,
  ...props
}) {
  const [field, meta, helpers] = useField(props);
  //   console.log("FIELD: ", field);
  // console.log("META: ", meta);
  //   console.log("HELPERS: ", helpers);
  // console.log(props)
  return (
    <>
      <div className="flex justify-between">
        <label
          htmlFor={field.name}
          className="block text-sm font-medium text-gray-700"
        >
          {label}
        </label>
        <span className="text-sm text-gray-500" id={field.name + "-hint"}>
          {hint}
        </span>
      </div>
      <div className="relative mt-1 rounded-md shadow-sm">
        <Field
          type="text"
          name={field.name}
          id={field.name}
          placeholder={placeholder}
          className={
            (meta.error
              ? "border-red-300 text-red-900 placeholder-red-300 focus:outline-none focus:ring-red-500 focus:border-red-500 pr-10  "
              : "border-gray-300 focus:ring-cornflower-500 focus:border-cornflower-500 ") +
            "block w-full sm:text-sm rounded-md"
          }
        />
        {meta.error && (
          <div className="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none">
            <ExclamationCircleIcon
              className="w-5 h-5 text-red-500"
              aria-hidden="true"
            />
          </div>
        )}
      </div>

      <ErrorMessage
        name={field.name}
        component="div"
        className="mt-1 text-xs text-red-600"
      />
    </>
  );
}

export default TextField;
