import { ExclamationIcon } from "@heroicons/react/solid";
import { ErrorMessage, useFormikContext } from "formik";
import React from "react";
import ArrayField from "../../formFields/ArrayField";
import CheckboxField from "../../formFields/CheckboxField";
import ImageUploadField from "../../formFields/ImageUploadField";
import DaysArrayField from "../../formFields/mealPlan/DaysArrayField";

import TextAreaField from "../../formFields/TextAreaField";
import TextField from "../../formFields/TextField";

function MealPlanForm({ title, description, image, meta, days }) {
  const { values } = useFormikContext();

  return (
    <>
      <div className="px-4 py-5 bg-white shadow sm:rounded-lg sm:p-6">
        <div className="md:grid md:grid-cols-3 md:gap-6">
          <div className="md:col-span-1">
            <h3 className="text-lg font-medium leading-6 text-gray-900">
              General
            </h3>
            <p className="mt-1 text-sm text-gray-500">...</p>
          </div>
          <div className="mt-5 space-y-4 md:mt-0 md:col-span-2">
            <div>
              <TextField {...title} />
            </div>

            <div>
              <TextAreaField {...description} />
            </div>

            <div className="block md:space-x-4 md:flex">
              <div className={(values.thumbnailUrl ? "md:mr-20 " : "") + "flex-1"}>
                <ImageUploadField {...image} />
              </div>
              {values.thumbnailUrl && (
                <div>
                  <div className="block mb-1 text-sm font-medium text-gray-700">
                    Current image
                  </div>
                  <div
                    className={
                      (values.image !== "" ? "grayscale " : "") +
                      "filter drop-shadow w-40 h-auto mx-auto mt-2 md:mt-0"
                    }
                  >
                    <img
                      src={values.thumbnailUrl}
                      alt={"Current preview (portrait)"}
                      className="object-cover"
                    />
                  </div>
                </div>
              )}
            </div>
            {values.image !== "" && values.thumbnailUrl && (
              <div className="p-4 rounded-md bg-yellow-50">
                <div className="flex">
                  <div className="flex-shrink-0">
                    <ExclamationIcon
                      className="w-5 h-5 text-yellow-400"
                      aria-hidden="true"
                    />
                  </div>
                  <div className="ml-3">
                    <h3 className="text-sm font-medium text-yellow-800">
                      Warning
                    </h3>
                    <div className="mt-2 text-sm text-yellow-700">
                      <p>Current image will be replaced with new upload!</p>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="px-4 py-5 bg-white shadow sm:rounded-lg sm:p-6">
        <div className="md:grid md:grid-cols-3 md:gap-6">
          <div className="md:col-span-1">
            <h3 className="text-lg font-medium leading-6 text-gray-900">
              YouTube Video
            </h3>
            <p className="mt-1 text-sm text-gray-500">
              Optionally link a YouTube video.
            </p>
          </div>

          <div className="mt-5 space-y-4 md:mt-0 md:col-span-2">
            <div>
              <TextField {...meta.videoUrl.url} />
            </div>
            <div>
              <TextAreaField {...meta.videoUrl.description} />
            </div>
          </div>
        </div>
      </div>

      <div className="px-4 py-5 bg-white shadow sm:rounded-lg sm:p-6">
        <div className="md:grid md:grid-cols-3 md:gap-6">
          <div className="md:col-span-1">
            <h3 className="text-lg font-medium leading-6 text-gray-900">
              Notes / Remarks / Additional Info
            </h3>
            <p className="mt-1 text-sm text-gray-500">
              Optionally add creator notes.
            </p>
          </div>

          <div className="mt-5 space-y-4 md:mt-0 md:col-span-2">
            <div>
              <TextField {...meta.notes.title} />
            </div>
            <div>
              <TextAreaField {...meta.notes.description} />
            </div>
          </div>
        </div>
      </div>

      <div className="px-4 py-5 bg-white shadow sm:rounded-lg sm:p-6">
        <div className="md:grid md:grid-cols-3 md:gap-6">
          <div className="md:col-span-1">
            <h3 className="text-lg font-medium leading-6 text-gray-900">
              Visibility
            </h3>
            <p className="mt-1 text-sm text-gray-500">Set visibility.</p>
          </div>

          <div className="mt-5 space-y-4 md:mt-0 md:col-span-2">
            <div>
              <CheckboxField {...meta.visibility.isPublished} />
            </div>
            <div>
              <CheckboxField {...meta.visibility.isFree} />
            </div>
          </div>
        </div>
      </div>

      <div className="px-4 py-5 bg-white shadow sm:rounded-lg sm:p-6">
        <div className="md:grid md:grid-cols-3 md:gap-6">
          <div className="md:col-span-1">
            <h3 className="text-lg font-medium leading-6 text-gray-900">
              Tags
            </h3>
            <p className="mt-1 text-sm text-gray-500">Add tags.</p>
          </div>

          <div className="mt-5 space-y-4 md:mt-0 md:col-span-2">
            <div>
              <ArrayField {...meta.tags} />
            </div>
          </div>
        </div>
      </div>

      <div className="px-4 py-5 bg-white shadow sm:rounded-lg sm:p-6">
        <div className="md:grid md:grid-cols-3 md:gap-6">
          <div className="md:col-span-1">
            <h3 className="text-lg font-medium leading-6 text-gray-900">
              Days
            </h3>
            <p className="mt-1 text-sm text-gray-500">
              Map out a whole week of recipes
            </p>
          </div>

          <div className="mt-5 space-y-4 md:mt-0 md:col-span-2">
            <div>
              <DaysArrayField {...days}/>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default MealPlanForm;
