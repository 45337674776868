export const LANDING = "/";
export const ERROR_NOT_FOUND = "/404";
export const SIGN_IN = "/sign-in";
export const ACCOUNT = "/account";
export const PROFILE = "/account/profile";
export const SETTINGS = "/account/settings";
export const CREATE_RECIPE = "/create-recipe";
export const FAVORITES = "/favorites";
export const BROWSE = "/browse";
export const BROWSE_RECIPES = "/browse/recipes";
export const BROWSE_MEAL_PLANS = "/browse/meal-plans";
export const MEAL_PLANNER = "/meal-planner";
export const SHOPPING_LIST = "/shopping-list";
export const INGREDIENTS = "/ingredients";
export const CREATE_MEAL_PLAN = "/create-meal-plan";
export const DRAFTS = "/drafts";
export const PRIVACY_POLICY = "/privacy-policy";
export const TERMS_OF_SERVICE = "/terms-of-service";
export const COURSES_REBOOT = "/courses/reboot";
export const NUTRITION = "/nutrition";
export const SHOP = "/shop";
export const SHOP_ALL = "/shop/all";
export const HELLO = "/hello";
