import { useFormikContext } from "formik";
import React from "react";
import ImageUploadThumbnail from "../../utils/ImageUploadThumbnail";

function SubmitForm() {
  const { values } = useFormikContext();

  return (
    <>
      <div className="px-4 py-5 bg-white shadow sm:rounded-lg sm:p-6">
        <div className="md:grid md:grid-cols-3 md:gap-6">
          <div className="md:col-span-1">
            <h3 className="text-lg font-medium leading-6 text-gray-900">
              Summary
            </h3>
            <p className="mt-1 text-sm text-gray-500">
              Recipe is ready to be saved. Please double-check all inputs and
              submit when you are ready.
            </p>
          </div>

          <div className="mt-5 space-y-4 md:mt-0 md:col-span-2">
            <div className="mx-auto text-base prose">
              <div className="flex justify-between space-x-4">
                {values.portraitImage !== "" ? (
                  <ImageUploadThumbnail file={values.portraitImage} />
                ) : (
                  values.data.mainPortraitUrl && (
                    <div className="w-40 h-40 mx-auto mt-2 filter drop-shadow md:mt-0">
                      <img
                        src={values.data.mainPortraitUrl}
                        alt={"Current preview (Portrait)"}
                        className="object-cover"
                      />
                    </div>
                  )
                )}

                {values.landscapeImage !== "" ? (
                  <ImageUploadThumbnail file={values.landscapeImage} />
                ) : (
                  values.data.mainLandscapeUrl && (
                    <div className="h-auto mx-auto mt-2 filter drop-shadow w-60 md:mt-0">
                      <img
                        src={values.data.mainLandscapeUrl}
                        alt={"Current preview (landscape)"}
                        className="object-cover"
                      />
                    </div>
                  )
                )}
              </div>

              <h2>{values.data.title}</h2>
              <p>{values.data.description}</p>
              <h4>Ingredients</h4>
              {values.data.ingredients.groups.map((group) => {
                return (
                  <>
                    <h6>{group.title}</h6>
                    <ul className="">
                      {group.ingredients.map((ingredient) => (
                        <li key={ingredient.name}>
                          <span>
                            {ingredient.amount} {ingredient.unit}{" "}
                          </span>
                          {ingredient.altAmount ? (
                            <span>
                              ({ingredient.altAmount} {ingredient.altUnit})
                            </span>
                          ) : null}{" "}
                          <span className="capitalize">{ingredient.name}</span>
                        </li>
                      ))}
                    </ul>
                  </>
                );
              })}
              <h4>Steps</h4>
              <ol>
                {values.data.steps.map((step, ix) => (
                  <li key={"step" + ix}>{step.details}</li>
                ))}
              </ol>
              <h4>Categories</h4>
              <ul>
                {values.data.meta.categories.map((category, ix) => (
                  <li key={"category" + ix} className="capitalize">
                    {category}
                  </li>
                ))}
              </ul>
              <h4>Claims</h4>
              <ul>
                {values.data.meta.claims.map((claim, ix) => (
                  <li key={"claim" + ix} className="capitalize">
                    {claim}
                  </li>
                ))}
              </ul>
              <h4>Tags</h4>
              <ul>
                {values.data.meta.tags.map((tag, ix) => (
                  <li key={"tag" + ix} className="capitalize">
                    {tag}
                  </li>
                ))}
              </ul>
              <h4>Notes</h4>
              <h6>{values.data.meta.notes.title}</h6>
              <p>{values.data.meta.notes.description}</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default SubmitForm;
