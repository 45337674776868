import React from "react";
import TextField from "../../formFields/TextField";
import TextAreaField from "../../formFields/TextAreaField";
import ImageUploadField from "../../formFields/ImageUploadField";
import { useFormikContext } from "formik";
import { ExclamationIcon } from "@heroicons/react/solid";

function BasicForm(props) {
  const {
    formField: { data, portraitImage, landscapeImage },
  } = props;
  const { values } = useFormikContext();

  return (
    <>
      <div className="px-4 py-5 bg-white shadow sm:rounded-lg sm:p-6">
        <div className="md:grid md:grid-cols-3 md:gap-6">
          <div className="md:col-span-1">
            <h3 className="text-lg font-medium leading-6 text-gray-900">
              General Info
            </h3>
            <p className="mt-1 text-sm text-gray-500">...</p>
          </div>

          <div className="mt-5 space-y-4 md:mt-0 md:col-span-2">
            <div>
              <TextField {...data.title} />
            </div>
            <div>
              <TextAreaField {...data.description} />
            </div>
            <div className="block md:space-x-4 md:flex">
              <div
                className={
                  (values.data.mainPortraitUrl ? "md:mr-20 " : "") + "flex-1"
                }
              >
                <ImageUploadField {...portraitImage} />
              </div>
              {values.data.mainPortraitUrl && (
                <div>
                  <div className="block mb-1 text-sm font-medium text-gray-700">
                    Current image
                  </div>
                  <div
                    className={
                      (values.portraitImage !== "" ? "grayscale " : "") +
                      "filter drop-shadow w-40 h-auto mx-auto mt-2 md:mt-0"
                    }
                  >
                    <img
                      src={values.data.mainPortraitUrl}
                      alt={"Current preview (portrait)"}
                      className="object-cover"
                    />
                  </div>
                </div>
              )}
            </div>
            {values.portraitImage !== "" && values.data.mainPortraitUrl && (
              <div className="p-4 rounded-md bg-yellow-50">
                <div className="flex">
                  <div className="flex-shrink-0">
                    <ExclamationIcon
                      className="w-5 h-5 text-yellow-400"
                      aria-hidden="true"
                    />
                  </div>
                  <div className="ml-3">
                    <h3 className="text-sm font-medium text-yellow-800">
                      Warning
                    </h3>
                    <div className="mt-2 text-sm text-yellow-700">
                      <p>Current image will be replaced with new upload!</p>
                    </div>
                  </div>
                </div>
              </div>
            )}
            <div className="block md:space-x-4 md:flex">
              <div className="flex-1">
                <ImageUploadField {...landscapeImage} />
              </div>
              {values.data.mainLandscapeUrl && (
                <div>
                  <div className="block mb-1 text-sm font-medium text-gray-700">
                    Current image
                  </div>
                  <div
                    className={
                      (values.landscapeImage !== "" ? "grayscale " : "") +
                      "filter drop-shadow h-auto mx-auto mt-2 w-60 md:mt-0"
                    }
                  >
                    <img
                      src={values.data.mainLandscapeUrl}
                      alt={"Current preview (landscape)"}
                      className="object-cover"
                    />
                  </div>
                </div>
              )}
            </div>
            {values.landscapeImage !== "" && values.data.mainLandscapeUrl && (
              <div className="p-4 rounded-md bg-yellow-50">
                <div className="flex">
                  <div className="flex-shrink-0">
                    <ExclamationIcon
                      className="w-5 h-5 text-yellow-400"
                      aria-hidden="true"
                    />
                  </div>
                  <div className="ml-3">
                    <h3 className="text-sm font-medium text-yellow-800">
                      Warning
                    </h3>
                    <div className="mt-2 text-sm text-yellow-700">
                      <p>Current image will be replaced with new upload!</p>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default BasicForm;
