import React from "react";
import RecipesArrayField from "../../formFields/recipe/RecipesArrayField";

function ReferencesForm(props) {
  const {
    formField: { data },
  } = props;
  return (
    <>
      <div className="px-4 py-5 bg-white shadow sm:rounded-lg sm:p-6">
        <div className="md:grid md:grid-cols-3 md:gap-6">
          <div className="md:col-span-1">
            <h3 className="text-lg font-medium leading-6 text-gray-900">
              Related Recipes
            </h3>
            <p className="mt-1 text-sm text-gray-500">
              Reference any related recipes here, e.g. sauce or dressing that is
              available separately.
            </p>
          </div>

          <div className="mt-5 space-y-4 md:mt-0 md:col-span-2">
            <div>
              <RecipesArrayField {...data.relatedRecipes} recipes={props.recipes}/>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ReferencesForm;
