export const formModel = (categories = []) => {
  return {
    formId: "recipeForm",
    formField: {
      portraitImage: {
        name: "portraitImage",
        description: "Card Image (Thumbnail)",
        label: "Portrait Image (3:4)",
        hint: "Optional",
      },
      landscapeImage: {
        name: "landscapeImage",
        description: "Main Recipe Image",
        label: "Landscape Image (4:3)",
        hint: "Optional",
      },
      data: {
        title: {
          name: "data.title",
          label: "Title",
          requiredErrorMsg: "Title is required",
          hint: "Required",
        },
        description: {
          name: "data.description",
          label: "Description / About",
          hint: "Optional",
        },
        meta: {
          videoUrl: {
            url: {
              name: "data.meta.videoUrl.url",
              label: "Link / URL",
              placeholder: "https://www.youtube.com/watch",
              hint: "Optional",
            },
            description: {
              name: "data.meta.videoUrl.description",
              label: "Description",
              hint: "Optional",
            },
          },
          notes: {
            title: {
              name: "data.meta.notes.title",
              label: "Title / Headline",
              hint: "Optional",
            },
            description: {
              name: "data.meta.notes.description",
              label: "Description",
              hint: "Optional",
            },
          },
          time: {
            title: {
              name: "data.meta.time.title",
              label: "Title / Headline",
              hint: "Optional",
            },
            amount: {
              name: "data.meta.time.amount",
              label: "Amount",
              hint: "Required",
            },
            unit: {
              name: "data.meta.time.unit",
              label: "Unit",
              hint: "Required",
              options: [
                { name: "minutes", value: "min" },
                { name: "hours", value: "h" },
              ],
            },
          },
          visibility: {
            isPublished: {
              name: "data.meta.visibility.isPublished",
              label: "Public",
              description:
                "Publish recipe by setting public here. Until then it is in draft.",
            },
            isFree: {
              name: "data.meta.visibility.isFree",
              label: "Free",
              description:
                "Make recipe available to all users (including unregistered and unsubscribed).",
            },
          },
          categories: {
            name: "data.meta.categories",
            label: "Categories",
            options: categories.map((category) => {
              return { name: category.displayName, value: category.name };
            }),
          },
          claims: {
            name: "data.meta.claims",
            label: "Allergy Information / Claims",
            options: [
              { name: "Gluten free", value: "gluten-free" },
              { name: "Nut free", value: "nut-free" },
              { name: "Oil free", value: "oil-free" },
              { name: "Soy free", value: "soy-free" },
            ],
          },
          tags: {
            name: "data.meta.tags",
            label: "Tags",
          },
        },
        ingredients: {
          servings: {
            name: "data.ingredients.servings",
            label: "Servings",
            hint: "Required"
          },
          groups: {
            name: "data.ingredients.groups",
            label: "Ingredient Groups",
          },
        },
        steps: {
          name: "data.steps",
          label: "Steps (in order)",
        },
        nutrition: {
          calories: {
            amount: {
              name: "data.nutrition.calories.amount",
              label: "Amount",
            },
            unit: {
              name: "data.nutrition.calories.unit",
              label: "Unit",
              options: [
                { name: "g", value: "g" },
                { name: "mg", value: "mg" },
                { name: "cal", value: "cal" },
                { name: "kcal", value: "kcal" },
              ],
            },
          },
          carbs: {
            amount: {
              name: "data.nutrition.carbs.amount",
              label: "Amount",
            },
            unit: {
              name: "data.nutrition.carbs.unit",
              label: "Unit",
              options: [
                { name: "g", value: "g" },
                { name: "mg", value: "mg" },
              ],
            },
          },
          protein: {
            amount: {
              name: "data.nutrition.protein.amount",
              label: "Amount",
            },
            unit: {
              name: "data.nutrition.protein.unit",
              label: "Unit",
              options: [
                { name: "g", value: "g" },
                { name: "mg", value: "mg" },
              ],
            },
          },
          fat: {
            amount: {
              name: "data.nutrition.fat.amount",
              label: "Amount",
            },
            unit: {
              name: "data.nutrition.fat.unit",
              label: "Unit",
              options: [
                { name: "g", value: "g" },
                { name: "mg", value: "mg" },
              ],
            },
          },
          sodium: {
            amount: {
              name: "data.nutrition.sodium.amount",
              label: "Amount",
            },
            unit: {
              name: "data.nutrition.sodium.unit",
              label: "Unit",
              options: [
                { name: "g", value: "g" },
                { name: "mg", value: "mg" },
              ],
            },
          },
          sugar: {
            amount: {
              name: "data.nutrition.sugar.amount",
              label: "Amount",
            },
            unit: {
              name: "data.nutrition.sugar.unit",
              label: "Unit",
              options: [
                { name: "g", value: "g" },
                { name: "mg", value: "mg" },
              ],
            },
          },
        },
        relatedRecipes: {
          name: "data.relatedRecipes",
          label: "Related Recipes",
        },
      },
    },
  };
};
