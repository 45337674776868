import React from "react";
import RecipeGridItemSimple from "./RecipeGridItemSimple";

function RecipeGridScrollable({ recipes, showBookmark = false }) {
  return (
    <div className="relative">
      <div className="relative w-full overflow-x-auto">
        <ul className="inline-flex space-x-4">
          {recipes.map((recipe) => (
            <RecipeGridItemSimple key={recipe.id} {...recipe} showBookmark={showBookmark} />
          ))}
        </ul>
      </div>
    </div>
  );
}

export default RecipeGridScrollable;
