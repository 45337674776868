import React, { useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import { useFirestoreConnect } from "react-redux-firebase";
import { useSelector, useDispatch } from "react-redux";
import * as ROUTES from "../../constants/routes";
import {
  initCreateMealPlan,
  createMealPlan,
  updateMealPlan,
  finishCreateMealPlan,
} from "../../store/actions/mealPlanActions";
import { motion } from "framer-motion";
import Loader from "../loaders/Loader";
import { showModal, hideModal } from "../../store/actions/modalActions";
import {
  getTags,
  isCreateMealPlanPending,
} from "../../store/selectors/selectors";
import MealPlanForm from "../forms/mealPlan/MealPlanForm";
import { Form, Formik } from "formik";
import { formInitialValues } from "../forms/mealPlan/models/formInitialValues";
import { validationSchema } from "../forms/mealPlan/models/validationSchema";
import { formModel } from "../forms/mealPlan/models/formModel";
import { updateRecipeTags } from "../../store/actions/recipeActions";
import { onlyUnique } from "../utils/Utils";

function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

const EditMealPlan = () => {
  useFirestoreConnect(() => [
    {
      collection: "categories",
      orderBy: [["sort"]],
      storeAs: "categories",
    },
    {
      collection: "tags",
      orderBy: [["createdAt"]],
      storeAs: "tags",
    },
    {
      collection: "recipes",
      orderBy: [["createdAt", "desc"]],
      storeAs: "recipes",
    },
    {
      collection: "mealPlans",
      orderBy: [["createdAt", "desc"]],
      storeAs: "mealPlans",
    },
  ]);
  let { id } = useParams();
  const dispatch = useDispatch();

  const mealPlan = useSelector(
    ({ firestore: { data } }) => data.mealPlans && data.mealPlans[id]
  );
  const tagList = useSelector(getTags);

  const { formId, formField } = formModel();

  //#region State of recipe processing
  const isPending = useSelector(isCreateMealPlanPending);
  const prevIsPending = usePrevious(isPending);
  const mealPlanState = useSelector((state) => state.mealPlan);
  const prevMealPlanState = usePrevious(mealPlanState);

  if (prevIsPending === false && isPending === true) {
    dispatch(
      showModal(
        {
          open: true,
          title: "Processing...",
          message: "Please wait while we process your request.",
        },
        "loading"
      )
    );
  } else if (prevIsPending === true && isPending === false) {
    dispatch(hideModal());
  }

  useEffect(() => {
    if (mealPlanState.finished === true && mealPlanState.error === null) {
      dispatch(finishCreateMealPlan());
    }
  }, [mealPlanState, dispatch]);
  //#endregion

  function _handleSubmit(values, actions) {
    dispatch(initCreateMealPlan())
      .then(() => {
        mealPlan
          ? dispatch(updateMealPlan(id, values))
          : dispatch(createMealPlan(values));
      })
      .then(() => {
        const tags = values.meta.tags;
        const existingTagNames = tagList.map(({ name }) => name);
        var newTagNames = tags.filter((tag) => {
          return !existingTagNames.includes(tag);
        });
        dispatch(updateRecipeTags(newTagNames.filter(onlyUnique)));
      })
      .catch((err) => {
        console.error(err);
      });
    actions.setSubmitting(false);
  }

  return (
    <motion.div
      exit={{ opacity: 0 }}
      className="py-4 min-h-screen-content bg-gray-50 sm:p-6 lg:p-8"
    >
      <h1 className="px-4 mb-4 text-3xl font-extrabold text-center text-gray-900 capitalize sm:text-left sm:px-0">
        Create meal plan
      </h1>

      <Formik
        initialValues={formInitialValues(mealPlan)}
        validationSchema={validationSchema()}
        onSubmit={_handleSubmit}
      >
        {({ isSubmitting }) => (
          <Form id={formId}>
            <div className="space-y-6">
              <MealPlanForm {...formField} />
              <div className="flex justify-end">
                <button
                  type="submit"
                  className="inline-flex justify-center w-full px-4 py-2 text-base font-medium text-white border border-transparent rounded-md shadow-sm bg-cornflower-600 hover:bg-cornflower-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cornflower-500 sm:ml-3 sm:w-auto sm:text-sm"
                  disabled={isSubmitting}
                >
                  Submit
                </button>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </motion.div>
  );
};

export default EditMealPlan;
