import React from "react";
import { ErrorMessage, Field, useField } from "formik";

function CheckboxGroupField({
  options,
  label,
  description,
  requiredErrorMsg,
  ...props
}) {
  const [field, meta, helpers] = useField(props);
  return (
    <>
      <div className="space-y-2">
        {options.map((option) => {
          return (
            <div className="flex items-start" key={option.value}>
              <div className="flex items-center h-5">
                <Field
                  type="checkbox"
                  name={field.name}
                  value={option.value}
                  id={option.value}
                  className="w-4 h-4 border-gray-300 rounded text-cornflower-600 focus:ring-cornflower-500"
                />
              </div>
              <div className="ml-3 text-sm">
                <label
                  htmlFor={field.name}
                  className="font-medium text-gray-700"
                >
                  {option.name}
                </label>
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
}

export default CheckboxGroupField;
