const initState = {
    finished: false,
    error: null,
    recipeId: null
}

const recipeReducer = (state = initState, action) => {
    switch (action.type) {
        case 'CREATE_RECIPE_REQUEST':
            return initState;
        case 'CREATE_RECIPE_SUCCESS':
            // console.log(`SUCCESS: Create recipe with id ${action.recipeId}`);
            return {
                ...state,
                finished: true,
                recipeId: action.recipeId,
                error: null
            }
        case 'CREATE_RECIPE_ERROR':
            // console.error("ERROR: Creating recipe data: ", action.error);
            return {
                ...state,
                finished: true,
                recipeId: null,
                error: action.error
            }
        case 'CREATE_RECIPE_FINISH':
            return initState;
        default:
            return state;
    }
};

export default recipeReducer;