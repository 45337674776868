import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from "./reportWebVitals";
import { createStore, applyMiddleware } from "redux";
import { Provider, useSelector } from "react-redux";
import rootReducer from "./store/reducers/rootReducer";
import thunk from "redux-thunk";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { createFirestoreInstance } from "redux-firestore";
import {
  ReactReduxFirebaseProvider,
  getFirebase,
  isLoaded,
} from "react-redux-firebase";
import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";
import "firebase/storage";
import "firebase/analytics";
import getFirebaseConfig from "./config/fbConfig";
import { composeWithDevTools } from "redux-devtools-extension";
import "react-redux-toastr/lib/css/react-redux-toastr.min.css";
import Loader from "./components/loaders/Loader";
import * as ROUTES from "./constants/routes";
import Hello from "./components/hello/Hello";
import { GlobalStyles } from "./global";
import { AnimatePresence } from "framer-motion";

const AuthIsLoaded = ({ children }) => {
  const auth = useSelector((state) => state.firebase.auth);
  if (!isLoaded(auth))
    return (
      <div className="flex items-center justify-center w-full h-screen">
        <Loader />
      </div>
    );
  return children;
};

// react-redux-firebase config
const rrfConfig = {
  userProfile: "users",
  // profileParamsToPopulate: [
  //   ['role:roles'], // populates user's role with matching role object from roles
  // ],
  // profileFactory: user => {
  //   const profile = {
  //     email: user.email || user.providerData[0].email,
  //     role: 'user',
  //   }
  //   if (user.providerData && user.providerData.length) {
  //     profile.providerData = user.providerData
  //   }
  //   return profile
  // },
  useFirestoreForProfile: true,
  updateProfileOnLogin: true,
  // enableClaims: true, // Get custom claims along with the profile
  presence: "presence", // where list of online users is stored in database
  sessions: "sessions",
};

// Initialize Firebase
getFirebaseConfig.then((config) => {
  firebase.initializeApp(config);
  firebase.firestore();
  firebase.storage();
  firebase.analytics();

  // Create store with reducers and initial state
  const initialState = {};
  const store = createStore(
    rootReducer,
    initialState,
    composeWithDevTools(applyMiddleware(thunk.withExtraArgument(getFirebase)))
  );

  const rrfProps = {
    firebase,
    config: rrfConfig,
    dispatch: store.dispatch,
    createFirestoreInstance,
  };

  ReactDOM.render(
    <React.StrictMode>
      <Provider store={store}>
        <ReactReduxFirebaseProvider {...rrfProps}>
          <GlobalStyles />
          <AnimatePresence exitBeforeEnter>
            <BrowserRouter>
              <Switch>
                <Route exact path={ROUTES.HELLO} component={Hello} />
                <AuthIsLoaded>
                  <App />
                </AuthIsLoaded>
              </Switch>
            </BrowserRouter>
          </AnimatePresence>
        </ReactReduxFirebaseProvider>
      </Provider>
    </React.StrictMode>,
    document.getElementById("root")
  );

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();

  // If you want to start measuring performance in your app, pass a function
  // to log results (for example: reportWebVitals(console.log))
  // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
  reportWebVitals();
});
