import { ChevronRightIcon, TagIcon } from "@heroicons/react/solid";
import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

function MealPlanDayItem({ category, notes = "", recipe }) {
  const recipeData = useSelector(
    ({ firestore: { data } }) => data.recipes && data.recipes[recipe]
  );

  const { title, thumbnailUrl } = recipeData;

  return (
    <li>
      <Link to={`/browse/recipes/${recipe}`} className="block hover:bg-gray-50">
        <div className="flex items-center px-4 py-4 sm:px-6">
          <div className="flex items-center flex-1 min-w-0">
            <div className="flex-shrink-0">
              <img
                className="object-cover object-center w-12 h-12 rounded-md"
                src={thumbnailUrl}
                alt=""
              />
            </div>
            <div className="flex-1 min-w-0 px-4 md:grid md:grid-cols-3 md:gap-4">
              <div className="my-auto md:col-span-1">
                <p className="text-sm font-medium truncate text-cornflower-600">
                  {title}
                </p>
                <p className="flex items-center mt-2 text-sm text-gray-500">
                  <TagIcon
                    className="flex-shrink-0 mr-1.5 h-4 w-4 text-gray-400"
                    aria-hidden="true"
                  />
                  <span className="capitalize truncate">{category}</span>
                </p>
              </div>
              <div className="hidden md:block md:col-span-2">
                <div>
                  <p className="text-sm text-gray-500">{notes}</p>
                </div>
              </div>
            </div>
          </div>
          <div>
            <ChevronRightIcon
              className="w-5 h-5 text-gray-400"
              aria-hidden="true"
            />
          </div>
        </div>
      </Link>
    </li>
  );
}

export default MealPlanDayItem;
