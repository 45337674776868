import React from "react";
import { Route, Redirect } from "react-router-dom";
import { isLoaded, isEmpty } from "react-redux-firebase";
import { useSelector } from "react-redux";
import { motion } from "framer-motion";
import * as ROUTES from "../../constants/routes";
import { getIsAdmin, SelectAuthUID } from "../../store/selectors/selectors";
import Loader from "../loaders/Loader";

const AdminOnlyRoute = ({ children, ...remainingProps }) => {
  const auth = useSelector((state) => state.firebase.auth);
  const profile = useSelector((state) => state.firebase.profile);
  const uid = useSelector(SelectAuthUID);
  const isAdmin = useSelector(getIsAdmin);
  return (
    <Route
      {...remainingProps}
      render={({ location }) =>
        isLoaded(auth) && isLoaded(profile) ? (
          uid && isAdmin ? (
            children
          ) : (
            <motion.Fragment exit="undefined">
              <Redirect
                to={{
                  pathname: ROUTES.ERROR_NOT_FOUND,
                  state: { from: location },
                }}
              />
            </motion.Fragment>
          )
        ) : (
          <Loader />
        )
      }
    />
  );
};

export default AdminOnlyRoute;
